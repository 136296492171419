import React from 'react';
interface Props {
  title: JSX.Element
  imagePath: string
  about?: string
}

const TabDetails = (props: Props): JSX.Element => {
  const { about, title, imagePath }  = props;

  return (
    <div className='details-option'>
      <h3>{title}</h3>
      <img className='drop-image' src={imagePath} alt="feedback_graph"/>
      {about ? <div className='about'>{about}</div> : null}
    </div>
  )
}

export default TabDetails