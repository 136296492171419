import { ChangePasswordDto } from "../../dto/account-settings/change-password-dto";
import AccountSettingsTypes from "./account-settings.types";
import { DeleteAccountDto } from "../../dto/account-settings/delete-account-dto";
import { ContactEmailDto } from "../../dto/contact-email-dto";
import { NewsletterSettingsDto } from "../../dto/account-settings/newsletter-settings-dto";
import { UserDetailsDto } from "../../dto/account-settings/user-details-dto";
import { FinalizeSignUpDto } from "../../dto/account-settings/finalize-signup-dto";

export const changeUserDetails = (changeUserDetails: UserDetailsDto) => ({
  type: AccountSettingsTypes.CHANGE_USERDETAILS,
  payload: changeUserDetails
});

export const changeNotifications = (newsletterSettings: NewsletterSettingsDto) => ({
  type: AccountSettingsTypes.CHANGE_NOTIFICATIONS,
  payload: newsletterSettings
});

export const changePassword = (changePasswordDto: ChangePasswordDto) => ({
  type: AccountSettingsTypes.CHANGE_PASSWORD,
  payload: changePasswordDto
});

export const changePasswordSuccess = (message: any) => ({
  type: AccountSettingsTypes.CHANGE_PASSWORD_SUCCESS,
  payload: message
});

export const finalizeSignUp = (finalizeSignUpDto: FinalizeSignUpDto ) => ({
  type: AccountSettingsTypes.FINALIZE_SIGNUP,
  payload: finalizeSignUpDto
})

export const deleteAccount = (deleteAccountDto: DeleteAccountDto) => ({
  type: AccountSettingsTypes.DELETE_ACCOUNT,
  payload: deleteAccountDto
});

export const resetPassword = (email: string) => ({
  type: AccountSettingsTypes.RESET_PASSWORD,
  payload: email
})

export const sendContactEmail = (email: ContactEmailDto) => ({
  type: AccountSettingsTypes.SEND_CONTACT_EMAIL,
  payload: email
})

export const addSocialMediaLink = (socialMediaLink: string) => ({
  type: AccountSettingsTypes.ADD_SOCIAL_MEDIA_LINK,
  payload: socialMediaLink
})

export const getUserProfile = () => ({
  type: AccountSettingsTypes.GET_USER_PROFILE,
})

export const deleteSocialMediaLink = (linkId: number) => ({
  type: AccountSettingsTypes.DELETE_SOCIAL_MEDIA_LINK,
  payload: linkId
})

export const updateUserLogin = (login: string) => ({
  type: AccountSettingsTypes.UPDATE_USER_LOGIN,
  payload: login
})