import { store } from "../../redux/store";
import { ToastsStore } from "react-toasts";
import { callApi } from "../api-call-service";
import { ApiPath } from "../ApiPath";
import { ApiMethodDto } from "../api-method-dto";
import UserActionTypes from "../../redux/user/user.types";
import { UserComparereWithVotesDto } from "../../dto/user-compareres/user-comparere-with-votes-dto";

export class uploadCompareres {
  static async applyComparereAsync(compareres: Array<File>, contextDescription: string, comparereIsPrivate: boolean): Promise<UserComparereWithVotesDto> {
    return new Promise(async (resolve) => {
      const token = store.getState().user.token
      let formData = new FormData();
      for (let i = 0; i < compareres.length; i++) {
        formData.append('file', compareres[i]);
      }

      formData.append('contextData', contextDescription);
      formData.append('isPrivate', comparereIsPrivate ? "true" : "false");

      const options = {
        body: formData,
        datatype: "FormData",
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        method: "POST",
        expectedStatus: 200
      } as ApiMethodDto;

      await callApi(options.method, ApiPath.uploadCompareres_startNewComparere, options)
        .then((res) => {
          ToastsStore.success('Your Comparere has been added.');
          if (res.details.status === options.expectedStatus) {
            store.dispatch({ type: UserActionTypes.GET_POINTS });
            resolve(res.data);
          }
        })
    });
  }
}