import React from 'react';
import './line-with-dot-indicator.styles.scss'
import { ReactComponent as HotIcon } from "./../../assets/hot_icon.svg";

interface Props {
  votesOnScale: number
}

const LineWithDotIndicator: React.FC<Props> = (props: Props) => {
  return (
    <div className="scope">
      <div className='line-wrapper'>
        <div className='line' />
        <div className='circle' style={{ left: ("calc(" + props.votesOnScale + "% - (35px / 2))") }} >
          <HotIcon />
        </div>
      </div>
    </div>
  )
}

export default LineWithDotIndicator;