import React, { PureComponent } from "react";
import { connect } from 'react-redux'
import "./sign-up-completeProfile-form.scss";
import BtnCustomShadow from "../../../components/btn-custom-shadow/btn-custom-shadow";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import { UserDto } from "../../../dto/user/user-dto";
import { changeUserDetails } from "../../../redux/account-settings/account-settings.actions";
import { Link, Redirect } from "react-router-dom";
import { RoutePath } from "../../../route-paths";
import { AccountSettingsController } from "../../../Api/requests/account-settings";
import { SexTypeEnum } from "../../../dto/account-settings/sex-type-enum";
import FormInputOvalDropdown from "../../input-forms/form-input-oval-dropdown/form-input-oval-dropdown";
import { UserDetailsDto } from "../../../dto/account-settings/user-details-dto";

interface Props {
  changeUserDetails: any;
  user: UserDto;
}

class SignUpCompleteProfileForm extends PureComponent<Props> {
  intervalContainer: any;
  state = {
    yearOfBirth: 0,
    sexTypeEnum: undefined,
    redirect: false,
    disableButton: false,
  }
  private tabWithYears: Array<string> = [];

  componentDidMount() {
    let dateNow = new Date().getFullYear();
    for (let i = 0; i < 73; i++) {
      this.tabWithYears.push(((dateNow - i) - 12).toString())
    }

    AccountSettingsController.GetUserDetails().then((res) => {
      this.setState({
        yearOfBirth: res.yearOfBirth,
        sexTypeEnum: SexTypeEnum[res.sexTypeEnum]
      });
    })
  }

  handleSubmit = async (e: any) => {
    e.preventDefault();
    this.setState({ disableButton: true })

    const { yearOfBirth, sexTypeEnum } = this.state;
    let userDetails = {
      yearOfBirth: yearOfBirth,
      sexTypeEnum: SexTypeEnum[sexTypeEnum! as keyof typeof SexTypeEnum]
    } as UserDetailsDto;

    this.props.changeUserDetails(userDetails);

    this.intervalContainer = window.setInterval(() => {
      this.setState({ disableButton: false })
      AccountSettingsController.GetUserDetails().then((res) => {
        if (res.yearOfBirth !== 0 || SexTypeEnum[res.sexTypeEnum] !== undefined) this.setState({ redirect: true })
      })
    }, 1000);
  };
  
  componentWillUnmount(){
    window.clearInterval(this.intervalContainer);
  }

  handleChangeStringToNum = (e: any) => {
    const { value } = e.target;
    this.setState({ yearOfBirth: Number(value) });
  };

  handleChangeEnum = (e: any) => {
    const { value } = e.target;
    this.setState({ sexTypeEnum: value });
  };

  render() {
    if (this.state.redirect) return <Redirect to={RoutePath.HOME_PAGE} />
    else return (
      <div className="sign-up-tile">
        <p className="title">Complete profile</p>
        <form className="sign-up-form sign-up-password-form" onSubmit={this.handleSubmit}>
          <FormInputOvalDropdown
            name="yearOfBirth"
            type="text"
            label="Year of birth"
            value={this.state.yearOfBirth === 0 ? '' : this.state.yearOfBirth}
            onChange={this.handleChangeStringToNum}
            items={this.tabWithYears}
            keepLabelShrinked={true}
            required={false}
          />
          <FormInputOvalDropdown
            name="sexTypeEnum"
            type="text"
            label="Sex"
            value={this.state.sexTypeEnum === 0 ? '' : this.state.sexTypeEnum}
            keepLabelShrinked={true}
            onChange={this.handleChangeEnum}
            items={['Man', 'Woman', 'Other', '']}
            required={false}
          />
          <BtnCustomShadow type="submit" disabled={this.state.disableButton ? true : false}>Finish</BtnCustomShadow>
          <p>You can complete it later - <Link to={RoutePath.HOME_PAGE}>skip</Link></p>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  changeUserDetails: (userDetails: UserDetailsDto) => dispatch(changeUserDetails(userDetails))
})

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpCompleteProfileForm);