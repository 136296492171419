import React from "react";
import { RouteComponentProps } from "react-router-dom";
import VoteForComparere from "../../components/vote-comparere/vote-comparere.component";
import ComparereWithVotesAndUserChoiceContainer from '../../containers/compareres-with-votes-and-user-choice.container'
import { connect } from "react-redux";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { createStructuredSelector } from "reselect";
import "./authorized-home-page.styles.scss";

interface Props {
  match?: any;
  user?: any;
}

class AuthorizedHomePage extends React.Component<Props & RouteComponentProps, {}> {
  render() {
    const { match, user, history } = this.props;
    return (
      <>
        <div className='vote-component-wrapper'>
          <VoteForComparere uc={match && match.params ? match.params.uc : undefined} history={history} />
        </div>
        <div className='home-page-components-wrapper'>
          {user && user.token ? <>
            <ComparereWithVotesAndUserChoiceContainer />
          </> : null}
        </div>
      </>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser
});

export default connect(mapStateToProps, null)(AuthorizedHomePage);