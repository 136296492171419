import React from 'react';
import { SupportForm } from '../../forms/support-form/support-form.component';
import MichalPic from '../../assets/michal.png';
import ArekPic from '../../assets/arek.png';
import './contact-page.styles.scss';
import GitHubIcon from '../../assets/github.svg';
import LinkedInIcon from '../../assets/linkedin.svg';
import EmailIcon from '../../assets/email.svg';
import PageTitleBar from '../../components/text-components/page-title-bar/page-title-bar';

export const ContactPage = () => {
  return (
    <div className='contact-page'>
      <PageTitleBar>Contact</PageTitleBar>
      <div className='form-and-founders'>
        <SupportForm />
        <div className='founders'>
          <div className='founders-body'>
            <h2 className='title'>Co-founders</h2>
            <div className='founders-wrapper'>
              <div className='founder'>
                <img className='founder-picture' src={MichalPic} alt="Michał Kowalczyk" />
                <p className='founder-name'>Michał Kowalczyk</p>
                <div className='founder-contact-media'>
                  <a href="https://github.com/MichalKowalczyk" target="_blank" rel="noopener noreferrer">
                    <img src={GitHubIcon} className='media-icon' alt="github" />
                  </a>
                  <a href="https://www.linkedin.com/in/michal-p-kowalczyk/" target="_blank" rel="noopener noreferrer">
                    <img src={LinkedInIcon} className='media-icon' alt="linkedin" />
                  </a>
                  <a href="mailto:michal.p.kowalczyk@gmail.com">
                    <img src={EmailIcon} className='media-icon' alt="email" />
                  </a>
                </div>
              </div>
              <div className='founder last'>
                <img className='founder-picture' src={ArekPic} alt="Arkadiusz Łysakowski" />
                <p className='founder-name'>Arkadiusz Łysakowski</p>
                <div className='founder-contact-media'>
                  <a href="https://github.com/Ubiquer" target="_blank" rel="noopener noreferrer">
                    <img src={GitHubIcon} className='media-icon' alt="github" />
                  </a>
                  <a href="https://linkedin.com/in/arkadiusz-łysakowski-b55107101" target="_blank" rel="noopener noreferrer">
                    <img src={LinkedInIcon} className='media-icon' alt="linkedin" />
                  </a>
                  <a href="mailto:lysakowski.arkadiusz91@gmail.com">
                    <img src={EmailIcon} className='media-icon' alt="email" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}