const VotesCompareresTypes = {
  GET_COMPARERE_PAIR_FOR_COMPARISON: 'GET_COMPARERE_PAIR_FOR_COMPARISON',
  GET_COMPARERE_PAIR_FOR_COMPARISON_SUCCESS: 'GET_COMPARERE_PAIR_FOR_COMPARISON_SUCCESS',
  ADD_VOTE: 'ADD_VOTE',
  ADD_VOTE_SUCCESS: 'ADD_VOTE_SUCCESS',
  INCREMENT_VOTE_COUNTER: 'INCREMENT_VOTE_COUNTER',
  GET_HISTORY_OF_USER_CHOICES_WITH_VOTES_WITH_COMPARERES: 'GET_HISTORY_OF_USER_CHOICES_WITH_VOTES_WITH_COMPARERES',
  GET_HISTORY_OF_USER_CHOICES_WITH_VOTES_WITH_COMPARERES_SUCCESS: 'GET_HISTORY_OF_USER_CHOICES_WITH_VOTES_WITH_COMPARERES_SUCCESS',
  RESET_VOTE_COUNTER: 'RESET_VOTE_COUNTER'
};

export default VotesCompareresTypes;