import React from "react";
import './form-input-oval-dropdown.scss'
import CollapsibleMenu from "../../../components/collapsible-menu/collapsible-menu.component";
import iconUp from "../../../assets/up_icon.svg"
import iconDown from "../../../assets/down_icon.svg"

interface Props {
  name: string
  type: string,
  label: string,
  value: string,
  onChange: () => {},
  required: boolean,
  icon?: JSX.Element,
  items: Array<string>
  keepLabelShrinked?: boolean
}

interface State {
  isMenuOpen: boolean;
  selectedItem: string | undefined;
}

class FormInputOvalDropdown extends React.Component<Props & any, State> {
  constructor(props: Props & any) {
    super(props);

    this.state = {
      isMenuOpen: false,
      selectedItem: undefined
    };
  }

  handleClose = () => {
    this.setState({ isMenuOpen: false })
  }

  handleOpen = () => {
    this.setState({ isMenuOpen: true })
  }

  setItemValue = (value: string) => {
    const { name } = this.props;
    this.props.onChange({ target: { name: name, value: value } })
  }

  render() {
    const { name, type, value, required, onChange, label, items, keepLabelShrinked } = this.props;
    const { isMenuOpen } = this.state;
    return (
      <div className={"form-input-oval-dropdown-group dropdown " + type} onClick={this.handleOpen}>
        <input
          className="form-input"
          name={name}
          type={type}
          value={value ? value : ''}
          autoComplete={"off"}
          required={required}
          onChange={onChange}
          onKeyDown={(e: any) => { e.preventDefault() }}
        />
        {isMenuOpen ? <img src={iconUp} alt="up" className="dropdownIcon" /> : <img src={iconDown} alt="down" className="dropdownIcon" />}
        {
          label ? (
            <>
              <label className={keepLabelShrinked && value !== 0 && value !== '' && value !== undefined ? 'shrink form-input-label' : ((value && value.length) || isMenuOpen ? 'shrink form-input-label' : 'form-input-label')}>
                {label}
              </label>
              <span className={isMenuOpen ? 'open bar' : 'bar'}></span>
            </>) : null
        }
        <CollapsibleMenu isOpen={isMenuOpen} handleClose={this.handleClose}>
          {items.filter((item: string) => item !== '').map((item: string, index: number) => {
            return (
              <div className="dropdown-item" key={index} onClick={() => { this.setItemValue(item) }}>{item}</div>
            )
          })}
        </CollapsibleMenu>
      </div>
    )
  }
}

export default FormInputOvalDropdown;