import React from 'react'
import './../sign-up.scss';
import signUpEmailImg from "../../../assets/signup/signup_email.svg";
import SignUpEmailForm from '../../../forms/sign-up-forms/sign-up-email-form/sign-up-email-form';



const SignUpPage = () => {
  return (
    <div className='sign-up-page container'>
      <div className='col50 left'>
        <div className="description">
          <h3>Join a growing community of <br /> designers, who feel the trends </h3>
        </div>
        <SignUpEmailForm/>
      </div>
      <div className="col50 right">
        <img src={signUpEmailImg} alt="Sign Up" />
      </div>
    </div>
  )
}

export default SignUpPage;