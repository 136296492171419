import React, { useLayoutEffect } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { getComparereDetails } from '../../../redux/user-compareres/user-compareres.actions';
import { ComparereDetailsTypeEnum } from '../../../dto/user-compareres/comparere-details-type-enum';
import { selectComparereDetails } from '../../../redux/user-compareres/user-compareres.selectors';
import { SubcategoryDistributionDto } from '../../../dto/user-compareres/subcategory-distribution-dto';
import './compareres-graph.styles.scss';
import GraphColumn from './graph-column/graph-column.component';

interface Props {
  userComparereId: number,
  comparereDetailsTypeEnum: ComparereDetailsTypeEnum,
}

const CompareresGraph: React.FC<Props> = (props: Props) => {
  const comparereDetails = useSelector(selectComparereDetails, shallowEqual);
  const dispatch = useDispatch();
  const { userComparereId, comparereDetailsTypeEnum } = props;

  useLayoutEffect(() => {
    dispatch(getComparereDetails(userComparereId, comparereDetailsTypeEnum));
  }, [comparereDetailsTypeEnum]);

  const comparereMaxVotes = (): number => {
    let maxA = comparereDetails.subcategoryDistributionList.reduce((prev, current) => {
      return ((prev.userPictureIdWithVotesDto_A.votes || prev.userPictureIdWithVotesDto_B.votes) > (current.userPictureIdWithVotesDto_A.votes || current.userPictureIdWithVotesDto_B.votes)) ? prev : current
    });
    return maxA.userPictureIdWithVotesDto_A.votes > maxA.userPictureIdWithVotesDto_B.votes ? maxA.userPictureIdWithVotesDto_A.votes : maxA.userPictureIdWithVotesDto_B.votes;
  }

  const graphColumns = (): Array<JSX.Element | null> | null => {
    return comparereDetails.subcategoryDistributionList ? comparereDetails.subcategoryDistributionList.map
      ((item: SubcategoryDistributionDto) => {
        if (item.userPictureIdWithVotesDto_A.votes === 0 && item.userPictureIdWithVotesDto_B.votes === 0) {
          return (
            null
          )
        } else {
          return <GraphColumn key={item.indicator} subcategoryDistributionDto={item} maxVotesCount={comparereMaxVotes()} />
        }
      }) : null;
  }

  return (
    <div className='compareres-graph'>
      {graphColumns() ? graphColumns()!.map((item: any) => {
        return item;
      }) : null}
      {graphColumns() && graphColumns()!.filter(x=>x !== null).length === 0 ?
        <p className="info-not-enough-data"><strong>Not enough data.</strong><br /> Please wait for more votes or share, to get it faster!</p> : null
      }
    </div>
  );
}

export const MemoizedCompareresGraph = React.memo(CompareresGraph)