import React, { PureComponent } from "react";
import { connect } from 'react-redux'
import "./sign-up-password-form.scss";
import FormInputOval from "../../input-forms/form-input-oval/form-input-oval.component";
import BtnCustomShadow from "../../../components/btn-custom-shadow/btn-custom-shadow";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import { UserDto } from "../../../dto/user/user-dto";
import { UserRoleEnum } from "../../../dto/user/user-role-enum";
import { FinalizeSignUpDto } from "../../../dto/account-settings/finalize-signup-dto";
import { finalizeSignUp } from "../../../redux/account-settings/account-settings.actions";
import { Link } from "react-router-dom";
import { RoutePath } from "../../../route-paths";

interface Props {
  finalizeSignUp: any;
  user: UserDto;
}

class SignUpPasswordForm extends PureComponent<Props> {
  timeoutContainer: any;
  state = {
    password: '',
    disableButton: false,
  }

  handleSubmit = async (e: any) => {
    e.preventDefault();

    const { password } = this.state;

    const finalizeSignUpDto: FinalizeSignUpDto = { email: this.props.user.email, newPassword: password }
    this.props.finalizeSignUp(finalizeSignUpDto);

    this.setState({ disableButton: true })
    this.timeoutContainer = window.setTimeout(() => {
      this.setState({ disableButton: false })
    }, 1500);
  };

  componentWillUnmount(){
    window.clearInterval(this.timeoutContainer);
  }

  handleChange = (e: any) => {
    this.setState({ password: e.target.value })
  }

  render() {
    const { user } = this.props;
    const isTempUser = user && user.roles ? user.roles.indexOf(UserRoleEnum.TempUser) > -1 : false;

    return (
      <div className="sign-up-tile">
        <p className="title">Set your password</p>
        <form className="sign-up-form sign-up-password-form" onSubmit={this.handleSubmit}>
          <FormInputOval
            name="password"
            type="password"
            label="Password"
            value={this.state.password}
            onChange={this.handleChange}
            required
          />
          <BtnCustomShadow type="submit" disabled={!isTempUser || this.state.disableButton ? true : false}>Submit</BtnCustomShadow>
          <p>You can do it later or just reset password if you session will expire - <Link to={RoutePath.COMPLETEYOURPROFILE}>skip</Link></p>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  finalizeSignUp: (finalizeSignUpDto: FinalizeSignUpDto) => dispatch(finalizeSignUp(finalizeSignUpDto))

})

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPasswordForm);