import React, { FC } from 'react';
import './arrow-btn.styles.scss';
import singleArrowLeft from '../../assets/single-arrow-left.svg';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  left?: boolean;
  right?: boolean;
  uppercase?: boolean;
  link?: string;
  fontSize?: string;
  active?: boolean
}


const ArrowBtn: FC<Props> = (props: Props | any) => {
  const { title, left, right, uppercase, link, fontSize, active } = props;
  const btnStyles: React.CSSProperties | undefined = { textTransform: uppercase ? "uppercase" : undefined, fontSize: fontSize ? fontSize : '1.4rem', color: active ? 'black' : '#BCBCBC' };
  const linkTo: {} = link ? <Link to={link}>{title}</Link> : title;

  return (
    <div className="arrow-btn-component"> 
      {
        left ?
          <div className='arrow-btn-wrapper'>
            <img className='arrow left' src={singleArrowLeft} alt='singleArrowLeft' />
            <button className='arrow-btn' style={btnStyles}>{linkTo}</button>
          </div>
          : right ?
            <div className='arrow-btn-wrapper'>
              <button className='arrow-btn' style={btnStyles}>{linkTo}</button>
              <img className='arrow right' src={singleArrowLeft} alt='singleArrowRight' />
            </div> :
            <div className='arrow-btn-wrapper'>
              <button className='arrow-btn' style={btnStyles}>{linkTo}</button>
            </div>
      }
    </div>
  )
}

export default ArrowBtn