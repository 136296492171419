import TabDetails from "../../components/tabs/tab-details/tab-details.component";
import FeedbackGraph from '../../assets/landingPage/tabs/feedback_graph.svg';
import DragNDrop from '../../assets/landingPage/tabs/drag_n_drop.svg';
import ShareComparere from '../../assets/landingPage/tabs/share_comparere.svg';
import CommunityChoice from '../../assets/landingPage/tabs/community_choice.svg';
import BuildProfile from '../../assets/landingPage/tabs/build_profile.svg';
import Compare from '../../assets/landingPage/tabs/compare.svg';
import React from "react";

export const uploadCompareres = [
  <TabDetails
    title={
      <>Add two variants of your design, <br />
        that you want to compare</>
    }
    imagePath={DragNDrop}
    about='It could be design at an early stage, community will understand, it is not finished, and may not be perfect'
  />,
  <TabDetails title={<>Share link to your comparere!</>} imagePath={ShareComparere} about='Share with your friends and teammates to get their feedback.
  It will help you get results much faster!' />,
  <TabDetails title={<>Collect feedback and <br /> start to analize your data!</>} imagePath={FeedbackGraph} about='' />
];

export const voteCompareres = [<TabDetails title={<>Compare users' desings, collect points</>} imagePath={Compare} about='Answers consistent with community choices will be rewarded with points' />,
<TabDetails title={<>Analaze community choices to <br/> better understand trends in design</>} imagePath={CommunityChoice} about='Keep up with trends'/>,
<TabDetails title={<>Build your profile as a specialist, who senses design trends splendidly.</>} imagePath={BuildProfile} 
about='Higher ratings will help you in building your authority, and increase your chances of finding better clients' />]

// about='High votes rating will give you chance for better clients' />]