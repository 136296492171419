import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { ApiPath } from "../../Api/ApiPath";
import { callApi } from "../../Api/api-call-service";
import { ApiMethodDto, UrlParam } from "../../Api/api-method-dto";
import { SagaInputDto } from "../../Api/saga-input-dto";
import { DeleteAccountDto } from "../../dto/account-settings/delete-account-dto";
import { ChangePasswordDto } from "../../dto/account-settings/change-password-dto";
import AccountSettingsTypes from "./account-settings.types";
import UserActionTypes from "../user/user.types";
import { ToastsStore } from "react-toasts";
import { ContactEmailDto } from "../../dto/contact-email-dto";
import { NewsletterSettingsDto } from "../../dto/account-settings/newsletter-settings-dto";
import { UserDetailsDto } from "../../dto/account-settings/user-details-dto";
import { FinalizeSignUpDto } from "../../dto/account-settings/finalize-signup-dto";
import { SignInDto } from "../../dto/sign-in-dto";

function* changeUserDetailsAsync(sagaInput_changeUserDetails: SagaInputDto<UserDetailsDto>) {
  const token = yield select(state => state.user.token);
  try {
    const options = {
      body: sagaInput_changeUserDetails.payload,
      headers: { Authorization: token, AccessControlAllowOrigin: "*", "Content-Type": "application/json" },
      method: "POST",
      expectedStatus: 200
    } as ApiMethodDto;

    yield call(callApi, options.method, ApiPath.accountSettings_changeUserDetails, options);

    ToastsStore.success('Your details settings have been changed.');
    yield put({ type: UserActionTypes.GET_POINTS });

  } catch (error) {
    console.error("Something went wrong.", error);
  }
}

function* changeNotificationsAsync(sagaInput_changeNotifications: SagaInputDto<NewsletterSettingsDto>) {
  const token = yield select(state => state.user.token);
  try {
    const options = {
      body: sagaInput_changeNotifications.payload,
      headers: { Authorization: token, AccessControlAllowOrigin: "*", "Content-Type": "application/json" },
      method: "POST",
      expectedStatus: 200
    } as ApiMethodDto;

    yield call(callApi, options.method, ApiPath.accountSettings_changeNotifications, options);

    ToastsStore.success('Your notifications settings have been changed.');

  } catch (error) {
    console.error("Something went wrong.", error);
  }
}
function* finalizeSignUpAsync(sagaInput_finalizeSignUp: SagaInputDto<FinalizeSignUpDto>) {
  const token = yield select(state => state.user.token);
  try {
    let options = {
      body: sagaInput_finalizeSignUp.payload,
      headers: { Authorization: token, AccessControlAllowOrigin: "*", "Content-Type": "application/json" },
      method: "POST",
      expectedStatus: 200
    } as ApiMethodDto;

    yield call(callApi, options.method, ApiPath.accountSettings_finalizeSignUp, options);

    options = {
      body: {email: sagaInput_finalizeSignUp.payload.email, password: sagaInput_finalizeSignUp.payload.newPassword} as SignInDto,
      headers: { "Content-Type": "application/json" },
      method: "POST",
      expectedStatus: 200
    } as ApiMethodDto;
    
    const res = yield call(callApi, "post", ApiPath.auth_signIn, options);

    yield put({
      type: UserActionTypes.SIGN_IN_SUCCESS,
      payload: {
        email: sagaInput_finalizeSignUp.payload.email,
        roles: res.data.roles.map((item: any) => { return item.role }),
        token: "Bearer " + res.data.token.toString(),
        lastRequestTime: Date.UTC,
        expiresStart: res.data.expiresStart,
        expiresEnd: res.data.expiresEnd
      }
    });

    ToastsStore.success('Your password was set successfully');
    // yield put({ type: UserActionTypes.AUTHORIZE });
    yield put({ type: UserActionTypes.GET_POINTS });


  } catch (error) {
    console.error("Something went wrong.", error);
  }
}

function* changePasswordAsync(sagaInput_changePassword: SagaInputDto<ChangePasswordDto>) {
  const token = yield select(state => state.user.token);
  try {
    const options = {
      body: sagaInput_changePassword.payload,
      headers: { Authorization: token, AccessControlAllowOrigin: "*", "Content-Type": "application/json" },
      method: "POST",
      expectedStatus: 200
    } as ApiMethodDto;

    yield call(callApi, options.method, ApiPath.accountSettings_changePassword, options);

    ToastsStore.success('Your password has been changed, please Sign In again with new credentials.');
    yield put({ type: UserActionTypes.SIGN_OUT_SUCCESS });

  } catch (error) {
    console.error("Something went wrong.", error);
  }
}

function* deleteAccountAsync(sagaInput_deleteAccount: SagaInputDto<DeleteAccountDto>) {
  const token = yield select(state => state.user.token);
  try {
    const options: ApiMethodDto = {
      body: sagaInput_deleteAccount.payload,
      headers: { Authorization: token, AccessControlAllowOrigin: "*", "Content-Type": "application/json" },
      method: "POST",
      expectedStatus: 200
    };

    const rest = yield call(callApi, options.method, ApiPath.accountSettings_deleteAccount, options);

    ToastsStore.success('Your account has been deleted.');
    yield put({ type: UserActionTypes.SIGN_OUT_SUCCESS, payload: rest.payload});

  } catch (error) {
    console.error("Something went wrong. ", error)
  }
}

function* resetPasswordAsync(email: any) {
  try {
    const options = {
      method: "POST",
      expectedStatus: 200,
      params: {
        name: 'email',
        value: email.payload
      } as UrlParam
    } as ApiMethodDto

    yield call(callApi, options.method, ApiPath.reset_password, options);
    ToastsStore.success('Password has been sent on provided email');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* sendContactEmailAsync(email: SagaInputDto<ContactEmailDto>) {
  try {
    const options = {
      body: email.payload,
      headers: { "Content-Type": "application/json" },
      method: "POST",
      expectedStatus: 200
    } as ApiMethodDto;

    yield call(callApi, options.method, ApiPath.send_contact_email, options);

    ToastsStore.success('Message has been sent');

  } catch (error) {
    console.error("Something went wrong.", error);
  }
}

function* addSocialMediaLinkAsync(socialMediaLink: SagaInputDto<string>) {
  const token = yield select(state => state.user.token);
  try {
    const options = {
      params: { name: 'link', value: socialMediaLink.payload } as UrlParam,
      headers: { Authorization: token, "Content-Type": "application/json" },
      method: "GET",
      expectedStatus: 200
    } as ApiMethodDto;

    const res = yield call(callApi, options.method, ApiPath.accountSettings_addSocialMediaLink, options);
    yield put({ type: UserActionTypes.ADD_SOCIAL_MEDIA_LINK_SUCCESS, payload: res.data});

  } catch (error) {
    console.error("Something went wrong.", error);
  }
}

function* getUserProfileAsync() {
  const token = yield select(state => state.user.token);
  try {
    const options = {
      headers: { Authorization: token, "Content-Type": "application/json" },
      method: "GET",
      expectedStatus: 200
    } as ApiMethodDto;

    const res = yield call(callApi, options.method, ApiPath.accountSettings_getUserProfile, options);
    yield put({ type: UserActionTypes.ADD_SOCIAL_MEDIA_LINK_SUCCESS, payload: res.data});

  } catch (error) {
    console.error("Something went wrong.", error);
  }
}

function* deleteSocialMediaLink(socialMediaLinkId: any){
  const token = yield select(state => state.user.token);
  try {
    const options = {
      params: { name: 'id', value: socialMediaLinkId.payload } as UrlParam,
      headers: { Authorization: token, "Content-Type": "application/json" },
      method: "GET",
      expectedStatus: 200
    } as ApiMethodDto;

    const res = yield call(callApi, options.method, ApiPath.accountSettings_deleteSocialMediaLink, options);
    yield put({ type: UserActionTypes.ADD_SOCIAL_MEDIA_LINK_SUCCESS, payload: res.data});

  } catch (error) {
    console.error("Something went wrong.", error);
  } 
}

function* updateUserLogin(login: any){
  const token = yield select(state => state.user.token);
  try {
    const options = {
      params: { name: 'login', value: login.payload } as UrlParam,
      headers: { Authorization: token, "Content-Type": "application/json" },
      method: "GET",
      expectedStatus: 200
    } as ApiMethodDto;

    const res = yield call(callApi, options.method, ApiPath.accountSettings_updateUserLogin, options);
    yield put({ type: UserActionTypes.ADD_SOCIAL_MEDIA_LINK_SUCCESS, payload: res.data});

  } catch (error) {
    console.error("Something went wrong.", error);
  }
}


function* watchChangeUserDetailsAsyncAsync() {
  yield takeLatest(AccountSettingsTypes.CHANGE_USERDETAILS as any, changeUserDetailsAsync);
}

function* watchChangeNotificationsAsync() {
  yield takeLatest(AccountSettingsTypes.CHANGE_NOTIFICATIONS as any, changeNotificationsAsync);
}

function* watchResetPassword() {
  yield takeLatest(AccountSettingsTypes.RESET_PASSWORD as any, resetPasswordAsync);
}

function* watchChangePassword() {
  yield takeLatest(AccountSettingsTypes.CHANGE_PASSWORD, changePasswordAsync);
}

function* watchfinalizeSignUp() {
  yield takeLatest(AccountSettingsTypes.FINALIZE_SIGNUP, finalizeSignUpAsync);
}

function* watchDeleteAccount() {
  yield takeLatest(AccountSettingsTypes.DELETE_ACCOUNT, deleteAccountAsync);
}

function* watchSendContactEmail() {
  yield takeLatest(AccountSettingsTypes.SEND_CONTACT_EMAIL, sendContactEmailAsync);
}

function* watchAddSocialMediaLinkAsync() {
  yield takeLatest(AccountSettingsTypes.ADD_SOCIAL_MEDIA_LINK, addSocialMediaLinkAsync);
}

function* watchGetUserProfileAsync() {
  yield takeLatest(AccountSettingsTypes.GET_USER_PROFILE, getUserProfileAsync);
}

function* watchDeleteSocialMediaLinkAsync() {
  yield takeLatest(AccountSettingsTypes.DELETE_SOCIAL_MEDIA_LINK, deleteSocialMediaLink);
}

function* watchUpdateUserLoginAsync() {
  yield takeLatest(AccountSettingsTypes.UPDATE_USER_LOGIN, updateUserLogin);
}

export default function* userSagas() {
  yield all([
    call(watchChangePassword),
    call(watchfinalizeSignUp),
    call(watchDeleteAccount),
    call(watchResetPassword),
    call(watchSendContactEmail),
    call(watchChangeNotificationsAsync),
    call(watchChangeUserDetailsAsyncAsync),
    call(watchAddSocialMediaLinkAsync),
    call(watchGetUserProfileAsync),
    call(watchDeleteSocialMediaLinkAsync),
    call(watchUpdateUserLoginAsync)]);
}
