import React, { useState } from "react";
import '../form-input-oval/form-input-oval.styles.scss';
import './form-input-oval-btn.scss';

interface Props {
  children?: React.ReactChild
  name: string
  type: string,
  label: string,
  value: string,
  handleChange: () => {},
  required: boolean,
  readOnly?: boolean,
  icon?: JSX.Element,
  labelIcon?: string,
  labelOffOnClick?: boolean,
  textAreaPaddings?: number,
  withBorder?: boolean,
  disabled: boolean
}

const FormInputOvalBtn: React.FC<any> = (props: Props) => {
  const { name, type, label, value, required, readOnly, icon, labelOffOnClick, withBorder, labelIcon, textAreaPaddings, children, disabled, ...rest } = props;
  const [labelIconOn, setLabelIconOn] = useState(true);

  return (
    <div className={"group-oval standardForm form-input-with-btn " + type}>
      {
        type === "textarea" ?
          <textarea style={textAreaPaddings ? { padding: `${textAreaPaddings}px` } : undefined} onFocus={() => setLabelIconOn(false)} onBlur={() => setLabelIconOn(true)} className={withBorder ? 'form-input-with-border' : 'form-input'} value={value} {...rest} /> :
          <>
            <input className="form-input" {... { ...props, children: undefined }} value={value} />
            <div className={"form-input-with-btn__icon"}>
              {children}
            </div>
          </>
      }
      {icon ? icon : null}
      {
        label || labelIcon ? (
          <>
            {labelIcon && labelIconOn && value.length === 0 ? <img className='label-icon' src={labelIcon} alt='label-icon' /> : null}
            <label className={value.length ? 'shrink form-input-label' : labelOffOnClick ? 'form-input-label-off' : 'form-input-label'}>
              {label}
            </label>
            {labelOffOnClick ? null : <span className="bar" />}
          </>) : null
      }
    </div>
  )
}

export default FormInputOvalBtn;