import React, { useState, useEffect } from 'react';
import './privacy-edit.scss';
import { useDispatch } from 'react-redux';
import { updateComparerePrivacy } from '../../redux/user-compareres/user-compareres.actions';
import Checkbox from '../checkbox/checkbox.component';

interface Props {
  isPrivate: boolean;
  comparereId: number
}

const PrivacyEdit = (props: Props) => {
  const { isPrivate, comparereId } = props;
  const dispatch = useDispatch();
  const [isPrivateState, setIsPrivate] = useState(isPrivate);
  const [firstRun, setIsFirstRun] = useState(true);

  useEffect(() => {
    setIsPrivate(isPrivate);
  }, [isPrivate])

  useEffect(() => {
    if(!firstRun){
      dispatch(updateComparerePrivacy(comparereId, isPrivateState));
    }
  }, [isPrivateState])
  
  const handleSave = () => {
    if(firstRun) setIsFirstRun(false)
    setIsPrivate(!isPrivateState)
  }

  return (
    <div className={'context-edit-wrapper privacy-edit-wrapper'}>
      <h3>Privacy</h3>
      <Checkbox
        onChange={(e: any) => handleSave()}
        label='Is private? (Will be visible only to the people you share the link with)'
        name='isPrivate'
        defaultValue={isPrivateState}
        id='isPrivate'
      />
    </div>
  )
}

export default React.memo(PrivacyEdit); 