import "./aesthetics-indicator.scss";
import individual from "./../../navIcons/individual.svg"
import majority from "./../../navIcons/majority.svg"
import hotIndicator from "./../../navIcons/hotIndicator.svg"
import React from "react";
import { UserDto } from "../../dto/user/user-dto";

interface Props {
  user: UserDto;
}

interface State {
  isMenuOpen: boolean;
  pointsAnimation: string;
  userPoints?: number;
  isTop: boolean;
}

export class AestheticsIndicator extends React.PureComponent<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      isMenuOpen: false,
      userPoints: undefined,
      pointsAnimation: "",
      isTop: true
    }
  }

  calculatePosition = (strike: number): string => {
    return (((strike/55)+1)*50).toFixed(1).toString() + '%';
  }

  render() {
    const { user } = this.props;
    const asl = this.calculatePosition(user.strike);
    return (
      <div className="aesthetic-indicator" title={"Aesthetics Sense Level: " + asl}>
        <div className="individual" title="Individual">
          <img src={individual} alt="individual" />
        </div>
        <div className="progress-bar-wrapper" >
          <div className="progress-bar">
            <img src={hotIndicator} alt="hotIndicator" className="hotIndicator" style={{ left: asl }} />
          </div>
        </div>
        <div className="majority" title="Majority">
          <img src={majority} alt="majority" />
        </div>
      </div>
    )
  }
}

export default AestheticsIndicator;