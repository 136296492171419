import React, { PureComponent } from "react";
import { connect } from 'react-redux'
import { signUpTemp } from "../../../redux/user/user.actions";
import "./sign-up-email-form.scss";
import { RoutePath } from "../../../route-paths";
import { Link } from "react-router-dom";
import FormInputOval from "../../input-forms/form-input-oval/form-input-oval.component";
import BtnCustomShadow from "../../../components/btn-custom-shadow/btn-custom-shadow";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import { UserDto } from "../../../dto/user/user-dto";

interface Props {
  signUpTemp: any;
  user: UserDto;
}

class SignUpEmailForm extends PureComponent<Props> {
  timeoutContainer: any;
  state = {
    email: '',
    disableButton: false,
  }

  handleSubmit = async (e: any) => {
    e.preventDefault();
    this.props.signUpTemp(this.state.email);
    this.setState({ disableButton: true })
    this.timeoutContainer = window.setTimeout(() => {
      this.setState({ disableButton: false })
    }, 1500);
  };

  componentWillUnmount(){
    window.clearInterval(this.timeoutContainer);
  }
  
  handleChange = (e: any) => {
    this.setState({ email: e.target.value })
  }

  render() {
    const { user } = this.props;

    return (
      <div className="sign-up-tile">
        <p className="title">Sign up</p>
        <form className="sign-up-form sign-up-email-form" onSubmit={this.handleSubmit}>
          <FormInputOval
            name="email"
            type="email"
            label="Email"
            value={this.state.email}
            onChange={this.handleChange}
            required
          />
          <BtnCustomShadow type="submit" disabled={(user && user.token) || this.state.disableButton}>Submit</BtnCustomShadow>
          <p>By submitting you declare that you have read and accepted <Link to={RoutePath.USER_AGREEMENT}>User Agreement</Link>.</p>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  signUpTemp: (email: string) => dispatch(signUpTemp(email))
})

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpEmailForm);
