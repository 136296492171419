const UserCompareresTypes = {
  GET_USER_COMPARERES: 'GET_USER_COMPARERES',
  GET_USER_COMPARERES_SUCCESS: 'GET_USER_COMPARERES_SUCCESS',
  DELETE_USER_COMPARERE: 'DELETE_USER_COMPARERE',
  DELETE_USER_COMPARERE_SUCCESS: 'DELETE_USER_COMPARERE_SUCCESS',
  GET_COMPARERE_DETAILS: 'GET_COMPARERE_DETAILS',
  GET_COMPARERE_DETAILS_SUCCESS: 'GET_COMPARERE_DETAILS_SUCCESS',
  GET_USER_COMPARERE_BY_ID: 'GET_USER_COMPARERE_BY_ID', 
  GET_USER_COMPARERE_BY_ID_SUCCESS: 'GET_USER_COMPARERE_BY_ID_SUCCESS',
  CLEAR_USER_COMPARERE_DETAILS: 'CLEAR_USER_COMPARERE_DETAILS',
  CLEAR_USER_COMPARERES: 'CLEAR_USER_COMPARERES',
  UPDATE_COMPARERE_CONTEXT: 'UPDATE_COMPARERE_CONTEXT',
  UPDATE_COMPARERE_PRIVACY: 'UPDATE_COMPARERE_PRIVACY'
};

export default UserCompareresTypes;