import React, { useState } from 'react';
import ComparereContextIcon from '../../assets/comparere-context/comparere-context.svg';
import './comparere-about.scss';
import PopupOverlayIndependent from '../popup-overlay-independent/popup-overlay-independent';
import OutsideWrapperDetector from '../outside-wrapper-detector/outside-wrapper-detector.component';

interface Props {
  comparereAboutOpen: () => any;
  about: string;
}

const ComparereAbout = (props: Props) => {
  const [show, setshow] = useState(false);

  const handleShow = () => {
    if (props.about) {
      setshow(!show);
      props.comparereAboutOpen();
    }
  }

  return (
    <>
      <PopupOverlayIndependent isActive={show} />
      <div className='comparere-about-wrapper'>
        <img onClick={handleShow} className='context-icon' style={props.about ? { opacity: 1, cursor: "pointer" } : { opacity: 0.45, cursor: "not-allowed" }} src={ComparereContextIcon} alt="comparere-context-icon" />
        {show && props.about ?
          <OutsideWrapperDetector handleClose={handleShow}>
            <div className={show ? 'comparere-about' : 'none'}>
              {props.about}
            </div>
          </OutsideWrapperDetector>
          : null}
      </div>
    </>
  )
}

export default ComparereAbout;