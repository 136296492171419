import React from 'react';
import './checkbox-shadow.scss';


interface Props {
  onChange: () => {},
  label: string;
  id: string;
  name: string;
  required: boolean;
  defaultValue?: boolean;
}

const CheckboxShadow: React.FC<any> = (props: Props) => {
  const { onChange, label, id, name, required, defaultValue } = props;
  return (
    <div className="group-input-checkbox-shadow">
      {defaultValue != null ?
        <input
          id={id}
          className="styled-checkbox"
          type="checkbox"
          checked={defaultValue ? true : false}
          name={name}
          required={required}
          onChange={onChange}
        /> :
        <input
          id={id}
          className="styled-checkbox"
          type="checkbox"
          name={name}
          required={required}
          onChange={onChange}
        />
      }
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

export default CheckboxShadow;