import { createSelector } from 'reselect';
import { ComparerereStore } from '../store.types';

const homePageStore = (state: ComparerereStore) => state.homePageStore

export const selectDesignerOfDay = createSelector(
  [homePageStore],
  homePageStore => {
    return homePageStore.designerOfDay
  }
);
