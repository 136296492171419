import { takeLatest, all, call, } from "redux-saga/effects";
import { ApiPath } from "../../Api/ApiPath";
import { callApi } from "../../Api/api-call-service";
import { ApiMethodDto } from "../../Api/api-method-dto";
import { ToastsStore } from "react-toasts";
import SurveyTypes from "./survey.types";
import { SurveyDto } from "../../dto/survey/survey-dto";
import { SagaInputDto } from "../../Api/saga-input-dto";

function* SendSurveyAsync(surveyDto: SagaInputDto<SurveyDto>) {

  try {
    const options = {
      body: surveyDto.payload,
      headers: { "Content-Type": "application/json" },
      method: "POST",
      expectedStatus: 200
    } as ApiMethodDto;

    yield call(callApi, options.method, ApiPath.survey_send, options);

    ToastsStore.success('Survey has been sent.');
  } catch (error) {
    console.error("Something went wrong.", error);
  }
}

function* watchSendSurvey() {
  yield takeLatest(SurveyTypes.SEND_SURVEY, SendSurveyAsync);
}

export default function* surveySagas() {
  yield all([call(watchSendSurvey)]);
}