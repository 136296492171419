import './comparere-with-votes-and-user-choice.styles.scss'
import React from 'react'
import { ComparereWithVotesAndUserChoiceDto } from '../../dto/comparere-with-votes-and-user-choice/comparete-with-votes-and-user-choice.ts.dto';
import Tile from "../tile/tile.component";
import { ReactComponent as CorrectChoice } from "./../../assets/heart_icon.svg";
import { ReactComponent as WrongChoice } from "./../../assets/brokenHeart_icon.svg";
import { ReactComponent as YouChoiceIconLeft } from "./../../assets/arrow_left_yourchoice.svg";
import { ReactComponent as YouChoiceIconRight } from "./../../assets/arrow_right_yourchoice.svg";

import LineWithDotIndicator from '../line-with-dot-indicator/line-with-dot-indicator.component';
import BgImgLoader from '../bg-img-loader/bg-img-loader';
import { ApiRootPath } from '../../Api/ApiRootPath';

interface Props {
  userAndCommunityChoice: ComparereWithVotesAndUserChoiceDto
}

const ComparereWithVotesAndUserChoice: React.FC<Props> = (props: Props) => {
  const { userAndCommunityChoice } = props;
  let leftPictureVotes = userAndCommunityChoice.picturesWithVotes[0].votesCount;
  let rightPictureVotes = userAndCommunityChoice.picturesWithVotes[1].votesCount;
  let selectedPictureId = userAndCommunityChoice.selectedPictureId;
  const totalVotes = leftPictureVotes + rightPictureVotes;
  let votesOnScale = totalVotes !== 0 ? (100 - Math.round(100 * leftPictureVotes / totalVotes)) : 50;

  let pathA = userAndCommunityChoice.picturesWithVotes ? userAndCommunityChoice.picturesWithVotes[0].path.indexOf('https') >= 0 ?
    userAndCommunityChoice.picturesWithVotes[0].path : ApiRootPath.rootPath + userAndCommunityChoice.picturesWithVotes[0].path : "";

  let pathB = userAndCommunityChoice.picturesWithVotes ? userAndCommunityChoice.picturesWithVotes[1].path.indexOf('https') >= 0 ?
    userAndCommunityChoice.picturesWithVotes[1].path : ApiRootPath.rootPath + userAndCommunityChoice.picturesWithVotes[1].path : "";
  return (
    <Tile>
      <div>
        <div className="smallTitle">COMMUNITY CHOICE</div>
        <LineWithDotIndicator votesOnScale={votesOnScale} />
        <div className="smallTitle userChoiceIcons" style={{ marginBottom: 20 + 'px' }}>
          {selectedPictureId === userAndCommunityChoice.picturesWithVotes[0].id ? <div className="iconContainer"><YouChoiceIconLeft /> </div> : <div className="iconContainer" />}
          <div>
            YOUR CHOICE
          </div>
          {selectedPictureId !== userAndCommunityChoice.picturesWithVotes[0].id ? <div className="iconContainer"><YouChoiceIconRight /> </div> : <div className="iconContainer" />}
        </div>
        <div className="pictures">
          {selectedPictureId === userAndCommunityChoice.picturesWithVotes[0].id ?
            <div className={"your-choice-indicator"}>
              {votesOnScale < 50 ? <CorrectChoice /> : <WrongChoice />}
            </div>
            : selectedPictureId === userAndCommunityChoice.picturesWithVotes[1].id ?
              <div className={"your-choice-indicator rightSide"}>
                {votesOnScale > 50 ? <CorrectChoice /> : <WrongChoice />}
              </div>
              : null
          }
          <BgImgLoader attributes={{ className: "picture pictureA" }} src={pathA} />
          <div className="breakBar" />
          <BgImgLoader attributes={{ className: "picture pictureB" }} src={pathB} />
        </div>
      </div>
    </Tile>
  )
}
export default ComparereWithVotesAndUserChoice;