import { Component } from "react"
import { withRouter, RouteComponentProps } from "react-router"

class ScrollToTop extends Component<RouteComponentProps, never> {
  componentDidUpdate(prevProps: RouteComponentProps) {
    if (this.props.location !== prevProps.location) {
      this.trackPage();
      window.scrollTo(0, 0);
    }
  }

  trackPage = () => {
    if (window.location.hostname !== 'localhost' && window.location.hostname !== 'code-resort.pl') {
      const gtag = (window as any).gtag;
      gtag('config', 'UA-143705223-3', {
        'page_location': window.location.href,
        'page_path': window.location.pathname,
      })
    }
  };

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)