import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { ApiPath } from "../../Api/ApiPath";
import { callApi } from "../../Api/api-call-service";
import { ApiMethodDto } from "../../Api/api-method-dto";
import HomePageTypes from "./home-page.types";
import { DesignerOfDayDto } from "../../dto/designer/designer-of-day";

function* GetDesignerOfTheDayAsync() {
  try {
    const options = {
      headers: { AccessControlAllowOrigin: "*" },
      method: "GET",
      expectedStatus: [200, 404]
    } as ApiMethodDto;

    const res = yield call(callApi, options.method, ApiPath.homePage_getDesigner, options);
    yield put({ type: HomePageTypes.GET_DESIGNER_OF_THE_DAY_SUCCESS, payload: res.data as DesignerOfDayDto });
    const test = yield select(state => state);
    console.log(test);
  } catch (error) {
    console.error("Something went wrong.", error);
  }
}

function* watchGetDesignerOfTheDay() {
  yield takeLatest(HomePageTypes.GET_DESIGNER_OF_THE_DAY, GetDesignerOfTheDayAsync);
}

export default function* homePageSagas() {
  yield all([call(watchGetDesignerOfTheDay)]);
}
