export class RoutePath {
  [key: string]: any;
  static HOME_PAGE: string = '/';
  static HOME_PAGE_WITHPARAM: string = '/uc=:uc';
  static SIGN_IN: string = '/signin';
  static SIGN_UP: string = '/signup';
  static RESET_PASSWORD: string = '/reset';
  static ADD_COMPERERE: string = '/addcomperere';
  static SIGN_OUT: string = '/signout';
  static ACCOUNT_SETTINGS: string = '/account-settings';
  static USER_COMPARERES: string = '/your-compareres';
  static USER_COMPARERES_DETAILS: string = '/your-compareres/:id';
  static VOTE_COMPARERES: string = '/vote-compareres';
  static COOKIES_POLICY: string = '/cookies-policy';
  static POINTS_PAGE: string = '/points';
  static CONTACT: string = '/contact';
  static CONTENT_POLICY: string = '/rules';
  static PAYMENT_STATUS_PAGE: string = '/payment-status/result=:result';
  static PRIVACY_POLICY: string = '/privacy-policy';
  static USER_AGREEMENT: string = '/user-agreement';
  static SURVEY: string = '/survey';
  static FAQ: string = '/faq'
  static FINALIZESIGNUP: string = '/finalize-sign-up-password'
  static COMPLETEYOURPROFILE: string = '/complete-your-profile'
}