import { select, takeLatest, all, call, put } from "redux-saga/effects";
import VotesCompareresTypes from "./votes.compareres.types";
import UserActionTypes from "../user/user.types";
import { ApiMethodDto, UrlParam } from "../../Api/api-method-dto";
import { callApi, CallApiResponse } from "../../Api/api-call-service";
import { ApiPath } from "../../Api/ApiPath";
import { AddVoteDto } from "../../dto/votes/add-vote.dto";
import { ActionResult } from "../store.types";

function* getComparerePairForComparisonAsync(actionResult: ActionResult<string | undefined>) {
  const token = yield select(state => state.user.token);
  try {
    const options = {
      headers: token ? { Authorization: token, "Access-Control-Allow-Origin": "*" } : undefined,
      method: "GET",
      expectedStatus: token ? [200, 204, 406] : [200, 204],
      params: actionResult.payload && actionResult.payload.length > 0 ? { name: "cuid", value: actionResult.payload } as UrlParam : undefined
    } as ApiMethodDto;

    const path = token ? ApiPath.vote_getComparerePairForAuthorizedComparison : ApiPath.vote_getComparerePairForAnonymousComparison
    const res: CallApiResponse = yield call(callApi, options.method, path, options);

    if (res.details.status === 204) {
      res.data.id = -1;
    }
    if (res.details.status === 406) {
      res.data.id = -2;
    }
    yield put({ type: VotesCompareresTypes.GET_COMPARERE_PAIR_FOR_COMPARISON_SUCCESS, payload: res.data });

  } catch (error) {
    console.error("Something went wrong.", error);
  }
}

function* addVoteAsync(actionResult: ActionResult<{ addVoteDto: AddVoteDto, ucParam: string | undefined }>) {
  const token = yield select(state => state.user.token);

  try {
    const options = {
      body: actionResult.payload.addVoteDto as AddVoteDto,
      headers: token ? { Authorization: token, "Content-Type": "application/json" , "Access-Control-Allow-Origin": "*" } : { "Content-Type": "application/json" },
      method: "POST",
      expectedStatus: 200,
      params: actionResult.payload.ucParam ? { name: "cuid", value: actionResult.payload.ucParam } as UrlParam : undefined
    } as ApiMethodDto;

    if (token) {
      yield call(callApi, options.method, ApiPath.vote_addVote, options);
      yield put({ type: UserActionTypes.GET_POINTS });
      yield put({ type: VotesCompareresTypes.GET_HISTORY_OF_USER_CHOICES_WITH_VOTES_WITH_COMPARERES });
    } else {
      if (actionResult.payload.ucParam) {
        yield call(callApi, options.method, ApiPath.vote_addVoteAnonymous, options);
      }
      yield put({ type: VotesCompareresTypes.INCREMENT_VOTE_COUNTER, payload: 1 });
    }
    yield put({ type: VotesCompareresTypes.GET_COMPARERE_PAIR_FOR_COMPARISON });
  } catch (error) {
    console.error("Something went wrong.", error);
  }
}

function* getHistoryOfUserChoicesWithVotesWithCompareresAsync(actionResult: ActionResult<{ page: number | undefined}>) {
  const token = yield select(state => state.user.token);
  try {
    const options = {
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
      method: "GET",
      expectedStatus: 200,
      params: actionResult.payload !== undefined && actionResult.payload.page !== undefined ? { name: "page", value: actionResult.payload.page } as UrlParam : undefined
    } as ApiMethodDto;

    const res = yield call(callApi, options.method, ApiPath.vote_getHistoryOfUserChoicesWithVotesWithCompareres, options);

    yield put({ type: VotesCompareresTypes.GET_HISTORY_OF_USER_CHOICES_WITH_VOTES_WITH_COMPARERES_SUCCESS, payload: res.data });

  } catch (error) {
    console.error("Something went wrong.", error);
  }
}

function* watchGetHistoryOfUserChoicesWithVotesWithCompareres() {
  yield takeLatest(VotesCompareresTypes.GET_HISTORY_OF_USER_CHOICES_WITH_VOTES_WITH_COMPARERES, getHistoryOfUserChoicesWithVotesWithCompareresAsync)
}

function* watchGetComparerePairForComparison() {
  yield takeLatest(VotesCompareresTypes.GET_COMPARERE_PAIR_FOR_COMPARISON, getComparerePairForComparisonAsync)
}

function* watchAddVote() {
  yield takeLatest(VotesCompareresTypes.ADD_VOTE, addVoteAsync)
}

export default function* votesCompareresSagas() {
  yield all([call(watchGetComparerePairForComparison), call(watchAddVote), call(watchGetHistoryOfUserChoicesWithVotesWithCompareres)]);
}