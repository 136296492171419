import React from 'react';
import PageTitleBar from '../../components/text-components/page-title-bar/page-title-bar';
import Accordion from '../../components/accordion/accordion.component';
import './faq.styles.scss';
import { SupportForm } from '../../forms/support-form/support-form.component';

const FAQ = () => {
  const accordionList = [
    { question: 'What is Comparere?', answer:'The place where community helps designers pick better version of their work.' },
    { question: 'How Comparere works?', answer:'You can add two variants of your design, and optionally context. Then community votes on variant that is in their opinion better. You get feedback on how users voted, and statistics- distribution of votes by sex and age of the users.'}, 
    { question: 'How can i benefit from using comparere?', 
      answer:
      <>
        <span>You can:</span>
        <ul>
          <li>better target your audiences with your design</li>
          <li>find out if your work is good enough</li>
          <li>prove to your customer which design is better</li>
        </ul>
      </>
    },
    { question: 'Can I use Comparere for free?', answer:'Yes' },
    { question: 'Why do we collect data about sex and age?', 
      answer:'We collect data about sex and age to present statistics about graphics to the user. You can find out what audiences liked what graphics better' },
    { question: 'How does the points system work?', answer:'You need 20 points to add your comparison (comparere). You can collect points by voting on other community memebers work. Each "good vote" grants you a point. "Good vote" is when you liked the work picked by majority of community.' },
  ];

  return (
    <div className='faq-page'> 
      <PageTitleBar>FAQ</PageTitleBar>
      <div className='accordion-form-wrapper'>
        <Accordion>
          {accordionList}
        </Accordion>
        <SupportForm/>
      </div>
    </div>
  )
}
export default FAQ;