import React from 'react'
// import './finalize-sign-up-password-page.scss';
import './../sign-up.scss';
import signUpPasswordImg from "../../../assets/signup/signup_password.svg";
import SignUpPasswordForm from "../../../forms/sign-up-forms/sign-up-password-form/sign-up-password-form"

const FinalizeSignUpPasswordPage = () => {
  return (
    <div className='sign-up-page container'>
      <div className='col50 left'>
        <div className="description">
          <h3>Your account has been created</h3>
          <p className="short-info">
            Set your password and get 10 points. <br />
            <span>
              Or click "skip" to do it later.
            </span>
          </p>
        </div>
        <SignUpPasswordForm />
      </div>
      <div className="col50 right">
        <img src={signUpPasswordImg} alt="Sign Up" />
      </div>
    </div>
  )
}

export default FinalizeSignUpPasswordPage;