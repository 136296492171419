import UserActionTypes from "./user.types";
import { SignUpDto } from "../../dto/sign-up-dto";
import { SignInDto } from "../../dto/sign-in-dto";
import { UserDto } from "../../dto/user/user-dto";
import { UserInfoPointsDto } from "../../dto/UserInfoPointsDto";

export const authorize = (user: SignUpDto) => {
  return {
    type: UserActionTypes.AUTHORIZE,
    payload: user
  };
};

export const signUpTemp = (email: string) => {
  return {
    type: UserActionTypes.AUTH_SIGNUPTEMP,
    payload: email
  };
};

export const signIn = (user: SignInDto) => ({
  type: UserActionTypes.SIGN_IN,
  payload: user
});

export const signOut = () => ({
  type: UserActionTypes.SIGN_OUT
});

export const getPoints = () => ({
  type: UserActionTypes.GET_POINTS
});

export const getPointsSuccess = (points: UserInfoPointsDto) => ({
  type: UserActionTypes.GET_POINTS_SUCCESS,
  payload: { points }
});

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS
})

export const signInSuccess = (user: UserDto, token: string) => {
  return {
    type: UserActionTypes.SIGN_IN_SUCCESS,
    payload: { user, token }
  };
};

export const signUpSuccess = (user: SignUpDto) => ({
  type: UserActionTypes.AUTH_SUCCESS,
  payload: user
});

export const signUpFailure = (error: any) => ({
  type: UserActionTypes.AUTH_FAILURE,
  payload: error
});

export const refreshToken = () => ({
  type: UserActionTypes.REFRESH_TOKEN,
})

export const updateRequestTime = (lastRequestTime: Date) => ({
  type: UserActionTypes.UPDATE_REQUEST_TIME,
  payload: lastRequestTime
})

export const resetPasswordSuccess = () => ({
  type: UserActionTypes.RESET_PASSWORD_SUCCESS,
})