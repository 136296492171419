import React from 'react';
import PageTitleBar from '../../components/text-components/page-title-bar/page-title-bar';
import './rules-page.styles.scss'
import { RoutePath } from '../../route-paths';
import { Link } from 'react-router-dom';

export const RulesPage = () => {
  return (
    <div className='cookies-policy-page'>
      <PageTitleBar>Content Policy</PageTitleBar>
      <div className="container">
        <h5>Last update: 1 October, 2019</h5>
        <h3>INTRODUCTION</h3>
        <p>
         Comparere is a platform to share designs in an open environment. It’s main mission is to help people create graphics and designs that will appeal to as much audiences as possible. While participating, it’s crucial to abide some savoir-vivre rules, so that everyone could enjoy Comparere.
        </p>
        <h3>YOUR WORK</h3>
        <p>You are allowed to only add your own work (work created by you). You are not allowed to publish, share someone else's work. When you add your work(designs, graphics, photos) to Comparere You give us- Comparere, the right to share your work on comparere-ui.codeebo.com website. Your work will not be shared after you delete it.</p>
        <h3>UNWELCOME CONTENT</h3>
        <p>Content is prohibited if it:</p>
        <ul>
          <li>Is illegal</li>
          <li>Is involuntary pornography:
            <div className='content-details'>Comparere prohibits the dissemination of images or video depicting any person in a state of nudity or engaged in any act of sexual conduct apparently created or posted without their permission, including depictions that have been faked</div>
          </li>
          <li>Is sexual or suggestive content involving minors:
            <div className='content-details'>
              Comparere prohibits any sexual or suggestive content involving minors or someone who appears to be a minor. This includes child sexual abuse imagery, child pornography, and any other content, including fantasy content (e.g. stories, “loli”/anime cartoons), that depicts encourages or promotes pedophilia, child sexual exploitation, or otherwise sexualizes minors or someone who appears to be a minor. Depending on the context, this can in some cases include depictions of minors that are fully clothed and not engaged in overtly sexual acts.
              If you are unsure about a piece of content involving a minor or someone who appears to be a minor, do not post it.
            </div>
          </li>
          <li>It's context harrases/threatens someone</li>
          <li>Promotes violence</li>
          <li>Depicts self-mutilation or/and encourages to it.</li>
          <li>Threatens, harasses, or bullies or encourages others to do so</li>
          <li>Is personal and confidential information</li>
          <li>Is spam</li>
        </ul>
        <h3>Intellectual property rights</h3>
        <ul>
          <li>Don't claim others work as your own.</li>
          <li>Don't claim complete/total rights to the work you have been only part of (you created only some part of this work).</li>
          <li>Don't use trademarks that do not belong to you. Only use trademarks if you have right to use them.</li>
          <li>If you want to report the use of improper or unlawful use of your: work, trademark, you can use <Link to={RoutePath.CONTACT}>comparere-ui.codeebo.com/contact</Link> form.</li>
        </ul>
        <h3>Prohibited behavior</h3>
        <p>Following behaviors are prohibited on Comparere:</p>
        <ul>
          <li>Creating multiple accounts to evade punishment or avoid restrictions</li>
          <li>Doing anything that interferes with normal use of Comparere</li>
        </ul>
        <h3>Enforcement</h3>
        <p>
          Not complying with our rules can result in:
        </p>
        <ul>
          <li>Temporary or permanent suspension of account</li>
          <li>Removal of privileges from, or adding restrictions to, accounts</li>
          <li>Removal of content</li>
          <li>Banning of Comparere</li>
        </ul>
      </div>
    </div>
  )
}