import VotesCompareresTypes from "./votes.compareres.types";
import { AddVoteDto } from "../../dto/votes/add-vote.dto";
import { ComparereWithVotesAndUserChoiceDto } from '../../dto/comparere-with-votes-and-user-choice/comparete-with-votes-and-user-choice.ts.dto'

export const getComparerePairForComparison = (ucParam?: string) => ({
  type: VotesCompareresTypes.GET_COMPARERE_PAIR_FOR_COMPARISON,
  payload: ucParam
})

export const addVote = (addVoteDto: AddVoteDto, ucParam?: string) => ({
  type: VotesCompareresTypes.ADD_VOTE,
  payload: {addVoteDto, ucParam}
})

export const getHistoryOfUserChoicesWithVotesWithCompareres = (page: number) => ({
  type: VotesCompareresTypes.GET_HISTORY_OF_USER_CHOICES_WITH_VOTES_WITH_COMPARERES,
  payload: {page}
})

export const getHistoryOfUserChoicesWithVotesWithCompareresSuccess = (compareresWithVotesAndUserChoice: Array<ComparereWithVotesAndUserChoiceDto>) => ({
  type: VotesCompareresTypes.GET_HISTORY_OF_USER_CHOICES_WITH_VOTES_WITH_COMPARERES_SUCCESS,
  payload: compareresWithVotesAndUserChoice
})

export const resetVoteCounter = () => ({
  type: VotesCompareresTypes.RESET_VOTE_COUNTER
})