import React, { useEffect } from 'react';

interface Props {
  loadData: (page: number) => any;
  itemsPerPage: number;
  currentItemsNumber: number
}

let inifnityScroll_currentItemsNumber: number = 0;
let infinityScroll_prevPage: number = 0;
let infinityScroll_requestAllowed: boolean = true;
let infinitySroll_itemsPerPage: number = 12;
let infinityScroll_loadData: (page: number) => void;

const InfiniteScroll: React.FC<Props> = (props) => {
  inifnityScroll_currentItemsNumber = props.itemsPerPage;

  useEffect(() => {
    inifnityScroll_currentItemsNumber = props.currentItemsNumber;
  }, [props])

  /* eslint-disable */
  useEffect(() => {
    inifnityScroll_currentItemsNumber = 0;
    infinityScroll_prevPage = 0;
    inifnityScroll_currentItemsNumber = props.currentItemsNumber;
    infinitySroll_itemsPerPage = props.itemsPerPage;
    infinityScroll_loadData = props.loadData
    handleScroll();
    return () => {
      inifnityScroll_currentItemsNumber = 0;
      infinityScroll_prevPage = 0;
      inifnityScroll_currentItemsNumber = 0;
      infinitySroll_itemsPerPage = 12
      infinityScroll_loadData = () => { };
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])
  /* eslint-enable */

  const handleScroll = () => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset + window.innerHeight >= document.body.scrollHeight) {
        const page = Math.round(inifnityScroll_currentItemsNumber / infinitySroll_itemsPerPage)
        if (page > infinityScroll_prevPage && infinityScroll_requestAllowed === true) {
          infinityScroll_requestAllowed = false;
          setTimeout(() => {
            infinityScroll_requestAllowed = true;
          }, 1000);
          infinityScroll_prevPage = page
          infinityScroll_loadData(page);
        }
      }
    });
  }

  return (
    <>
      {props.children}
    </>
  )
}

export default InfiniteScroll