import React, { Component } from "react";
import { connect } from 'react-redux'
import { changePassword } from "../../redux/account-settings/account-settings.actions";
import { ChangePasswordDto } from "../../dto/account-settings/change-password-dto";
import Tile from "../../components/tile/tile.component";
import { ToastsStore } from 'react-toasts';
import { Popup } from "../../components/popup/popup.component";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { UserDto } from "../../dto/user/user-dto";
import { UserRoleEnum } from "../../dto/user/user-role-enum";
import FormInputOval from "../input-forms/form-input-oval/form-input-oval.component";
import BtnCustomShadow from "../../components/btn-custom-shadow/btn-custom-shadow";


interface State {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  isOpen: boolean;
}

interface Props {
  changePassword: any;
  user: UserDto;
}

class ChangePasswordForm extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      isOpen: false
    };
  }

  openPopup = (e: any) => {
    e.preventDefault()
    const { newPassword, confirmNewPassword } = this.state;

    if (newPassword !== confirmNewPassword) {
      ToastsStore.warning('New password and Confirm password must be the same.');
      return;
    }
    this.setState({ isOpen: true })
  };

  handleConfirm = () => {
    this.setState({ isOpen: false })
    this.handleSubmit();
  }

  handleCancel = () => {
    this.setState({ isOpen: false })
  }

  handleSubmit = async () => {
    const { oldPassword, newPassword } = this.state;

    const changePasswordDto: ChangePasswordDto = { oldPassword: oldPassword, newPassword: newPassword }
    this.props.changePassword(changePasswordDto);
  };

  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState({ [name]: value } as State);
  };

  render() {
    const { oldPassword, newPassword, confirmNewPassword, isOpen } = this.state;
    const { user } = this.props;
    const isTempUser = user && user.roles.indexOf(UserRoleEnum.TempUser) > -1;
    if (isTempUser) return null;
    return (
      <>
        <Popup isOpen={isOpen} handleConfirm={this.handleConfirm} handleCancel={this.handleCancel}>
          <div className="popup-title">It will change your password!</div>
          <div className="popup-body">Are you sure?</div>
        </Popup>
        <Tile>
          <h2 className="title">{isTempUser ? 'Set password' : 'Change password'}</h2>
          <form onSubmit={this.openPopup}>
            <FormInputOval
              name="oldPassword"
              type="password"
              label="Old Password"
              value={oldPassword}
              onChange={this.handleChange}
              required
            />
            <FormInputOval
              name="newPassword"
              type="password"
              label="New password"
              value={newPassword}
              onChange={this.handleChange}
              required
            />
            <FormInputOval
              name="confirmNewPassword"
              type="password"
              label="Confirm new password"
              value={confirmNewPassword}
              onChange={this.handleChange}
              required
            />
            <BtnCustomShadow type="submit">Change</BtnCustomShadow>
          </form>
        </Tile>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  changePassword: (changePasswordDto: ChangePasswordDto) => dispatch(changePassword(changePasswordDto))
})

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
