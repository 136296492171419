import './bg-img-loader.scss';
import React, { Component } from "react";
interface Props {
  src: string;
  minLoadingTime?: number;
  attributes: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
}

interface State {
  showPreloader: boolean;
  src: string;
}

class BgImgLoader extends Component<Props, State> {
  fakeImg = new Image();
  timeouts = [] as Array<any>;

  constructor(props: any) {
    super(props);

    this.state = {
      showPreloader: true,
      src: '',
    };
  }

  componentDidMount() {
    this.fakeImg.onload = null;
    this.fakeImg.src = '';
    this.setState({ showPreloader: true, src: '' })
    this.timeouts.push(setTimeout(() => {
      this.fakeImg.onload = this.handleImageLoaded;
      this.fakeImg.src = this.props.src;
    }, this.props.minLoadingTime ? this.props.minLoadingTime : 300));
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.src !== nextProps.src) {
      this.timeouts.push(setTimeout(() => {
        this.fakeImg.onload = null;
        this.fakeImg.src = '';
        this.setState({ showPreloader: true, src: '' })
        this.timeouts.push(setTimeout(() => {
          this.fakeImg.onload = this.handleImageLoaded;
          this.fakeImg.src = nextProps.src;
        }, this.props.minLoadingTime ? this.props.minLoadingTime : 300));
      }, 100));
    }
  }

  componentWillUnmount() {
    this.setState({ showPreloader: false, src: '' })
    for (let i = 0; i < this.timeouts.length; i++) {
      window.clearTimeout(this.timeouts[i]);
    }
    this.fakeImg.onload = null;
    this.fakeImg.src = '';
  }

  handleImageLoaded = () => {
    this.timeouts.push(setTimeout(() => {
      this.setState({ src: this.fakeImg.src })
      this.setState({ showPreloader: false })
    }, this.props.minLoadingTime ? this.props.minLoadingTime : 300));
  }

  render() {
    const { attributes, children } = this.props;
    const { showPreloader } = this.state;
    return (
      <div {...attributes}
        className={showPreloader ? (attributes.className ? (attributes.className + " bg-img-loader preloaderActive") : "bg-img-loader preloaderActive") :
          (attributes.className ? (attributes.className + " bg-img-loader") : "bg-img-loader")}
        style={{ backgroundImage: 'url("' + this.fakeImg.src + '")' }}>
        {children ? children : null}
      </div>
    )
  }
}

export default BgImgLoader;