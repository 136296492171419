import Ig from "../assets/social-media/ig-10.svg"
import Behance from '../assets/social-media/behance-10.svg';
import Dribble from '../assets/social-media/dribble-10.svg';
import World from "../assets/social-media/world-10.svg";
import FB from "../assets/social-media/fb-10.svg";
import Linkedin from "../assets/social-media/ln-10.svg";
import Mail from "../assets/social-media/email-10.svg";
import Pinterest from "../assets/social-media/pinterest.svg";
import Twitter from "../assets/social-media/twitter.svg";

const regexpString = (word: string) => new RegExp(word)

export const mapLinkToIcon = (socialMediaLink: string) => {
  if(regexpString('instagram').test(socialMediaLink)){
    return {icon: Ig, name: 'Instagram'};
  } else if(regexpString('behance').test(socialMediaLink)){
    return {icon: Behance, name: 'Behance'};
  } else if(regexpString('dribbble').test(socialMediaLink)){  
    return {icon: Dribble, name: 'Dribbble'}
  } else if(regexpString('facebook'|| 'fb').test(socialMediaLink)){
    return {icon: FB, name: 'Facebook'}
  } else if(regexpString('linkedin').test(socialMediaLink)){
    return {icon: Linkedin, name: 'Linkedin'}
  } else if(regexpString('@').test(socialMediaLink)){
    return {icon: Mail, name: 'Email'}
  } else if(regexpString('pinterest').test(socialMediaLink)){
    return {icon: Pinterest, name: 'Pinterest'}
  } else if(regexpString('twitter').test(socialMediaLink)){
    return {icon: Twitter, name: 'twitter'}
  }else
  return {icon: World, name: 'Website'}
};