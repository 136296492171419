import { ApiRootPath } from "./ApiRootPath";

const rootPath: string = ApiRootPath.rootPath;
export class ApiPath {
  //** AUTH **//
  static auth_signUp: string = rootPath + 'auth/signUp';
  static auth_signUpTemp: string = rootPath + 'auth/signUpTemp';
  static auth_signIn: string = rootPath + 'auth/signIn';
  static auth_signOut: string = rootPath + 'auth/signOut';
  static auth_refreshToken: string = rootPath + 'auth/refreshToken';
  static auth_getUserInfoGetPoints: string = rootPath + 'auth/getUserInfoGetPoints';

  //** ACCOUNT SETTINGS **//
  static accountSettings_changePassword: string = rootPath + 'accountSettings/changePassword';
  static accountSettings_deleteAccount: string = rootPath + 'accountSettings/deleteAccount';
  static accountSettings_changeNotifications: string = rootPath + 'accountSettings/changeNotifications';
  static accountSettings_getNotificationsSettings: string = rootPath + 'accountSettings/getNotificationsSettings';
  static accountSettings_getUserDetails: string = rootPath + 'accountSettings/getUserDetails';
  static accountSettings_changeUserDetails: string = rootPath + 'accountSettings/changeUserDetails';
  static accountSettings_finalizeSignUp: string = rootPath + 'accountSettings/finalizeSignUp';
  static accountSettings_addSocialMediaLink: string = rootPath + 'accountSettings/addSocialMediaLink';
  static accountSettings_getUserProfile: string = rootPath + 'accountSettings/getUserProfile';
  static accountSettings_deleteSocialMediaLink: string = rootPath + 'accountSettings/deleteSocialMediaLink';
  static accountSettings_updateUserLogin: string = rootPath + 'accountSettings/updateUserLogin';
  static send_contact_email: string = rootPath + 'accountSettings/sendContactEmail';
  static reset_password: string = rootPath + 'accountSettings/resetPassword';

  //** USER COMPARERES **/
  static userCompareres_getUserCompareres: string = rootPath + 'userCompareres/getUserCompareres';
  static userCompareres_getComparereDetails: string = rootPath + 'userCompareres/getComparereDetails';
  static userCompareres_deleteUserComparere: string = rootPath + 'userCompareres/deleteUserComparere';
  static userCompareres_getUserComparereById: string = rootPath + 'userCompareres/getUserComparereById';
  static userCompareres_updateComparereContext: string = rootPath + 'userCompareres/updateComparereContext';
  static userCompareres_updateComparerePrivacy: string = rootPath + 'userCompareres/updateComparerePrivacy';

  //** UPLOAD COMPARERES**//
  static uploadCompareres_startNewComparere: string = rootPath + 'uploadCompareres/startNewComparere';

  //** VOTE **//
  static vote_addVote: string = rootPath + 'vote/addVote';
  static vote_addVoteAnonymous: string = rootPath + 'vote/addVoteAnonymous';
  static vote_getComparerePairForAuthorizedComparison: string = rootPath + 'vote/getComparerePairForAuthorizedComparison';
  static vote_getComparerePairForAnonymousComparison: string = rootPath + 'vote/getComparerePairForAnonymousComparison';
  static vote_getHistoryOfUserChoicesWithVotesWithCompareres: string = rootPath + 'vote/getHistoryOfUserChoicesWithVotesWithCompareres';

  //** REPORT **//
  static report_addNewReport: string = rootPath + 'report/addNewReport';

  //** POINTS **//
  static points_realizePromotionCode: string = rootPath + 'points/realizePromotionCode';

  //** PAYMENT **//
  static payments_createCheckoutSession: string = rootPath + 'payment/createCheckoutSession';

  //** LAUNCH **//
  static survey_send: string = rootPath + 'launch/sendSurvey';
  static launch_addToNewsletter: string = rootPath + 'launch/addToNewsletter';

  //** HOME PAGE **/
  static homePage_getDesigner: string = rootPath + 'designerOfDay/getNewest';
}