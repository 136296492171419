import React, { useEffect } from 'react';
import './profile-informations-form.scss';
import Tile from '../../components/tile/tile.component';
import { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import FormInputOvalBtn from '../input-forms/form-input-oval-btn/form-input-oval-btn';
import RoundBtn from '../../components/round-btn/round-btn.component';
import Plus from '../../assets/btns/plus-small.svg';
import { addSocialMediaLink, getUserProfile, deleteSocialMediaLink, updateUserLogin } from '../../redux/account-settings/account-settings.actions';
import { selectAccountSettings } from '../../redux/account-settings/account-settings.selectors';
import { UserProfileDto } from '../../dto/account-settings/userProfile/user-profile-dto';
import SocialLink from './social-link/social-link.component';
import { SocialMediaLinkDto } from '../../dto/account-settings/userProfile/social-media-link-dto';
import SaveIcon from '../../assets/document-icons/save-icon.svg';
import EditIcon from '../../assets/document-icons/edit-icon.svg';

export const ProfileInformationsForm: React.FC = () => {

  const details = useSelector(selectAccountSettings, shallowEqual) as UserProfileDto
  const [linkError, setLinkError] = useState('');
  const [loginError, setLoginError] = useState('');
  const [firstRun, setfirstRun] = useState(true);
  const [link, setLink] = useState('');
  const [edit, setEdit] = useState(true);
  const [userProfile, setUserProfile] = useState({} as UserProfileDto)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile())
  }, [])

  useEffect(() => {
    setUserProfile(details)
    if (firstRun) setfirstRun(false);
  }, [details])

  useEffect(() => {
    if (!firstRun) {
      handleSetLinkError();
    }
  }, [link]);

  useEffect(() => {
    if (!firstRun && userProfile.login) {
      handleSetLoginError();
    }
  }, [userProfile.login]);

  const handleChangeLogin = (e: any) => {
    e.preventDefault();
    const { name, value } = e.target
    setUserProfile({ ...userProfile, [name]: value })
  }

  const handleChangeLink = (e: any) => {
    e.preventDefault();
    const { value } = e.target
    setLink(value);
  }

  const handleSetLinkError = () => {
    if (link.length < 5) {
      setLinkError('Link must be longer than 5 characters');
    } else {
      setLinkError('');
    }
  }

  const handleSetLoginError = () => {
    if (userProfile.login.length < 5) {
      setLoginError('Login must be longer than 5 characters');
    } else {
      setLoginError('');
    }
  }

  const handleLinkSubmit = () => {
    if (link.length >= 5) {
      dispatch(addSocialMediaLink(link))
      setLink('');
      setLinkError('');
      setfirstRun(true);
    }
  }

  const handleLinkDelete = (linkId: number) => {
    dispatch(deleteSocialMediaLink(linkId))
  }

  const handleLoginSubmit = () => {
    dispatch(updateUserLogin(userProfile.login))
    setEdit(!edit);
  }

  const renderLoginError = () => {
    if (loginError.length > 0) {
      return <div className='error'>{loginError}</div>
    } 
    return <div className='error' />
  }

  const renderLinkError = () => {
    if (linkError.length > 0) {
      return <div className='error'>{linkError}</div>
    } 
    return <div className='error' />
  }

  const renderRoundBtn = () => {
    if(edit){
      return <RoundBtn icon={EditIcon} onClick={() => setEdit(!edit)} />
    }
    return <RoundBtn disabled={userProfile.login ? userProfile.login.length < 5 : true} icon={SaveIcon} onClick={handleLoginSubmit} />
  }

  return (
    <Tile>
      <h2 className='title'>Profile informations</h2>
      <form className='profile-info-form'>
      <div className='btn-and-error-wrapper'>
        {renderLoginError()}
        <FormInputOvalBtn
          name="login"
          type="name"
          label="login"
          value={userProfile.login ? userProfile.login : ''}
          onChange={handleChangeLogin}
          disabled={edit}
          required
        >
        {renderRoundBtn()}
        </FormInputOvalBtn>
        </div>
        <div className='social-media-header'>Social media</div>
        {details.socialMediaLinks && details.socialMediaLinks.length > 0 ?
          details.socialMediaLinks.map((item: SocialMediaLinkDto) => {
            return (
              <SocialLink
                key={item.id}
                socialMediaLink={item}
                onClick={handleLinkDelete}
              />
            )
          })
          : <div className={"emptyList"}>You don't have any portfolio link, please add something.</div>
        }
        <div className='btn-and-error-wrapper'>
        {renderLinkError()}
          <FormInputOvalBtn
            name="link"
            type="name"
            label="link"
            value={link}
            onChange={handleChangeLink}
            required
            >
            <RoundBtn disabled={link.length < 5} icon={Plus} onClick={handleLinkSubmit} />
          </FormInputOvalBtn>
        </div>
        <div className='notification'>This profile information will be used on the main page if You will be chosen as a “Designer of the day”</div>
      </form>
    </Tile>
  );
}