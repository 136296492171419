import React, { useState, FC } from 'react';
import './support-form.styles.scss';
import Tile from '../../components/tile/tile.component';
import { ContactEmailDto } from '../../dto/contact-email-dto';
import { useDispatch } from 'react-redux';
import { sendContactEmail } from '../../redux/account-settings/account-settings.actions';
import FormInputOval from '../input-forms/form-input-oval/form-input-oval.component';
import BtnCustomShadow from '../../components/btn-custom-shadow/btn-custom-shadow';

const initialState = { name: '', email: '', message: '' }

export const SupportForm: FC = () => {

  const [values, setValues] = useState(initialState);
  const dispatch = useDispatch();

  const clearState = () => {
    setValues({ ...initialState });
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { name, email, message } = values;

    const contactEmailDto: ContactEmailDto = { name: name, email: email, message: message} as ContactEmailDto;
    dispatch(sendContactEmail(contactEmailDto))
    clearState();
  };

  return (
    <>
      <Tile>
        <h2 className="title">Write to us</h2>
        <form onSubmit={(e) => handleSubmit(e)}>
          <FormInputOval
            name="name"
            type="name"
            label="Name"
            value={values.name}
            onChange={handleChange}
            required
          />
          <FormInputOval
            name="email"
            type="email"
            label="Email"
            value={values.email}
            onChange={handleChange}
            required
          />
          <FormInputOval
            name="message"
            type="textarea"
            label="Your message"
            value={values.message}
            onChange={handleChange}
            required
          />
          <BtnCustomShadow type="submit">Send</BtnCustomShadow>
        </form>
      </Tile>
    </>
  )
}