import { store } from "../../redux/store";
import { callApi } from "../api-call-service";
import { ApiPath } from "../ApiPath";
import { ApiMethodDto } from "../api-method-dto";
import { NewsletterSettingsDto } from "../../dto/account-settings/newsletter-settings-dto";
import { UserDetailsDto } from "../../dto/account-settings/user-details-dto";

export class AccountSettingsController {
  static async GetNotificationsSettings(): Promise<NewsletterSettingsDto> {
    return new Promise(async (resolve) => {
      const token = store.getState().user.token;
      const options = {
        datatype: "Json",
        headers: { Authorization: token, "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
        method: "GET",
        expectedStatus: 200
      } as ApiMethodDto;

      await callApi(options.method, ApiPath.accountSettings_getNotificationsSettings, options)
        .then((res) => {
          if (res.details.status === options.expectedStatus) {
            resolve(res.data);
          }
        })
    });
  }
  static async GetUserDetails(): Promise<UserDetailsDto> {
    return new Promise(async (resolve) => {
      const token = store.getState().user.token
      const options = {
        datatype: "Json",
        headers: { Authorization: token, "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
        method: "GET",
        expectedStatus: 200
      } as ApiMethodDto;

      await callApi(options.method, ApiPath.accountSettings_getUserDetails, options)
        .then((res) => {
          if (res.details.status === options.expectedStatus) {
            resolve(res.data);
          }
        })
    });
  }
}