import React from 'react';
import './points-page.styles.scss';
import RealizePromotionCodeForm from '../../forms/realize-promotion-code-form/realize-promotion-code-form.component';
import PageTitleBar from '../../components/text-components/page-title-bar/page-title-bar';

const PointsPage: React.FC = () => {
  return (
    <div className='account-settings container'>
      {/* <PageTitleBar>SUPPORT OUR TEAM</PageTitleBar>
      <p className="underheader">The best way to gain points is VOTING. It is free. Section with extra points is only to support our team.</p>
      <div className='tiles-wrapper'>
        <BuyPointsTile packageType={PremiumTypeEnum.standard} />
        <BuyPointsTile packageType={PremiumTypeEnum.pro} />
        <BuyPointsTile packageType={PremiumTypeEnum.proPlus} />
      </div> */}
      <PageTitleBar>Promotion Code</PageTitleBar>
      <div className='tiles-wrapper'>
        <RealizePromotionCodeForm />
      </div>
    </div>
  );
}

export default PointsPage;