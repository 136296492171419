import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import userReducer from './user/user.reducer';
import storage from 'redux-persist/lib/storage';
import userCompareresReducer from './user-compareres/user-compareres.reducer';
import votesCompareresReducer from './votes-compareres/votes-compareres.reducer';
import welcomePopupReducer from './welcome-popup/welcome-popup.reducer';
import accountSettingsReducer from './account-settings/account-settings.reducer';
import homePageReducer from './home-page/home-page.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'welcomePopup']
}

const rootReducer = combineReducers({
  welcomePopup: welcomePopupReducer,
  user: userReducer,
  userCompareresStore: userCompareresReducer,
  votesCompareresStore: votesCompareresReducer,
  accountSettingsStore: accountSettingsReducer,
  homePageStore: homePageReducer
})

export default persistReducer(persistConfig, rootReducer);