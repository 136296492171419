import React from 'react'
import ResetPasswordForm from '../../forms/reset-password-form/reset-password-form.component';

const ResetPasswordPage = () => {
  return (
    <div className='sign-in-page container'>
      <div className='tiles-wrapper'>
        <ResetPasswordForm/>
      </div>
    </div>
  )
}

export default ResetPasswordPage;