import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getHistoryOfUserChoicesWithVotesWithCompareres } from '../redux/votes-compareres/votes-compareres.actions';
import { createStructuredSelector } from 'reselect';
import { ComparerereStore } from '../redux/store.types';
import { selectHistoryOfUserChoicesWithVotesWithCompareres } from '../redux/votes-compareres/votes-compareres.selectors';
import { ComparereWithVotesAndUserChoiceDto } from '../dto/comparere-with-votes-and-user-choice/comparete-with-votes-and-user-choice.ts.dto';

import './compareres-with-votes-and-user-choice.styles.scss';
import PageTitleBar from '../components/text-components/page-title-bar/page-title-bar';
import InfiniteScroll from '../components/infinite-scroll/infinite-scroll.component';
import ComparereWithVotesAndUserChoice from '../components/comparere-with-votes-and-user-choice/comparere-with-votes-and-user-choice.component';

interface Props {
  getHistoryOfUserChoicesWithVotesWithCompareres: any;
}

interface DerivedProps {
  historyOfUserChoicesWithVotesWithCompareres: Array<ComparereWithVotesAndUserChoiceDto>;
}

interface State {
  choicesHistory: Array<ComparereWithVotesAndUserChoiceDto>;
}

class ComparereWithVotesAndUserChoiceContainer extends Component<Props & DerivedProps, State> {

  constructor(props: any) {
    super(props);

    this.state = {
      choicesHistory: []
    }
  }

  componentDidMount() {
    const { getHistoryOfUserChoicesWithVotesWithCompareres } = this.props;
    getHistoryOfUserChoicesWithVotesWithCompareres(0);
  }

  static getDerivedStateFromProps(nextProps: DerivedProps, prevState: State) {
    if (Object.keys(nextProps.historyOfUserChoicesWithVotesWithCompareres).length !== 0 && nextProps.historyOfUserChoicesWithVotesWithCompareres !== prevState.choicesHistory) {
      const ids = new Set(prevState.choicesHistory.map(item => item.id));
      const newItems = nextProps.historyOfUserChoicesWithVotesWithCompareres.filter(item => !ids.has(item.id)).reverse();
      const merged = newItems && newItems.length === 1 ? [...newItems, ...prevState.choicesHistory] : newItems ? [...prevState.choicesHistory, ...newItems] : [...prevState.choicesHistory];
      return {
        choicesHistory: merged
      }
    }
    else return null;
  }

  render() {
    const { choicesHistory } = this.state;
    return (
      <div>
        {choicesHistory && choicesHistory.length !== undefined ? (
          <InfiniteScroll loadData={this.props.getHistoryOfUserChoicesWithVotesWithCompareres} itemsPerPage={12} currentItemsNumber={choicesHistory.length}>
            <PageTitleBar>YOUR LAST VOTES</PageTitleBar>
            <div className='compareres-with-votes-and-user-choice-wrapper'>
              {choicesHistory.map((item: ComparereWithVotesAndUserChoiceDto) => {
                return (
                  Object.keys(item).length !== 0 ? <ComparereWithVotesAndUserChoice key={item.id} userAndCommunityChoice={item} /> : null
                )
              })}
              {choicesHistory.length === 0 ? <div className="any-votes">You haven't voted yet. Click on better graphics and start fun!</div> : null}
            </div>
          </InfiniteScroll>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = createStructuredSelector<ComparerereStore, DerivedProps>({
  historyOfUserChoicesWithVotesWithCompareres: selectHistoryOfUserChoicesWithVotesWithCompareres
});

const mapDispatchToProps = (dispatch: any) => ({
  getHistoryOfUserChoicesWithVotesWithCompareres: (page: number) => dispatch(getHistoryOfUserChoicesWithVotesWithCompareres(page))
})

export default connect(mapStateToProps, mapDispatchToProps)(ComparereWithVotesAndUserChoiceContainer);