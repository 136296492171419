import React from 'react';
import './popup-overlay-independent.scss';

interface Props {
  isActive?: boolean;
}

const PopupOverlayIndependent = (props: Props) => {
  return (
    <div className={props.isActive ? "popup-overlay-independent active" : "popup-overlay-independent"} />
  );
}

export default PopupOverlayIndependent;