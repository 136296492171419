import React, { useEffect } from "react";
import './designer-section.styles.scss';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getDesignerOfTheDay } from "../../redux/home-page/home-page.actions";
import { selectDesignerOfDay } from "../../redux/home-page/home-page.selectors";
import { DesignerOfDayDto } from "../../dto/designer/designer-of-day";
import { SocialMediaLinkDto } from "../../dto/account-settings/userProfile/social-media-link-dto";
import { mapLinkToIcon } from "../../Tools/MapLinkToIcon";
import DesignerOfDayImg from '../../assets/landingPage/designer_of_day.svg';
import DesignerOfDayHeader from '../../assets/landingPage/designer_of_day_header.svg';

const DesignerSection: React.FC = () => {

  const dispatch = useDispatch();
  const details = useSelector(selectDesignerOfDay, shallowEqual) as DesignerOfDayDto

  useEffect(() => {
    dispatch(getDesignerOfTheDay())
  }, [])

  return (
    <>
      {details && details.login && details.links ?
        <div className="designer-section">
          <div className='header-and-details-wrapper'>
            <img className='designer-of-day-header' src={DesignerOfDayHeader} alt="" />
            <div className='login'>{details.login}</div>
            <div className='media-icons-wrapper'>
              {details.links ? details.links.map((item: SocialMediaLinkDto) => {
                return (
                  <a key={item.id} href={item.link}>
                    <img className="media-icon" src={mapLinkToIcon(item.link).icon} alt="media-icon" />
                  </a>
                )
              }) : null}
            </div>
          </div>
          <img className='designer-of-day' src={DesignerOfDayImg} alt="designer-of-day" />
        </div> : null}
    </>
  );
};

export default DesignerSection;
