import React from 'react';
import './account-settings.styles.scss';
import ChangePasswordForm from '../../forms/change-password-form/change-password-form.component';
import DeleteAccountForm from '../../forms/delete-account-form/delete-account-form.component';
import ChangeNotificationsForm from '../../forms/change-notifications-form/change-notifications-form';
import ChangeUserDetailsForm from '../../forms/change-userDetails-form/change-userDetails-form';
import FinalizeSignUpForm from '../../forms/sign-up-forms/finalize-signup-form/finalize-signup-form';


import PageTitleBar from '../../components/text-components/page-title-bar/page-title-bar';
import { useSelector, shallowEqual } from 'react-redux';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { UserDto } from '../../dto/user/user-dto';
import { ProfileInformationsForm } from '../../forms/profile-informations/profile-informations-form';

const AccountSettings = () => {
  const user = useSelector(selectCurrentUser, shallowEqual) as UserDto;

  return (
    <div className='account-settings container'>
      <PageTitleBar>Account Settings</PageTitleBar>
      {user && user.email ? <div className="account-name" title={user.email.replace("@comparere-ui.codeebo.com", "")}>{user.email.replace("@comparere-ui.codeebo.com", "")}</div> : null}
      <div className="importantSection">
      </div>
      <FinalizeSignUpForm />
      <div className='tiles-wrapper'>
        <div className="col col-left">
          <ProfileInformationsForm />
          <ChangeUserDetailsForm />
        </div>
        <div className="col">
          <ChangePasswordForm />
          <ChangeNotificationsForm />
          <DeleteAccountForm />
        </div>
      </div>
    </div>
  )
}

export default AccountSettings;