import React from "react";
import BtnCustom from "../btn-custom/btn-custom.component";
import "./not-found.styles.scss";
import { RoutePath } from "../../route-paths";
import { Link } from "react-router-dom";

const NotFound = () => (
  <>
    <div className="not-found-wrapper">
      <div className="not-found-404">404</div>
      <div className="details">
        <h1>Sorry</h1>
        <h3>The page you were looking for doesn't exist.</h3>
        <Link to={RoutePath.HOME_PAGE}>
          <BtnCustom>Go to homepage</BtnCustom>
        </Link>
      </div>
    </div>
  </>
);
export default NotFound;
