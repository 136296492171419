import React from 'react';
import "./collapsible-menu.styles.scss";
import OutsideWrapperDetector from '../outside-wrapper-detector/outside-wrapper-detector.component';

interface Props {
  isOpen: boolean;
  handleClose: any;
  positionOver?: boolean;
}

interface State {
  isOpen: boolean;
}

export class CollapsibleMenu extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      isOpen: false
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.state.isOpen !== nextProps.isOpen) {
      this.setState({ isOpen: nextProps.isOpen })
    }
  }

  handleClose = () => {
    this.setState({ isOpen: false })
    this.props.handleClose();
  }

  render() {
    const { positionOver, children } = this.props;
    return (
      this.state.isOpen ?
        <OutsideWrapperDetector handleClose={this.handleClose} closeOnClickAny={true}>
          <div className="collapsible-menu-wrapper">
            <div className="collapsible-menu">
              <div className={positionOver ? "position-over" : ""}>
                {children}
              </div>
            </div>
          </div>
        </OutsideWrapperDetector> : null
    );
  }
}

export default CollapsibleMenu;