import "./drop-zone.scss";
import React, { Component, createRef } from "react";
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { ToastsStore } from "react-toasts";

interface Props {
  onFileAdded: any;
  disabled: boolean;
  id: number;
  clear: boolean;
}

interface State {
  hightlight: boolean;
  imagePreviewUrl: any;
  file?: File;
}

class Dropzone extends Component<Props, State> {
  fileInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: any) {
    super(props);
    this.fileInputRef = createRef<HTMLInputElement>();

    this.state = {
      hightlight: false,
      imagePreviewUrl: "",
      file: undefined
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.clear) {
      this.setState({
        file: undefined,
        imagePreviewUrl: ""
      });
    }
  }

  onDragOver = (e: any) => {
    e.preventDefault();
    if (this.props.disabled) return;
    this.setState({ hightlight: true });
  };

  onDragLeave = (e: any) => {
    e.preventDefault();
    this.setState({ hightlight: false });
  };

  handleImageChange = (file: File) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
  };

  onDrop = (e: any) => {
    e.preventDefault();
    if (this.props.disabled) return;
    const files = e.dataTransfer.files;

    let FileSizeLimit = files[0].size / 1024 / 1024;
    if (FileSizeLimit > 2) {
      ToastsStore.error('File size cannot exceed 2MB');
        return;
    }
    else if (this.props.onFileAdded && files[0]) {
      this.handleImageChange(files[0]);
      const comparere = { id: this.props.id, file: files[0] };
      this.props.onFileAdded(comparere);
    }
    this.setState({ hightlight: false });
  };

  openFileDialog = () => {
    if (this.props.disabled) return;
    const node = this.fileInputRef.current;
    if (node) {
      node.click();
    }
  };

  handleFileAdd = (e: any) => {
    if (this.props.disabled) return;
    const file = e.target.files[0];
    if (this.props.onFileAdded && file) {
      this.handleImageChange(file);
      const comparere = { id: this.props.id, file: file };
      this.props.onFileAdded(comparere);
    }
  };

  render() {
    let { imagePreviewUrl, hightlight } = this.state;
    return (
      <div className="dropzone-wrapper" style={{ backgroundImage: imagePreviewUrl ? ("url(" + imagePreviewUrl + ")") : "" }}>
        <div
          className={`dropzone ${hightlight ? "highlight" : ""}`}
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}
          onClick={this.openFileDialog}
          style={{ cursor: this.props.disabled ? "default" : "pointer" }}
        >
          {imagePreviewUrl ? null : <Plus />}
          <input
            ref={this.fileInputRef}
            className="fileInput"
            type="file"
            multiple={false}
            onChange={this.handleFileAdd}
          />
        </div>
      </div>
    );
  }
}

export default Dropzone;
