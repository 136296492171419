import './home-page.styles.scss';
import React from "react";

import { RouteComponentProps, Link } from "react-router-dom";
import { RoutePath } from "../../route-paths";

import Tabs from '../../components/tabs/tabs.component';
import FeedbackGraph from '../../assets/landingPage/tabs/feedback_graph.svg';
import DragNDrop from '../../assets/landingPage/tabs/drag_n_drop.svg';
import ShareComparere from '../../assets/landingPage/tabs/share_comparere.svg';
import CommunityChoice from '../../assets/landingPage/tabs/community_choice.svg';
import BuildProfile from '../../assets/landingPage/tabs/build_profile.svg';
import VoteForComparere from "../../components/vote-comparere/vote-comparere.component";

import Compare from '../../assets/landingPage/tabs/compare.svg';
import { uploadCompareres } from './home-page-tabs';
import { voteCompareres } from './home-page-tabs';
import DesignerSection from '../../components/designer-section/designer-section.component';
import BtnCustomShadow from '../../components/btn-custom-shadow/btn-custom-shadow';

const HomePage: React.FC<any> = (props: any & RouteComponentProps) => {

  return (
    <>
      {props.match && props.match.params && props.match.params.uc ?
        <div className='vote-component-wrapper'>
          <VoteForComparere uc={props.match && props.match.params ? props.match.params.uc : undefined} history={props.history} />
        </div>
        : null
      }
      <div className='landing-page-wrapper'>
        <div className={'hiddenPreloader'}>
          <img src={DragNDrop} alt="preloader" />
          <img src={ShareComparere} alt="preloader" />
          <img src={FeedbackGraph} alt="preloader" />
          <img src={Compare} alt="preloader" />
          <img src={CommunityChoice} alt="preloader" />
          <img src={BuildProfile} alt="preloader" />
        </div>
        <div className="container">
          <section>
            <div className='col50'>
              <div className="description">
                <h1>The fast and easy way to collect<br />results of designs comparisons </h1>
                <p>Created design in multiple variants, and wonder which one is better? Comparere community gives you feedback, fast and efficiently.<br /> Understand how to hit the tastes of your audience.</p>
                <Link to={RoutePath.SIGN_UP}>
                  <BtnCustomShadow>Start, it's free</BtnCustomShadow>
                </Link>
              </div>
            </div>
            <div className="col50 tabWrapper">
              <Tabs steps={['Upload', 'Share', 'Analyze']} details={uploadCompareres} />
            </div>
          </section>
          <section>
            <div className="col50 tabWrapper">
              <Tabs steps={['Compare', 'Analyze', 'Build']} details={voteCompareres} />
            </div>
            <div className='col50'>
              <div className="description">
                <h2>Join a growing community of <br /> designers, who feel the trends</h2>
                <p>Become the Comparere expert, get inspired by amazing designs.<br />
                  Base on statistics and advance your design trends knowledge.<br />Attract better clients, that appreciate your skills!</p>
                <Link to={RoutePath.SIGN_UP}>
                  <BtnCustomShadow>Browse designs</BtnCustomShadow>
                </Link>
              </div>
            </div>
          </section>
          <section>
          <DesignerSection/>
          </section>
        </div>
      </div>
    </>
  )
}

export default HomePage;