import './payment-status.styles.scss';
import React, { useEffect } from "react";
import Tile from '../../components/tile/tile.component';
import { ToastsStore } from 'react-toasts';
import { getPoints } from '../../redux/user/user.actions';
import { connect } from 'react-redux';

interface Props {
  match?: any;
  getPoints?: () => void;
}

const PaymentStatusPage: React.FC<Props> = (props: Props) => {
  const { match } = props;

/* eslint-disable */
  useEffect(() => {
    if (match.params.result === 'success') {
      ToastsStore.success('Success, your points will be updated');
      props.getPoints!();
    } else if (match.params.result === 'failes') {
      ToastsStore.error('Your transaction has been failed');
    }
  }, []);
/* eslint-enable */
  
  return (
    <>
      {match && match.params && match.params.result && (match.params.result === 'success' || match.params.result === 'failed') ?
        <div className='payment-status container'>
          <div className='tiles-wrapper'>
            <Tile>
              <h2 className="title">Operation status: {match.params.result}</h2>
              <p>
                {match.params.result === 'success' ? "Thanks for making purchases in our application. If your account balance has not yet been updated, please wait for the transaction to be approved. Otherwise, please contact with our support - we'll check your transaction manually." :
                  match.params.result === 'failed' ? "Your transaction has been failes. Check that you have the required funds or contact your bank. If everything is OK, please contact with our support - we will check your transaction manually." : null
                }
              </p>
            </Tile>
          </div>
        </div> : null}
    </>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  getPoints: () => dispatch(getPoints())
})

export default connect(null, mapDispatchToProps)(PaymentStatusPage);