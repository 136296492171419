import HomePageTypes from "./home-page.types";
import { DesignerOfDayDto } from "../../dto/designer/designer-of-day";

export const getDesignerOfTheDay = () => ({
  type: HomePageTypes.GET_DESIGNER_OF_THE_DAY,
});

export const getDesignerOfTheDaySuccess = (designer: DesignerOfDayDto) => ({
  type: HomePageTypes.GET_DESIGNER_OF_THE_DAY_SUCCESS,
  payload: designer
});