import React from "react";
import { reportController } from "../../Api/requests/add-report";
import { AddReportDto } from "../../dto/report/add-report-dto";
import { ReportTypeEnum } from "../../dto/report/report-type-enum";
import FormInputOvalDropdown from "../input-forms/form-input-oval-dropdown/form-input-oval-dropdown";
import FormInputOval from "../input-forms/form-input-oval/form-input-oval.component";
import BtnCustomShadow from "../../components/btn-custom-shadow/btn-custom-shadow";

interface State {
  reportType: string;
  details: string;
}

interface Props {
  comparereId: number;
  handleClosePopup?: any;
}

class ReportForm extends React.Component<Props, State> {
  state = {
    reportType: '',
    details: '',
  };

  handleSubmit = async (e: any) => {
    e.preventDefault()
    const { reportType, details } = this.state;
    let reportTypeEnum: ReportTypeEnum = (ReportTypeEnum as any)[reportType.toLowerCase() as keyof ReportTypeEnum];
    reportController.addNewReportAsync({ comparereId: this.props.comparereId, reportType: reportTypeEnum, comment: details } as AddReportDto)
    if(this.props.handleClosePopup) this.props.handleClosePopup()
  };

  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState({ [name]: value } as State);
  };

  render() {
    const { reportType, details } = this.state;

    return (
      <form className="report-form" onSubmit={this.handleSubmit}>
        <FormInputOvalDropdown
          name="reportType"
          type="text"
          label="Report type"
          value={reportType}
          onChange={this.handleChange}
          items={['Voulgarity', 'Offense', 'Trolling']}
          required
        />
        <FormInputOval
          name="details"
          type="textarea"
          label="Please describe details"
          value={details}
          onChange={this.handleChange}
          required
        />
        <BtnCustomShadow type="submit">Submit</BtnCustomShadow>
      </form>
    );
  }
}

export default ReportForm;