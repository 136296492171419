import { RandomComparereDto } from "../../dto/votes/random-comparere.dto";
import VotesCompareresTypes from "./votes.compareres.types";
import { AddVoteDto } from "../../dto/votes/add-vote.dto";
import { ComparereWithVotesAndUserChoiceDto } from "../../dto/comparere-with-votes-and-user-choice/comparete-with-votes-and-user-choice.ts.dto";

const INITIAL_STATE = {
  comparerePairForComparison: {} as RandomComparereDto,
  addVote: {} as AddVoteDto,
  historyOfUserChoicesWithVotesWithCompareres: {} as Array<ComparereWithVotesAndUserChoiceDto>,
  voteCounter: 0 as number,
  sharedComparereLimit: 0
};

const votesCompareresReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case VotesCompareresTypes.GET_COMPARERE_PAIR_FOR_COMPARISON_SUCCESS:
      return {
        ...state,
        comparerePairForComparison: action.payload
      };
    case VotesCompareresTypes.ADD_VOTE:
      return {
        ...state,
        addVote: action.payload,
      }
    case VotesCompareresTypes.INCREMENT_VOTE_COUNTER:
      return {
        ...state,
        voteCounter: state.voteCounter + action.payload
      }  
    case VotesCompareresTypes.GET_HISTORY_OF_USER_CHOICES_WITH_VOTES_WITH_COMPARERES_SUCCESS:
      return {
        ...state,
        historyOfUserChoicesWithVotesWithCompareres: action.payload
      }
    case VotesCompareresTypes.RESET_VOTE_COUNTER:
      return {
        ...state,
        voteCounter: 0
      }  
    default: {
      return state;
    }
  }
};

export default votesCompareresReducer;
