import React, { Component } from 'react';
import './fab.scss';
import infoFabBtn from "../../assets/info_fab.svg";
import alertFabBtn from "../../assets/bulb.svg";
import { CollapsibleMenu } from "../collapsible-menu/collapsible-menu.component";

interface Props {
  children: React.ReactNode;
  fabType: 'info' | 'alert'
}

interface State {
  isMenuOpen: boolean,
}

class InfoFab extends Component<Props, State> {

  constructor(props: any) {
    super(props)

    this.state = {
      isMenuOpen: false
    }
  }

  handleClose = () => {
    this.setState({ isMenuOpen: false })
  }

  handleOpen = () => {
    this.setState({ isMenuOpen: true })
  }

  render() {
    const { isMenuOpen } = this.state;
    const { fabType } = this.props;

    return (
      <div className={'fab-wrapper fab-wrapper-' + fabType}>
        <div className="menu-wrapper">
          <CollapsibleMenu isOpen={isMenuOpen} handleClose={this.handleClose} positionOver={true}>
            {this.props.children}
          </CollapsibleMenu>
        </div>
        <div className="info-fab-btn-wrapper">
          {fabType === 'info' ? <img src={infoFabBtn} onClick={this.handleOpen} alt="info" /> :
            fabType === 'alert' ? <img src={alertFabBtn} onClick={this.handleOpen} alt="alert" /> : null
          }
        </div>
      </div>
    )
  }
}

export default InfoFab;