import React from 'react'
import './round-btn-styles.scss'

export interface Props {
  icon: string
  onClick: any
  disabled?: boolean
}

const RoundBtn: React.FC<Props> =(props: Props)=> {

  const { icon, onClick, disabled } = props; 

  return (
    <div className='round-btn' onClick={!disabled || undefined ? onClick : undefined}>
      <img style = { { opacity: disabled ? 0.5 : 1 } } src={icon} alt="round-btn-icon"/>
    </div>
  )
}

export default RoundBtn