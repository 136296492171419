import React, { useState, useEffect } from 'react';
import './context-edit.styles.scss';
import SaveIcon from '../../assets/document-icons/save-icon.svg';
import EditIcon from '../../assets/document-icons/edit-icon.svg';
import { useDispatch } from 'react-redux';
import { updateComparereContext } from '../../redux/user-compareres/user-compareres.actions';
import { UserComparereContextUpdateDto } from '../../dto/user-compareres/user-comparere-context-update-dto';

interface Props {
  about: string;
  comparereId: number
}

const ContextEdit = (props: Props) => {
  const { about, comparereId } = props;
  const dispatch = useDispatch();
  const [edit, setedit] = useState(true);
  const [context, setContext] = useState('');
  const [textareaDisabled, setTextareaDisabled] = useState(true);

  useEffect(() => {
    setContext(about);
  }, [about])

  const handleSave = () => {
    setedit(true);
    setTextareaDisabled(true);
    let comparereContext: UserComparereContextUpdateDto = { comparereId: comparereId, contextData: context };
    dispatch(updateComparereContext(comparereContext));
  }

  const handleEdit = () => {
    setedit(!edit);
    setTextareaDisabled(false);
  }

  return (
    <div className={'context-edit-wrapper'}>
      <h3>Context</h3>
        {/* <img className={'context-icon'} src={ComparereContextIcon} alt="comparere-context-icon"/> */}
        <div className='icon-wrapper'>
          {edit ?
            <img onClick={handleEdit} className='edit-icon' src={EditIcon} alt='edit-icon'/>
            :
            <img onClick={handleSave} className='save-icon' src={SaveIcon} alt="save-icon"/>
          }
        </div>
      <>
        <div className={'context-edit'}>
          {!textareaDisabled ? <textarea onChange={(e)=>setContext(e.target.value)} value={context} className="form-input"/>
           :
          <textarea disabled value={context} className="form-input-disabled"/>}
        </div>
      </>
    </div>
  )
}

export default React.memo(ContextEdit); 