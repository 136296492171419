import React, { useState, useEffect, ReactChild } from 'react'
import AccordionItem from './accordion-item/accordion-item';
import { AccordionItemDto } from '../../dto/faq/accordion-item';
import './accordion.styles.scss'

interface Props {
  children: ReactChild
}

const Accordion =(props: Props | any) => {  
  
  const { children } = props;
  const [ blocks, setBlock ] = useState()

  useEffect(() => {
    if(blocks === undefined || blocks.length < children.length){
      setBlock(populateBlocksArray()); 
    }
  },[blocks]);

  const populateBlocksArray =()=> {
    const blocksArray = [];
    for(let i=0; i<children.length; i++){
      blocksArray.push({ [`block${i}`]: false })
    }
    return blocksArray
  }
 
  const toggle =(index: number)=> {
    setBlock({ [`block${index}`]: !blocks[`block${index}`] });
  }

    return (
      <div className="accordion-container">
        <dl className="accordion">
          {
            blocks ?
            children.map((item: AccordionItemDto, index: number) => (
              <AccordionItem key={index} title={item.question} onClick={()=>toggle(index)} expand={blocks[`block${index}`]}>
                {item.answer}
              </AccordionItem>
            )) : null
          }
        </dl>
      </div>
    ) 
}

export default Accordion;