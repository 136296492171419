import React, { useState } from "react";
import "./radio-buttons-group.styles.scss";

interface Props {
  buttonsValuesArray: Array<string>;
  labelTitle: string;
}

export const RadioButtonsGroup = (props: Props) => {
  const { buttonsValuesArray, labelTitle } = props;
  const [option, setOption] = useState({ value: undefined });

  const handleChange = (event: any) => {
    setOption({ value: event.target.value });
  };

  return (
    <div className="survey-qa-wrapper">
      <span className="title">{labelTitle}</span>
      <div className="radio-buttons-wrapper">
        {buttonsValuesArray.map((item, key) => {
          return (
            <label key={key} title={labelTitle}>
              <input
                type="radio"
                value={item}
                name={labelTitle.replace(/\s+/g, '')}
                checked={option.value === item}
                onChange={e => handleChange(e)}
                required={key === 0 ? true : false}
              />
              <div>
                {item}
              </div>
            </label>
          );
        })}
      </div>
    </div>
  );
};
