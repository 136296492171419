import './vote-scaler.scss';
import React, { Component, createRef } from "react";
import { RandomComparereDto } from "../../../dto/votes/random-comparere.dto";
import HeartWithShadow from "../../../assets/heartWithShadow_icon.svg";
import { AddVoteDto } from "../../../dto/votes/add-vote.dto";
import { ApiRootPath } from '../../../Api/ApiRootPath';
import BgImgLoader from '../../bg-img-loader/bg-img-loader';

interface Props {
  comparerePairForComparison: RandomComparereDto;
  vote: (voteDto: AddVoteDto) => void;
}

interface State {
  showIconLeft: string;
  showIconRight: string;
}

class VoteScaler extends Component<Props, State> {
  pictureA: React.RefObject<HTMLDivElement>;
  bar: React.RefObject<HTMLDivElement>;
  voteWrapper: React.RefObject<HTMLDivElement>;
  barWrapper: React.RefObject<HTMLDivElement>;
  timeouts = [] as Array<any>;
  clicks = [] as Array<number>;
  timeout = 0;
  isClickDisabled: boolean = false;

  constructor(props: any) {
    super(props);

    this.state = {
      showIconLeft: "",
      showIconRight: "",
    };

    this.bar = createRef();
    this.pictureA = createRef();
    this.voteWrapper = createRef();
    this.barWrapper = createRef();
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.drag);
    document.removeEventListener('touchmove', this.drag);

    for (let i = 0; i < this.timeouts.length; i++) {
      window.clearTimeout(this.timeouts[i]);
    }
  }

  startMove = () => {
    document.addEventListener('mousemove', this.drag);
    document.addEventListener('touchmove', this.drag);
  }

  endMove = () => {
    document.removeEventListener('mousemove', this.drag);
    document.removeEventListener('touchmove', this.drag);
  }

  drag = (e: any) => {
    window.getSelection()!.removeAllRanges();
    const barMarginLeft = (this.barWrapper.current!.clientWidth / 2) - 1;
    const position = (e.touches ? e.touches[0].pageX : e.pageX) - (this.voteWrapper.current!.offsetLeft + barMarginLeft);
    this.pictureA.current!.style.width = position + "px"
  }

  like = (pictureIndex: 0 | 1) => {
    if (this.isClickDisabled) return
    this.isClickDisabled = true;
    pictureIndex === 0 ?
      this.setState({ showIconLeft: "blockItem showIcon", showIconRight: "blockItem" }) :
      this.setState({ showIconRight: "blockItem showIcon", showIconLeft: "blockItem" });

    this.timeouts.push(window.setTimeout(() => {
      const { comparerePairForComparison, vote } = this.props;
      const addVote: AddVoteDto = { comparereId: comparerePairForComparison.id, selectedPictureId: comparerePairForComparison.pictures[pictureIndex].id }
      vote(addVote);
    }, 700));

    this.timeouts.push(window.setTimeout(() => {
      this.isClickDisabled = false;
    }, 2000));

    this.timeouts.push(window.setTimeout(() => {
      this.setState({ showIconLeft: "", showIconRight: "" })
      this.restBarPosition();
    }, 800));
  }

  doubleClick = (selectedPic: 0 | 1) => {
    this.like(selectedPic);
    this.clicks = [];
  }

  clickHandler = (e: any, selectedPic: 0 | 1) => {
    e.preventDefault();
    e.persist()
    this.clicks.push(new Date().getTime());
    window.clearTimeout(this.timeout);
    this.timeout = window.setTimeout(() => {
      if (this.clicks.length > 1 && this.clicks[this.clicks.length - 1] - this.clicks[this.clicks.length - 2] < 400) {
        this.doubleClick(selectedPic);
      } else {
        // this.singleClick();
      }
    }, 0);
  }

  restBarPosition = () => {
    window.getSelection()!.removeAllRanges();
    this.pictureA.current!.style.width = "calc(45% - 12px)";
    this.pictureA.current!.style.transitionDuration = "500ms";
    this.timeouts.push(window.setTimeout(() => {
      this.pictureA.current!.style.transitionDuration = "0ms";
    }, 500));
  }

  render() {
    const { comparerePairForComparison } = this.props;
    const { showIconLeft, showIconRight } = this.state;

    let pathA = comparerePairForComparison.pictures ? comparerePairForComparison.pictures[0].path.indexOf('https') >= 0 ?
      comparerePairForComparison.pictures[0].path : ApiRootPath.rootPath + comparerePairForComparison.pictures[0].path : "";

    let pathB = comparerePairForComparison.pictures ? comparerePairForComparison.pictures[1].path.indexOf('https') >= 0 ?
      comparerePairForComparison.pictures[1].path : ApiRootPath.rootPath + comparerePairForComparison.pictures[1].path : "";

    return comparerePairForComparison &&
      comparerePairForComparison.pictures ? (
        <div className="vote-vertical" ref={this.voteWrapper} onMouseUp={this.endMove} onTouchEnd={this.endMove}>
          <BgImgLoader attributes={{ id: "pictureA", ref: this.pictureA, className: showIconLeft, onClick: () => this.like(0) }} src={pathA} minLoadingTime={700}>
            <img className={'heartIcon'} src={HeartWithShadow} alt="heartIcon" />
          </BgImgLoader>
          <div className="breakBarWrapperParent" ref={this.barWrapper}>
            <div className="breakBarWrapper" ref={this.bar} onMouseDown={this.startMove} onTouchStart={this.startMove} >
              <div className="breakBar" />
            </div>
          </div>
          <BgImgLoader attributes={{ id: "pictureB", className: showIconRight, onClick: () => this.like(1) }} src={pathB} minLoadingTime={700}>
            <img className={'heartIcon'} src={HeartWithShadow} alt="heartIcon" />
          </BgImgLoader>
        </div>
      ) : null
  }
}

export default VoteScaler;