import React, { Component } from "react";
import { connect } from 'react-redux'
import { finalizeSignUp } from "../../../redux/account-settings/account-settings.actions";
import Tile from "../../../components/tile/tile.component";
import BtnCustom from "../../../components/btn-custom/btn-custom.component";
import { Popup } from "../../../components/popup/popup.component";
import FormInput from "../../input-forms/form-input/form-input.component";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import { UserDto } from "../../../dto/user/user-dto";
import { UserRoleEnum } from "../../../dto/user/user-role-enum";
import { FinalizeSignUpDto } from "../../../dto/account-settings/finalize-signup-dto";

interface State {
  email: string;
  newPassword: string;
  isOpen: boolean;
}

interface Props {
  finalizeSignUp: any;
  user: UserDto;
}

class FinalizeSignUpForm extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      email: '',
      newPassword: '',
      isOpen: false
    };
  }

  openPopup = (e: any) => {
    e.preventDefault()
    this.setState({ isOpen: true })
  };

  handleConfirm = () => {
    this.setState({ isOpen: false })
    this.handleSubmit();
  }

  handleCancel = () => {
    this.setState({ isOpen: false })
  }

  handleSubmit = async () => {
    const { email, newPassword } = this.state;

    const finalizeSignUpDto: FinalizeSignUpDto = { email: email, newPassword: newPassword }
    this.props.finalizeSignUp(finalizeSignUpDto);
  };

  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState({ [name]: value } as State);
  };

  render() {
    const { email, newPassword, isOpen } = this.state;
    const { user } = this.props;
    const isTempUser = user && user.roles.indexOf(UserRoleEnum.TempUser) > -1;
    if (!isTempUser) return null;
    return (
      <>
        <Popup isOpen={isOpen} handleConfirm={this.handleConfirm} handleCancel={this.handleCancel}>
          <div className="popup-title">Is the provided data correct?</div>
          <div className="popup-body">You will be sign out.</div>
        </Popup>
        <Tile>
          <h2 className="title">Finalize Sign Up</h2>
          <h3>+10 POINTS</h3>
          <form onSubmit={this.openPopup}>
            <FormInput
              name="email"
              type="email"
              label="Email"
              value={email}
              onChange={this.handleChange}
              required
            />
            <FormInput
              name="newPassword"
              type="password"
              label="New password"
              value={newPassword}
              onChange={this.handleChange}
              required
            />
            <BtnCustom type="submit">Submit</BtnCustom>
          </form>
        </Tile>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  finalizeSignUp: (finalizeSignUpDto: FinalizeSignUpDto) => dispatch(finalizeSignUp(finalizeSignUpDto))
})

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(FinalizeSignUpForm);
