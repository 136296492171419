import PointsTypes from "./points.types";

export const realizePromotionCode = (promotionCode: string) => ({
  type: PointsTypes.REALIZE_PROMOTION_CODE,
  payload: promotionCode
});

export const realizePromotionCodeSuccess = (message: any) => ({
  type: PointsTypes.REALIZE_PROMOTION_CODE_SUCCESS,
  payload: message
});