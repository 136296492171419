import React from 'react';
import { SubcategoryDistributionDto } from "../../../../dto/user-compareres/subcategory-distribution-dto";
import './graph-column.styles.scss';

interface Props {
  subcategoryDistributionDto: SubcategoryDistributionDto
  maxVotesCount: number;
}

export const GraphColumn: React.FC<Props> = (props: Props) => {
  const { subcategoryDistributionDto, maxVotesCount } = props;
  return (
    <div className='graph-columns-wrapper'>
      <div className='graph-column left'>
        <div className="nested_description">{subcategoryDistributionDto.userPictureIdWithVotesDto_A.votes.toString()}</div>
        <div className='graph-column-details'>
          <div className='nested' style={
            {
              width: `calc(((100vw / 2.5 ) - 80px) * ${subcategoryDistributionDto.userPictureIdWithVotesDto_A.votes / maxVotesCount})`,
              maxWidth: `calc(((1200px / 2.5 ) - 50px) * ${subcategoryDistributionDto.userPictureIdWithVotesDto_A.votes / maxVotesCount})`
            }
          }></div>
        </div>
      </div>
      <div className='indicator'>{subcategoryDistributionDto.indicator}</div>
      <div className='graph-column right'>
        <div className='graph-column-details'>
          <div className='nested' style={{
            width: `calc(((100vw / 2.5 )  - 80px) * ${subcategoryDistributionDto.userPictureIdWithVotesDto_B.votes / maxVotesCount})`,
            maxWidth: `calc(((1200px / 2.5 )  - 50px) * ${subcategoryDistributionDto.userPictureIdWithVotesDto_B.votes / maxVotesCount})`
          }}></div>
        </div>
        <div className="nested_description">{subcategoryDistributionDto.userPictureIdWithVotesDto_B.votes.toString()}</div>
      </div>
    </div>
  );
}

export default GraphColumn