import React, { useEffect, useState } from "react";
import { SocialMediaLinkDto } from "../../../dto/account-settings/userProfile/social-media-link-dto";
import XBtn from "../../../assets/btns/x.svg";
import "./social-link.styles.scss";
import { mapLinkToIcon } from "../../../Tools/MapLinkToIcon";

export interface SocialLinkProps {
  socialMediaLink: SocialMediaLinkDto;
  onClick: (linkId: number) => void;
}

const SocialLink: React.FC<SocialLinkProps> = (props: SocialLinkProps) => {
  const { socialMediaLink, onClick } = props;
  const [state, setstate] = useState({icon: '', name:''});

  useEffect(() => {
    setstate(mapLinkToIcon(socialMediaLink.link));
  }, [])

  return (
    <div className="wrapper">
      <div className="link-wrapper">
        <div className="media-name-and-icon">
          <img className="media-icon" src={state.icon} alt="media-icon" />
            <p>{state.name}</p>
        </div>
        <div className="link">
          {socialMediaLink ? socialMediaLink.link : ""}
        </div>
      </div>
      <img
        onClick={() => onClick(socialMediaLink.id)}
        src={XBtn}
        alt="x-img"
        className="delete-btn"
      ></img>
    </div>
  );
};

export default SocialLink;
