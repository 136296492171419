import React, { Component } from "react";
import { connect } from 'react-redux'
import Tile from "../../components/tile/tile.component";
import { changeUserDetails } from "../../redux/account-settings/account-settings.actions";
import { AccountSettingsController } from "../../Api/requests/account-settings";
import { UserDetailsDto } from "../../dto/account-settings/user-details-dto";
import { SexTypeEnum } from "../../dto/account-settings/sex-type-enum";
import FormInputOvalDropdown from "../input-forms/form-input-oval-dropdown/form-input-oval-dropdown";
import BtnCustomShadow from "../../components/btn-custom-shadow/btn-custom-shadow";

interface State {
  yearOfBirth?: number,
  sexTypeEnum?: string,
  tabWithYears: Array<string>
  extraPoints: number,
}

interface Props {
  changeUserDetails?: any;
}

class ChangeUserDetailsForm extends Component<Props, State> {
  state = {
    yearOfBirth: 0,
    sexTypeEnum: undefined,
    tabWithYears: [],
    extraPoints: 0,
  };

  componentDidMount() {
    let tabWithYears: Array<string> = [];
    let dateNow = new Date().getFullYear();
    for (let i = 0; i < 73; i++) {
      tabWithYears.push(((dateNow - i) - 12).toString())
    }
    this.setState({ tabWithYears: tabWithYears })
    AccountSettingsController.GetUserDetails().then((res) => {
      this.setState({
        extraPoints: (res.yearOfBirth === 0 ? 10 : 0) + ((res.sexTypeEnum === 0 || res.sexTypeEnum === null) ? 10 : 0),
        yearOfBirth: res.yearOfBirth,
        sexTypeEnum: SexTypeEnum[res.sexTypeEnum]
      });
    })
  }

  handleSubmit = async (e: any) => {
    e.preventDefault();
    const { yearOfBirth, sexTypeEnum } = this.state;
    let userDetails = {
      yearOfBirth: yearOfBirth,
      sexTypeEnum: SexTypeEnum[sexTypeEnum! as keyof typeof SexTypeEnum]
    } as UserDetailsDto;
    this.setState({
      extraPoints: (yearOfBirth === 0 ? 10 : 0) + ((sexTypeEnum === 0 || sexTypeEnum === null || sexTypeEnum === undefined) ? 10 : 0),
    });
    this.props.changeUserDetails(userDetails);
  };

  handleChangeStringToNum = (e: any) => {
    const { value } = e.target;
    this.setState({ yearOfBirth: Number(value) } as State);
  };

  handleChangeEnum = (e: any) => {
    const { value } = e.target;
    this.setState({ sexTypeEnum: value } as State);
  };

  render() {
    const { yearOfBirth, sexTypeEnum, tabWithYears, extraPoints: gratisPoints } = this.state;
    return (
      <Tile>
        <h2 className="title">User Details</h2>
        {gratisPoints > 0 ? <h3>{'+' + gratisPoints + ' points'}</h3> : null}
        <form onSubmit={this.handleSubmit}>
          <FormInputOvalDropdown
            name="yearOfBirth"
            type="text"
            label="Year of birth"
            value={yearOfBirth === 0 ? '' : yearOfBirth}
            onChange={this.handleChangeStringToNum}
            items={tabWithYears}
            keepLabelShrinked={true}
            required={false}
          />
          <FormInputOvalDropdown
            name="sexTypeEnum"
            type="text"
            label="Sex"
            value={sexTypeEnum === 0 ? '' : sexTypeEnum}
            keepLabelShrinked={true}
            onChange={this.handleChangeEnum}
            items={['Man', 'Woman', 'Other', '']}
            required={false}
          />
          <BtnCustomShadow type="submit">Change</BtnCustomShadow>
        </form>
      </Tile>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  changeUserDetails: (userDetails: UserDetailsDto) => dispatch(changeUserDetails(userDetails))
})

export default connect(null, mapDispatchToProps)(ChangeUserDetailsForm);