import React from 'react'
import SignInForm from '../../forms/sign-in-form/sign-in-form.component';
import './sign-in-page.styles.scss';

const SignInPage = () => {
  return (
    <div className='sign-in-page container'>
      <div className='tiles-wrapper'>
        <SignInForm />
      </div>
    </div>
  )
}

export default SignInPage;