import "./user-compareres.styles.scss";
import React, { Component } from "react";
import { connect } from 'react-redux'
import { UserComparereWithVotesDto } from "../../dto/user-compareres/user-comparere-with-votes-dto";
import { createStructuredSelector } from "reselect";
import { selectUserCompareres } from "../../redux/user-compareres/user-compareres.selectors";
import { getUserCompareres } from "../../redux/user-compareres/user-compareres.actions";
import { ComparerereStore } from "../../redux/store.types";
import UserComparereTile from "../../components/user-comparere-tile/user-comparere-tile.component";
import InfiniteScroll from "../../components/infinite-scroll/infinite-scroll.component";
import PageTitleBar from "../../components/text-components/page-title-bar/page-title-bar";
import addIcon from "./../../navIcons/add-icon.svg"

interface Props {
  getUserCompareres: any;
}

interface DerivedProps {
  userCompareres: Array<UserComparereWithVotesDto>;
}

interface State {
  userCompareresMerged: Array<UserComparereWithVotesDto>;
}

class UserCompareres extends Component<Props & DerivedProps, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      userCompareresMerged: []
    }
  }

  componentDidMount() {
    this.props.getUserCompareres(0);
  }

  render() {
    const { userCompareres } = this.props;
    return (
      <InfiniteScroll loadData={this.props.getUserCompareres} itemsPerPage={12} currentItemsNumber={userCompareres.length}>
        <div className='user-compareres container'>
          <PageTitleBar>YOUR COMPARERES</PageTitleBar>
          <div className='tiles-wrapper'>
            {userCompareres && userCompareres.length > 0 ? userCompareres.map((comparere: UserComparereWithVotesDto) => {
              return (
                <UserComparereTile key={comparere.id} userComparere={comparere} />
              )
            }) : userCompareres && userCompareres.length === 0 ?
                <div className="any-compareres">
                  Currently you have no Compareres, you can add some by clicking on the <img className="addIcon" src={addIcon} alt="add" /> icon in the navigation
                </div> : <div className="any-compareres">
                  Connecting to the server... Please wait...
                </div>
            }
          </div>
        </div>
      </InfiniteScroll>
    );
  }
}
const mapStateToProps = createStructuredSelector<ComparerereStore, DerivedProps>({
  userCompareres: selectUserCompareres
});

const mapDispatchToProps = (dispatch: any) => ({
  getUserCompareres: (page: number) => dispatch(getUserCompareres(page))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserCompareres);