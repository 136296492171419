import React, { useEffect, useState } from 'react'
import { MemoizedCompareresGraph } from './comparere-graph/compareres-graph.component'
import { withRouter } from 'react-router-dom'
import { ComparereDetailsTypeEnum } from '../../dto/user-compareres/comparere-details-type-enum';
import PageTitleBar from '../text-components/page-title-bar/page-title-bar';
import './user-comparere-details.styles.scss';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getUserComparereById, clearUserComparereDetails } from '../../redux/user-compareres/user-compareres.actions';
import { selectUserComparereWithVotes, selectComparereDetails } from '../../redux/user-compareres/user-compareres.selectors';
import { ApiRootPath } from '../../Api/ApiRootPath';
import arrows from '../../assets/comparere-details-page/arrows.svg'
import ArrowBtn from '../arrow-btn/arrow-btn.component';
import { RoutePath } from '../../route-paths';
import ContextEdit from '../context-edit/context-edit.component';
import PrivacyEdit from '../privacy-edit/privacy-edit';


const UserComparereDetails: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const comparereWithVotes = useSelector(selectUserComparereWithVotes, shallowEqual);
  const comparereDetails = useSelector(selectComparereDetails, shallowEqual);

  useEffect(() => {
    const { match } = props;
    dispatch(getUserComparereById(match.params.id));
    return () => {
      dispatch(clearUserComparereDetails());
    }
  }, []);

  const [state, setstate] = useState({ age: true, sex: false })
  const imgPathA = comparereWithVotes && comparereWithVotes.picturesWithVotes ? ApiRootPath.rootPath + comparereWithVotes.picturesWithVotes[0].path : null;
  const imgPathB = comparereWithVotes && comparereWithVotes.picturesWithVotes ? ApiRootPath.rootPath + comparereWithVotes.picturesWithVotes[1].path : null;
  const votesCountA = comparereWithVotes && comparereWithVotes.picturesWithVotes ? comparereWithVotes.picturesWithVotes[0].votesCount : 0;
  const votesCountB = comparereWithVotes && comparereWithVotes.picturesWithVotes ? comparereWithVotes.picturesWithVotes[1].votesCount : 0;
  return (
    <div className='comparere-details container'>
      <PageTitleBar>Comparere Details</PageTitleBar>
      <PrivacyEdit
        comparereId={props.match.params.id}
        isPrivate={comparereDetails.isPrivate ? comparereDetails.isPrivate : false}
      />
      <ContextEdit
        about={comparereDetails.contextData ? comparereDetails.contextData : ''}
        comparereId={props.match.params.id}
      />
      <PageTitleBar>Voting results</PageTitleBar>
      <div className='arrows-points-wrapper'>
        <div className='total-votes'>Total votes
        <div className='total-votes-count'>{votesCountA + votesCountB}</div>
        </div>
        <img src={arrows} className='arrows' alt="arrows" />
        <div className='points-wrapper'>
          <div className='points left'>{votesCountA}</div>
          <div className='points right'>{votesCountB}</div>
        </div>
      </div>
      {imgPathA && imgPathB !== null && (imgPathA && imgPathB) !== undefined ?
        <div className='images-wrapper'>
          <img className="image left" src={imgPathA} alt="leftImg" />
          <img className="image right" src={imgPathB} alt="rightImg" />
        </div>
        : null
      }
      <>
        <div className='graph-change-btn-wrapper'>
          <div className='btn-wrapper' onClick={() => setstate({ age: true, sex: false })}>
            <ArrowBtn title='age' fontSize='2rem' uppercase={true} active={state.age} />
          </div>
          <div className='btn-wrapper' onClick={() => setstate({ age: false, sex: true })}>
            <ArrowBtn title='sex' fontSize='2rem' uppercase={true} active={state.sex} />
          </div>
        </div>
        {state.age ?
          <MemoizedCompareresGraph
            comparereDetailsTypeEnum={ComparereDetailsTypeEnum.age}
            userComparereId={props.match.params.id}
          />
          : state.sex ?
            <MemoizedCompareresGraph
              comparereDetailsTypeEnum={ComparereDetailsTypeEnum.gender}
              userComparereId={props.match.params.id}
            />
            : null
        }
        <div className='btn-wrapper arrow'>
          <ArrowBtn
            title='Back to compareres'
            left={true}
            uppercase={true}
            link={RoutePath.USER_COMPARERES}
          />
        </div>
      </>
      }
      </div>
  )
}

export default withRouter(UserComparereDetails)