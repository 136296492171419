import './add-new-comperere.styles.scss'
import React, { Component } from "react";
import Popup from '../../components/popup/popup.component';
import Dropzone from "../../components/drop-zone/drop-zone.component";
import BtnCustom from "../../components/btn-custom/btn-custom.component";
import { ToastsStore } from 'react-toasts';
import { uploadCompareres } from '../../Api/requests/upload-compareres';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { History } from 'history';
import { RoutePath } from '../../route-paths';
import PageTitleBar from '../../components/text-components/page-title-bar/page-title-bar';
import { UserComparereWithVotesDto } from '../../dto/user-compareres/user-comparere-with-votes-dto';
import FormInput from '../../forms/input-forms/form-input/form-input.component';
import copyIcon from '../../resources/copy_icon.svg';
import optionsIcon from '../../resources/options_icon.svg';
import userIcon from "./../../navIcons/user-icon-stroke-res.svg";
import contexPlus from './../../assets/comparere-context/context-plus.svg';
import FormInputOval from '../../forms/input-forms/form-input-oval/form-input-oval.component';
import BtnCustomShadow from '../../components/btn-custom-shadow/btn-custom-shadow';
import CheckboxShadow from '../../components/checkbox-shadow/checkbox-shadow';

interface Props {
  user: any;
  history?: History
}

interface State {
  files: Array<File>;
  isConfirmPopupOpen: boolean;
  clearDropZones: boolean;
  isRedirectToPointsPagePopupOpen: boolean;
  isSharePopupOpen: boolean,
  userComparere?: UserComparereWithVotesDto;
  comparereContext: string,
  comparereIsPrivate: boolean,
}

class AddNewComperere extends Component<Props, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      files: [] as Array<File>,
      isConfirmPopupOpen: false,
      clearDropZones: false,
      userComparere: undefined,
      isRedirectToPointsPagePopupOpen: false,
      isSharePopupOpen: false,
      comparereContext: '',
      comparereIsPrivate: false,
    }
  }

  onFileAdded = (item: any) => {
    const { files } = this.state;
    const { user } = this.props
    if (user.points >= 25) {
      let items = [...files];
      items[item.id] = item.file;
      this.setState({ files: items });
    } else {
      this.setState({ isRedirectToPointsPagePopupOpen: true });
    }
  }

  addComparere = () => {
    const { files } = this.state
    if (files.length === 2) {
      this.setState({ isConfirmPopupOpen: true })
    }
    else {
      ToastsStore.warning('Please upload 2 images.');
    }
  }

  clearForm = () => {
    this.setState({ files: [] as Array<File>, clearDropZones: true }, () => {
      this.setState({ clearDropZones: false, });
    });
    this.setState({ comparereContext: '', comparereIsPrivate: false });
  }

  handleConfirm = () => {
    const { files, comparereContext, comparereIsPrivate } = this.state
    this.setState({ isConfirmPopupOpen: false })
    uploadCompareres.applyComparereAsync(files, comparereContext, comparereIsPrivate).then((res: UserComparereWithVotesDto) => {
      this.clearForm();
      this.setState({ isConfirmPopupOpen: false, isSharePopupOpen: true, userComparere: res })
    })
  }

  handleCopy = (event: any) => {
    this.handleFocus(event);
    document.execCommand('copy');
    ToastsStore.success('Your share url has been copied to clipboard.');
  }

  handleFocus = (event: any) => {
    event.target.parentElement.getElementsByTagName("input")[0].select();
  }

  handleCancel = () => {
    this.setState({ isConfirmPopupOpen: false, isRedirectToPointsPagePopupOpen: false, isSharePopupOpen: false })
  }

  render() {
    const { isConfirmPopupOpen, isRedirectToPointsPagePopupOpen, clearDropZones, isSharePopupOpen, userComparere, comparereContext, comparereIsPrivate } = this.state;
    const { history } = this.props;
    return (
      <>
        <Popup isOpen={isConfirmPopupOpen} handleConfirm={this.handleConfirm} handleCancel={this.handleCancel}>
          <div className="popup-title">It will cost 15 points!</div>
          <div className="popup-body">Are you sure?</div>
        </Popup>
        <Popup title={"Share your secret link to Comparere"} isOpen={isSharePopupOpen} handleCancel={this.handleCancel} hideFooter>
          <div className="popup-body shareInputWrapper">
            <div>You'll get feedback faster, but be carefoul! If you send it to anonymous spammers, their votes can falsify results. </div>
            {userComparere ?
              <FormInput value={window.origin + "/uc=" + userComparere.publicId} readOnly={true} onFocus={this.handleFocus} icon={
                <img src={copyIcon} alt="copy" className="copyIcon" onClick={this.handleCopy} />
              } />
              : null}
            <br />
            <br />
            <div className="linkPlaceInfo"> This link will be also available in: <br />
              <img src={userIcon} alt="userIcon" />
              <span className="arrowIcon">&rarr;</span>
              COMPARERES
              <span className="arrowIcon">&rarr;</span>
              <img src={optionsIcon} alt="options" />
              <span className="arrowIcon">&rarr;</span>
              SHARE
            </div>
          </div>
        </Popup>
        <Popup isOpen={isRedirectToPointsPagePopupOpen} handleCancel={this.handleCancel} hideFooter={true}>
          <div className="popup-title">You have not enough points.</div>
          <div className="popup-body" style={{ minWidth: '23vw' }}>
            <p>
              Just vote few times. <br /> Each correct vote grants you 1 point.<br /> <br /> <br />  or <br />  Support our Team<br /><br />
            </p>
            <BtnCustom onClick={() => history!.push(RoutePath.POINTS_PAGE)}>Buy points</BtnCustom>
          </div>
        </Popup>
        <div className='add-new-compareres container'>
          <PageTitleBar>ADD NEW COMPARERE</PageTitleBar>
          <div className="cards-wrapper">
            <div className="card">
              <Dropzone clear={clearDropZones} onFileAdded={(e: any) => this.onFileAdded(e)} disabled={false} id={0} />
            </div>
            <div className="card">
              <Dropzone clear={clearDropZones} onFileAdded={(e: any) => this.onFileAdded(e)} disabled={false} id={1} />
            </div>
          </div>
          <PageTitleBar>Settings</PageTitleBar>
          <div className="settings-wrapper">
            <h3>Privacy</h3>
            <CheckboxShadow
              onChange={(e: any) => this.setState({ comparereIsPrivate: e.target.value })}
              label='Is private? (Will be visible only to the people you share the link with)'
              name='isPrivate'
              value={comparereIsPrivate}
              id='isPrivate'
            />
            <h3>Context (optional)</h3>
            <FormInputOval
              name="details"
              type="textarea"
              labelIcon={contexPlus}
              value={comparereContext}
              textAreaPaddings={12}
              onChange={(e: any) => this.setState({ comparereContext: e.target.value })}
              labelOffOnClick={true}
              required
            />
            <div className='custom-btn-wrapper'>
              <BtnCustomShadow onClick={this.addComparere}>Apply</BtnCustomShadow>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser
});

export default connect(mapStateToProps, null)(AddNewComperere);