import AccountSettingsTypes from "./account-settings.types";
import { UserProfileDto } from "../../dto/account-settings/userProfile/user-profile-dto";

const INITIAL_STATE = {
  userProfile: {} as UserProfileDto
}

const accountSettingsReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AccountSettingsTypes.ADD_SOCIAL_MEDIA_LINK_SUCCESS:
      return {
        ...state,
        userProfile: action.payload
      };
    default: {
      return state;
    }
  }
};

export default accountSettingsReducer;