import React from 'react';
import './privacy-policy-page.scss';
import PageTitleBar from '../../components/text-components/page-title-bar/page-title-bar';
import { RoutePath } from '../../route-paths';
import { Link } from 'react-router-dom';

export const PrivacyPolicyPage = () => {
  return (
    <div className='privacy-policy-page'>
      <PageTitleBar>Privacy Policy</PageTitleBar>
      <div className="container">
        <h5>Last update: 1 October, 2019</h5>
        <h3>Your privacy is critically important to us.</h3>
        <h4>General information</h4>
        <p>It is Comparere's policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to <a href="https://comparere-ui.codeebo.com">https://comparere-ui.codeebo.com</a> (hereinafter, "us", "we", or "https://comparere-ui.codeebo.com"). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.</p>
        <p>This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the general rules and policies governing your use of our Website. Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions.</p>
        <h4>Location</h4>
        <address>Codeebo Michał Kowalczyk<br />Rubinowa 11a, Tyniec Mały<br />55-040, Poland</address>
        <h3>Website Visitors</h3>
        <p>Like most website operators, Comparere collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Comparere's purpose in collecting non-personally identifying information is to better understand how Comparere's visitors use its website. From time to time, Comparere may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.</p>
        <p>Comparere also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on https://comparere-ui.codeebo.com blog posts. Comparere only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.</p>

        <h3>Gathering of Personally-Identifying Information</h3>
        <p>Certain visitors to Comparere's websites choose to interact with Comparere in ways that require Comparere to gather personally-identifying information. The amount and type of information that Comparere gathers depends on the nature of the interaction. For example, we ask visitors who sign up for a blog at https://comparere-ui.codeebo.com to provide a username and email address. Visitors who sign up to Comparere Service we ask to provide email, username, sex and year of birth.</p>

        <h3>Security</h3>
        <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

        <h3>Advertisements</h3>
        <p>Ads appearing on our website may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by Comparere and does not cover the use of cookies by any advertisers.</p>

        <h3>Links To External Sites</h3>
        <p>Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit.</p>
        <p>We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.</p>

        <h3>comparere-ui.codeebo.com uses Google AdWords for remarketing</h3>
        <p>comparere-ui.codeebo.com uses the remarketing services to advertise on third party websites (including Google) to previous visitors to our site. It could mean that we advertise to previous visitors who haven't completed a task on our site, for example using the contact form to make an enquiry. This could be in the form of an advertisement on the Google search results page, or a site in the Google Display Network. Third-party vendors, including Google, use cookies to serve ads based on someone's past visits. Of course, any data collected will be used in accordance with our own privacy policy and Google's privacy policy.</p>
        <p>You can set preferences for how Google advertises to you using the Google Ad Preferences page, and if you want to you can opt out of interest-based advertising entirely by cookie settings or permanently using a browser plugin.</p>

        <h3>We use Hotjar in order to better understand our users</h3>
        <p>We use Hotjar in order to better understand our users’ needs and to optimize this service and experience. Hotjar is a technology service that helps us better understand our users’ experience (e.g. how much time they spend on which pages, which links they choose to click, what users do and don’t like, etc.) and this enables us to build and maintain our service with user feedback. Hotjar uses cookies and other technologies to collect data on our users’ behavior and their devices. This includes a device's IP address (processed during your session and stored in a de-identified form), device screen size, device type (unique device identifiers), browser information, geographic location (country only), and the preferred language used to display our website. Hotjar stores this information on our behalf in a pseudonymized user profile. Hotjar is contractually forbidden to sell any of the data collected on our behalf. For further details, please see the ‘about Hotjar’ section of Hotjar’s support site.</p>

        <h3>Protection of Certain Personally-Identifying Information</h3>
        <p>Comparere discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on Comparere's behalf or to provide services available at Comparere's website, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using Comparere's website, you consent to the transfer of such information to them. Comparere will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, Comparere discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when Comparere believes in good faith that disclosure is reasonably necessary to protect the property or rights of Comparere, third parties or the public at large.</p>
        <p>If you are a registered user of https://comparere-ui.codeebo.com and have supplied your email address, Comparere may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what's going on with Comparere and our products. We primarily use our blog to communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Comparere takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.</p>

        <h3>Aggregated Statistics</h3>
        <p>Comparere may collect statistics about the behavior of visitors to its website. Comparere may display this information publicly or provide it to others. However, Comparere does not disclose your personally-identifying information.</p>

        <h3>Affiliate Disclosure</h3>
        <p>This site uses affiliate links and does earn a commission from certain links. This does not affect your purchases or the price you may pay.</p>

        <h3>Cookies</h3>
        <p>To enrich and perfect your online experience, Comparere uses "Cookies", similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer.</p>
        <p>A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. Comparere uses cookies to help Comparere identify and track visitors, their usage of https://comparere-ui.codeebo.com, and their website access preferences. Comparere visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Comparere's websites, with the drawback that certain features of Comparere's websites may not function properly without the aid of cookies.</p>
        <p>By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to Comparere's use of cookies. For more informations you can go to <Link to={RoutePath.COOKIES_POLICY}>Cookies Policy</Link> page</p>

        {/* <h3>E-commerce</h3>
        <p>Those who engage in transactions with Comparere – by purchasing Comparere's services or products, are asked to provide additional information, including as necessary the personal and financial information required to process those transactions. In each case, Comparere collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor's interaction with Comparere. Comparere does not disclose personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain website-related activities.</p> */}

        <h3>Business Transfers</h3>
        <p>If Comparere, or substantially all of its assets, were acquired, or in the unlikely event that Comparere goes out of business or enters bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of Comparere may continue to use your personal information as set forth in this policy.</p>

        <h3>Privacy Policy Changes</h3>
        <p>Although most changes are likely to be minor, Comparere may change its Privacy Policy from time to time, and in Comparere's sole discretion. Comparere encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p>

        <h3>Credit &amp; Contact Information</h3>
        <p>This privacy policy was created at <a href="https://termsandconditionstemplate.com/privacy-policy-generator/" title="Privacy policy template generator" target="_blank" rel="noopener noreferrer">termsandconditionstemplate.com</a>. If you have any questions about this Privacy Policy, please contact us via <Link to={RoutePath.CONTACT}>Contact Page</Link>.</p>
      </div>
    </div>
  )
}