import React, { Component } from "react";
import { connect } from 'react-redux'
import { finalizeSignUp } from "../../../redux/account-settings/account-settings.actions";
import BtnCustom from "../../../components/btn-custom/btn-custom.component";
import FormInput from "../../input-forms/form-input/form-input.component";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import { UserDto } from "../../../dto/user/user-dto";
import { UserRoleEnum } from "../../../dto/user/user-role-enum";
import { FinalizeSignUpDto } from "../../../dto/account-settings/finalize-signup-dto";

interface State {
  email: string;
  newPassword: string;
}

interface Props {
  finalizeSignUp: any;
  user: UserDto;
}

class FinalizeSignUpFormQuick extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      email: '',
      newPassword: '',
    };
  }

  componentWillReceiveProps(props: Props) {
    const { user } = this.props;
    const isComparereEmail = user.email.substring(user.email.lastIndexOf("@") + 1, user.email.length).localeCompare("comparere-ui.codeebo.com") === 0
    if (!isComparereEmail) {
      this.setState({
        email: user.email,
      })
    }
  }

  handleSubmit = async (e: any) => {
    e.preventDefault();
    const { email, newPassword } = this.state;
    const { user } = this.props;

    const finalizeSignUpDto: FinalizeSignUpDto = { email: email.length > 0 ? email : (user ? user.email : ""), newPassword: newPassword }
    this.props.finalizeSignUp(finalizeSignUpDto);
  };

  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState({ [name]: value } as State, () => {
    });
  };

  render() {
    const { email, newPassword } = this.state;
    const { user } = this.props;
    const isTempUser = user && user.roles.indexOf(UserRoleEnum.TempUser) > -1;
    const isComparereEmail = user.email.substring(user.email.lastIndexOf("@") + 1, user.email.length).localeCompare("comparere-ui.codeebo.com") === 0
    if (!isTempUser) return null;
    return (
      <>
        <h2 className="title">Finalize Sign Up</h2>
        <h3>+10 POINTS</h3>
        <form onSubmit={this.handleSubmit}>
          {isComparereEmail ?
            <FormInput
              name="email"
              type="email"
              label="Email"
              value={email}
              onChange={this.handleChange}
              required
            />
            : null}
          <FormInput
            name="newPassword"
            type="password"
            label="New password"
            value={newPassword}
            onChange={this.handleChange}
            required
          />
          <BtnCustom type="submit">Submit</BtnCustom>
        </form>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  finalizeSignUp: (finalizeSignUpDto: FinalizeSignUpDto) => dispatch(finalizeSignUp(finalizeSignUpDto))
})

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(FinalizeSignUpFormQuick);
