import React from "react";
import "./tile.styles.scss";

interface Props {
  children: React.ReactNode,
  buttonInTopRight?: JSX.Element
}

const Tile: React.FC<Props> = (props: Props) => {
  return (
    <div className="tileWrapper">
      <div className="tile">
        {props.buttonInTopRight ? <div className="addictionalButtonTopRight">{props.buttonInTopRight}</div> : null}
        {props.children}
      </div>
    </div>
  )
}

export default Tile;
