import React, { FC, useState } from "react";
import { RadioButtonsGroup } from "../../components/radio-buttons-group/radio-buttons-group.component";
import { SurveyDto } from "../../dto/survey/survey-dto";
import PageTitleBar from "../../components/text-components/page-title-bar/page-title-bar";
import './survey-page.styles.scss'
import BtnCustom from "../../components/btn-custom/btn-custom.component";
import { useDispatch } from 'react-redux';
import { sendSurvey } from "../../redux/survey/survey.actions";
import { SurveyQnA } from "../../dto/survey/qa-list-dto";
import FormInput from "../../forms/input-forms/form-input/form-input.component";
import { RouteComponentProps } from "react-router-dom";
import { RoutePath } from "../../route-paths";

const SurveyPage: FC<any> = (props: any & RouteComponentProps) => {
  const dispatch = useDispatch();
  let surveyDto = {} as SurveyDto;
  let validatorCounter = 0 as number;
  const [email, setEmail] = useState('');

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const SurveyListDto: Array<SurveyQnA> = [];

    const elements = e.target.elements;
    for (let i = 0; i < elements.length - 1; i++) {
      if (elements[i].checked) {
        SurveyListDto.push({ question: elements[i].parentNode.title, answer: elements[i].value } as SurveyQnA)
      }
    }
    surveyDto.email = email;
    surveyDto.qNaList = SurveyListDto;
    dispatch(sendSurvey(surveyDto));

    setEmail('');
    props.history.push(RoutePath.HOME_PAGE);
  }

  const scrollAboveQuestion = (e: any) => {
    if (e.target.type === "radio") {
      if (validatorCounter % (e.target.parentNode.parentNode.parentNode.parentNode.elements.length) === 0) {
        setTimeout(() => {
          window.scrollTo(0, window.scrollY - 200);
          validatorCounter = -1;
        }, 10);
      }
      validatorCounter++;
    }
  }

  const handleEmailChange = (e: any) => {
    e.preventDefault();
    setEmail(e.target.value);
  }

  return (
    <>
      <PageTitleBar>Survey</PageTitleBar>
      <form className='survey-form' onSubmit={handleSubmit} onInvalidCapture={scrollAboveQuestion}>
        <RadioButtonsGroup labelTitle={'What are you into with graphic design?'} buttonsValuesArray={["Nothing", "I'm an amateur", "That's my job", "That's my job and my passion"]} />
        <RadioButtonsGroup labelTitle={'Are you a member of social media groups bringing together artists, UX/graphic designers?'} buttonsValuesArray={["I have never heard of them", "No, I'm not", "I'm, but I'm a passive user", "I'm an active user"]} />
        <RadioButtonsGroup labelTitle={'Do you collect feedback on websites like Behance or Dribble?'} buttonsValuesArray={["No and I won't", "No, but I will", "Yes, sometimes", "Always, It's my portfolio"]} />
        <RadioButtonsGroup labelTitle={'Do you know any tool for measuring the quality of design?'} buttonsValuesArray={["No, It would be too subjective", "No, but I would tottaly use", "Yes, but it is based on popularity ", "Yes and it is not based on popularity"]} />
        <RadioButtonsGroup labelTitle={'Do you have doubts about your project while working on your design?'} buttonsValuesArray={["No, I'm the tastemaker", "Sometimes", "Often", "Always"]} />
        <RadioButtonsGroup labelTitle={'Do you create multiple versions of your work?'} buttonsValuesArray={["I don't", "Every once in a while", "My workspace is filled with prototypes", "Yes, I always have a few versions"]} />
        <RadioButtonsGroup labelTitle={'Would the decision-making tool help you save some time?'} buttonsValuesArray={["No", "It's possible", "Yes", "Hell yes!"]} />
        <RadioButtonsGroup labelTitle={'How often do you ask your friends for opinion on your work?'} buttonsValuesArray={["I don't", "Every once in a while", "Often", "Always"]} />
        <RadioButtonsGroup labelTitle={'Do you favor your work that you spent more time on?'} buttonsValuesArray={["No, I'm objective", "No, I take into account the opinions of others", "Yes, I don't give in to opinions", "I'm always right"]} />
        <RadioButtonsGroup labelTitle={'Would the decision-making tool help you in objective evaluation of your work?'} buttonsValuesArray={["No, it's not measurable", "Yes", "Likely", "Hell yes!"]} />
        <RadioButtonsGroup labelTitle={'Dou you have disputes with clients about project design?'} buttonsValuesArray={["Never", "Quiet often", "Sometimes", "Always"]} />
        <RadioButtonsGroup labelTitle={'Do you convince the client to your point?'} buttonsValuesArray={["Never, client is always right", "Sometimes, but it's difficult", "Yes, I don't want ugly projects in my portfolio", "It's either my way or the highway!"]} />
        <RadioButtonsGroup labelTitle={'Would you use a tool to provide you with statistical arguments about the superiority of one design over the other?'} buttonsValuesArray={["No", "Maybe", "Likely", "Hell yes!"]} />
        <div className='email-input-wrapper'>
          <h3>Subscribe to our newsletter</h3>
          <p className="info-survey-email">Email is not mandatory. If you will provide it however, we will grant you with extra bonus.</p>
          <FormInput
            name="email"
            type="email"
            label="Email"
            value={email}
            onChange={handleEmailChange}
            required={false}
          />
        </div>
        <p className="info-survey-email">Survey is anonymous, your email will not be associated with your answers. We'll send you only information about Comparere. Zero spam. Unsubscribe link will be provided in each and every message footer.</p>
        <div className='submit-button-wrapper'>
          <BtnCustom type="submit">Submit</BtnCustom>
        </div>
      </form>
    </>
  );
};

export default SurveyPage;
