import React, { Component } from "react";
import { connect } from 'react-redux'
import { signIn } from "../../redux/user/user.actions";
import { SignInDto } from "../../dto/sign-in-dto";
import Tile from "../../components/tile/tile.component";
import { RoutePath } from "../../route-paths";
import "./sign-in-form.styles.scss";
import { Link } from "react-router-dom";
import FormInputOval from "../input-forms/form-input-oval/form-input-oval.component";
import BtnCustomShadow from "../../components/btn-custom-shadow/btn-custom-shadow";

interface State {
  email: string;
  password: string;
}

interface Props {
  signIn: any;
}

class SignInForm extends Component<Props, State> {

  state = {
    email: process.env.REACT_APP_MOCK_USER_EMAIL as string,
    password: process.env.REACT_APP_MOCK_USER_PASSWORD as string
  };

  handleSubmit = async (e: any) => {
    e.preventDefault();
    const { email, password } = this.state;

    const signInDto: SignInDto = { email: email, password: password } as SignInDto;
    this.props.signIn(signInDto);
  };

  handleChange = (e: any) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value } as State);
  };

  render() {
    const { password } = this.state;
    return (
      <Tile>
        <h2 className="title">Sign In</h2>
        <form onSubmit={this.handleSubmit} className="signInForm">
          <FormInputOval
            name="email"
            type="email"
            label="Email"
            value={this.state.email}
            onChange={this.handleChange}
            required
          />
          <FormInputOval
            name="password"
            type="password"
            label="Password"
            value={password}
            onChange={this.handleChange}
            required
          />
          <BtnCustomShadow type="submit">Submit</BtnCustomShadow>
        </form>
        <div className="sign-in-form_footer">
          <p>Don't have an account yet? Create one right <span className="link"><Link to={RoutePath.SIGN_UP}>here!</Link></span></p>
          <p>Forgot password? Reset <span className="link"><Link to={RoutePath.RESET_PASSWORD}>here!</Link></span></p>
        </div>
      </Tile>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  signIn: (user: SignInDto) => dispatch(signIn(user))
})

export default connect(null, mapDispatchToProps)(SignInForm);
