import { createSelector } from 'reselect';
import { ComparerereStore } from '../store.types';

const userCompareresStore = (state: ComparerereStore) => state.userCompareresStore

export const selectUserCompareres = createSelector(
  [userCompareresStore],
  userCompareresStore => {
    return userCompareresStore.userCompareres
  }
);

export const selectComparereDetails = createSelector(
  [userCompareresStore],
  userCompareresStore => {
    return userCompareresStore.userComparereDetails
  }
);

export const selectUserComparereWithVotes = createSelector(
  [userCompareresStore],
  userCompareresStore => {
    return userCompareresStore.userComparere
  }
);