import React, { Component } from "react";
import "./user-comparere-tile.styles.scss";
import { UserComparereWithVotesDto } from "../../dto/user-compareres/user-comparere-with-votes-dto";
import Tile from "../tile/tile.component";
import polygonIcon from '../../Polygon.svg';
import optionsIcon from '../../resources/options_icon.svg';
import copyIcon from '../../resources/copy_icon.svg';

import { ApiRootPath } from "../../Api/ApiRootPath";
import CollapsibleMenu from "../collapsible-menu/collapsible-menu.component";
import Popup from "../popup/popup.component";
import FormInput from "../../forms/input-forms/form-input/form-input.component";
import { ToastsStore } from "react-toasts";
import BgImgLoader from "../bg-img-loader/bg-img-loader";
import { connect } from "react-redux";
import { deleteUserComparere } from "../../redux/user-compareres/user-compareres.actions";
import { Link } from "react-router-dom";
import BtnCustom from "../btn-custom/btn-custom.component";

interface Props {
  userComparere: UserComparereWithVotesDto;
  location?: any;
  deleteUserComparere: any;
}

interface State {
  isMenuOpen: boolean;
  isSharePopupOpen: boolean;
  isDeleteComparerePopupOpen: boolean;
}

class UserComparereTile extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      isMenuOpen: false,
      isSharePopupOpen: false,
      isDeleteComparerePopupOpen: false
    };
  }

  handleDeleteComparerePopupOpen = () => {
    this.setState({isDeleteComparerePopupOpen: true});
  }

  openMenu = () => {
    this.setState({ isMenuOpen: true })
  }

  handleClose = () => {
    this.setState({ isMenuOpen: false })
  }

  handleCancelDeletePopup = () => {
    this.setState({ isDeleteComparerePopupOpen: false });
  }

  handleCancelSharePopup = () => {
    this.setState({ isSharePopupOpen: false })
  }

  handleCopy = (event: any) => {
    this.handleFocus(event);
    document.execCommand('copy');
    ToastsStore.success('Your share url has been copied to clipboard.');
  }

  handleFocus = (event: any) => {
    event.target.parentElement.getElementsByTagName("input")[0].select();
  }

  
  render() {
    const { userComparere } = this.props;
    const { isSharePopupOpen, isMenuOpen, isDeleteComparerePopupOpen } = this.state;
    const totalVotes = userComparere.picturesWithVotes[0].votesCount + userComparere.picturesWithVotes[1].votesCount;

    let votesOnScale = totalVotes !== 0 ? Math.round(100 * userComparere.picturesWithVotes[0].votesCount / totalVotes) : 50;
    let votesOnDigit = totalVotes !== 0 ? Math.abs((votesOnScale * 2) - 100) : 0;

    const imgPathA = userComparere.picturesWithVotes[0].path.indexOf('https') >= 0 ?
      userComparere.picturesWithVotes[0].path : ApiRootPath.rootPath + userComparere.picturesWithVotes[0].path;
    const imgPathB = userComparere.picturesWithVotes[1].path.indexOf('https') >= 0 ?
      userComparere.picturesWithVotes[1].path : ApiRootPath.rootPath + userComparere.picturesWithVotes[1].path;

    return (
      <>
        <Popup title={"Share your secret link to Comparere"} isOpen={isSharePopupOpen} handleCancel={this.handleCancelSharePopup} hideFooter>
          <div className="popup-body shareInputWrapper">
            <div>You'll get feedback faster, but be carefoul! If you send it to anonymous spammers, their votes can falsify results.</div>
            <FormInput value={window.origin + "/uc=" + userComparere.publicId} readOnly={true} onFocus={this.handleFocus} icon={
              <img src={copyIcon} alt="copy" className="copyIcon" onClick={this.handleCopy} />
            } />
          </div>
        </Popup>
        <Popup title={'Are you sure you want to delete comparere?'} isOpen={isDeleteComparerePopupOpen} hideFooter handleCancel={this.handleCancelDeletePopup}>
            <BtnCustom style={{marginTop:`${24}px`}} onClick={ ()=> this.props.deleteUserComparere(userComparere.id)}> Delete </BtnCustom>
        </Popup>
        <Tile buttonInTopRight={
          <>
            <img src={optionsIcon} alt="options" onClick={this.openMenu} />
            <CollapsibleMenu isOpen={isMenuOpen} handleClose={this.handleClose}>
              <div className="link-wrapper user_comparere_link-wrapper">
                <div onClick={() => { this.setState({ isSharePopupOpen: true }) }}> Share </div>
              </div>
              <div className="link-wrapper user_comparere_link-wrapper">
                { userComparere.id ?
                  <div onClick={()=> this.handleDeleteComparerePopupOpen()}> Delete </div>
                : null
                } 
              </div>
              <div className="link-wrapper user_comparere_link-wrapper">
                { userComparere.id ?
                  <Link to={`/your-compareres/${userComparere.id}`}>Details</Link>
                : null
                } 
              </div>
            </CollapsibleMenu>
          </>
        }>
          <div>
            <div className="smallTitle">TOTAL VOTES:</div>
            <div className="votesCount">{totalVotes}</div>
            <div className="smallTitle">COMMUNITY CHOICE:</div>
            <div className="selector">
              <div className="selectorItem" style={{ left: (100 - votesOnScale) + "%" }}>
                <div>{votesOnDigit + "%"}</div>
                <img src={polygonIcon} alt="ratingPolygon" />
              </div>
            </div>
            <div className="pictures">
              <BgImgLoader attributes={{ className: "picture" }} src={imgPathA} />
              <div className="breakBar" />
              <BgImgLoader attributes={{ className: "picture" }} src={imgPathB} />
            </div>
          </div>
        </Tile>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  deleteUserComparere: (userComparereId: number)=> dispatch(deleteUserComparere(userComparereId))
})

export default connect(null, mapDispatchToProps)(UserComparereTile);
