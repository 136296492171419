import React, { useState } from "react";
import Fab from "../fab/fab";
import Popup from "../popup/popup.component";
import FinalizeSignupFormQuick from "../../forms/sign-up-forms/finalize-signup-form-quick/finalize-signup-form-quick";

const AlertFabWrapper: React.FC = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleCancel = () => {
    setIsPopupOpen(false);
  }

  return (
    <>
      <Popup isOpen={isPopupOpen} handleCancel={handleCancel} hideCancelButton>
        <FinalizeSignupFormQuick />
      </Popup>
      <Fab fabType='alert'>
        <div className="link-wrapper">
          <span onClick={() => { setIsPopupOpen(true) }}>GET BONUS - Finalize Sign Up</span>
        </div>
      </Fab>
    </>
  )
}

export default AlertFabWrapper;
