import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { Redirect, RouteComponentProps, Route } from "react-router";
import { UserRoleEnum } from "../../dto/user/user-role-enum";
import { refreshToken, updateRequestTime } from "../../redux/user/user.actions";

interface Props {
  component:
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>;
  path: string;
  user?: any;
  roles?: UserRoleEnum[];
  refreshToken: any;
  updateRequestTime: any;
}

interface State {
  isActivityTimeValid: boolean
}

class PrivateRoute extends React.Component<Props, State> {
  state = {
    isActivityTimeValid: false
  };

  hasUserExpectedRole = (): boolean => {
    if (
      this.props.roles &&
      this.props.roles.every(i => this.props.user.roles.includes(i))
    ) {
      return true;
    } else {
      return false;
    }
  };

  componentWillMount() {
    this.activityTimeIsValid();
  }

  activityTimeIsValid = (): void => {

    const user = this.props.user;

    if (this.props.user) {
      const token = user.token;
      let dateNow = new Date().getTime();

      let lastRequestTime = user.lastRequestTime;
      let expiresStart = token ? user.expiresStart : undefined;
      let expiresEnd = token ? user.expiresEnd : undefined;
      this.props.updateRequestTime(dateNow);

      if (expiresStart && expiresEnd) {
        if (dateNow < expiresStart!) {
          this.setState({ isActivityTimeValid: true })
        } else if (
          expiresStart <= dateNow &&
          dateNow <= expiresEnd &&
          (lastRequestTime && lastRequestTime <= expiresStart)
        ) {
          try {
            this.props.refreshToken();
          } catch (e) {
            this.setState({ isActivityTimeValid: false })
          }
        }
      }
    }
  };

  render() {
    const { isActivityTimeValid } = this.state;
    const { user, path } = this.props;
    return user &&
      user!.token &&
      this.hasUserExpectedRole() &&
      isActivityTimeValid ? (
        <Route exact path={path} {...this.props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signIn"
          }}
        />
      );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  refreshToken: () => dispatch(refreshToken()),
  updateRequestTime: (date: Date) => dispatch(updateRequestTime(date))
});

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
