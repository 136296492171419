import { UserComparereWithVotesDto } from "../dto/user-compareres/user-comparere-with-votes-dto";
import { PersistState } from "redux-persist";
import { RandomComparereDto } from "../dto/votes/random-comparere.dto";
import { ComparereWithVotesAndUserChoiceDto } from "../dto/comparere-with-votes-and-user-choice/comparete-with-votes-and-user-choice.ts.dto";
import { UserDto } from "../dto/user/user-dto";
import { UserComparereDetailsDto } from "../dto/user-compareres/user-comparere-details-dto";
import { UserProfileDto } from "../dto/account-settings/userProfile/user-profile-dto";
import { DesignerOfDayDto } from "../dto/designer/designer-of-day";

export type ActionResult<T> = {
  payload: T;
  type: string;
}

export const INITIAL_STORE = {} as ComparerereStore

export type ComparerereStore = {
  welcomePopup: {
    isPopupVisible: boolean,
    isPopupVisibleLosePoint: boolean
  };
  user: UserDto;
  userCompareresStore: {
    userCompareres: Array<UserComparereWithVotesDto>,
    userComparereDetails: UserComparereDetailsDto,
    userComparere: UserComparereWithVotesDto
  };
  votesCompareresStore: {
    comparerePairForComparison: RandomComparereDto,
    historyOfUserChoicesWithVotesWithCompareres: Array<ComparereWithVotesAndUserChoiceDto>,
    voteCounter: number
  };
  accountSettingsStore: {
    userProfile: UserProfileDto
  };
  homePageStore: HomePageStoreType;

  _persist: PersistState;
}

export type HomePageStoreType = {
  designerOfDay: DesignerOfDayDto
}