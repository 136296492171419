import WelcomePopupTypes from './welcome-popup.types';

const INITIAL_STATE = {
  isPopupVisible: true as boolean,
  isPopupVisibleLosePoint: true as boolean
}

const welcomePopupReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case WelcomePopupTypes.HIDE_POPUP:
      return {
        ...state,
        isPopupVisible: false
      }
    case WelcomePopupTypes.HIDE_POPUP_LOSEPOINT:
      return {
        ...state,
        isPopupVisibleLosePoint: false
      }
    default: {
      return state;
    }
  }
}

export default welcomePopupReducer;