import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { ApiPath } from "../../Api/ApiPath";
import { callApi, CallApiResponse } from "../../Api/api-call-service";
import { ApiMethodDto, UrlParam } from "../../Api/api-method-dto";
import { ToastsStore } from "react-toasts";
import UserCompareresTypes from "./user-compareres.types";
import { ActionResult, ComparerereStore } from "../store.types";
import { SagaInputDto } from "../../Api/saga-input-dto";
import { UserComparereWithVotesDto } from "../../dto/user-compareres/user-comparere-with-votes-dto";
import { ComparereDetailsTypeEnum } from "../../dto/user-compareres/comparere-details-type-enum";
import { UserComparereContextUpdateDto } from "../../dto/user-compareres/user-comparere-context-update-dto";

function* getUserCompareresAsync(actionResult: ActionResult<{ page: number | undefined }>) {
  const token = yield select(state => state.user.token);
  try {
    const options = {
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
      method: "GET",
      expectedStatus: [200, 204],
      params: actionResult.payload !== undefined && actionResult.payload.page !== undefined ? { name: "page", value: actionResult.payload.page } as UrlParam : undefined
    } as ApiMethodDto;

    const res: CallApiResponse = yield call(callApi, options.method, ApiPath.userCompareres_getUserCompareres, options);
    const currentUserCompareres: UserComparereWithVotesDto[] = yield select((state: ComparerereStore) => state.userCompareresStore.userCompareres);

    if (Object.entries(currentUserCompareres).length === 0 || currentUserCompareres.length === 0) {
      yield put({
        type: UserCompareresTypes.GET_USER_COMPARERES_SUCCESS,
        payload: res.data as UserComparereWithVotesDto
      });
    }
    else if (res.data && Object.keys(res.data).length !== 0 && res.data !== currentUserCompareres && currentUserCompareres.length > 0) {
      const ids = new Set(currentUserCompareres.map(item => item.id));
      const newUserCompareres = res.data.filter((item: UserComparereWithVotesDto) => !ids.has(item.id)).reverse();
      const merged = newUserCompareres ? currentUserCompareres.concat(newUserCompareres) : [...currentUserCompareres];

      yield put({
        type: UserCompareresTypes.GET_USER_COMPARERES_SUCCESS,
        payload: merged
      });
    }

  } catch (error) {
    if (error === 'TypeError: Failed to fetch') {
      ToastsStore.error("Sorry, service works are currently underway.")
      // TODO TRZEBA KONIECZNIE USTAWIĆ TU JAKĄŚ FLAGĘ, CZY COŚ, CO PRZEKIERUJE NAS NA PODSTRONĘ, Z INFORMACJĄ O PRACACH SERWISOWYCH I TRZYMA TAM, ZANIM BACKEND NIE ZOSTANIE PONOWNIE WŁĄCZNY.
    }
    console.error("Something went wrong.", error);
  }
}

function* deleteUserComparereAsync(comparereId: SagaInputDto<number>) {
  const token = yield select(state => state.user.token);

  try {
    const options = {
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
      method: "DELETE",
      expectedStatus: [200, 204],
      params: comparereId.payload !== undefined ? { name: "userComparereId", value: comparereId.payload } as UrlParam : undefined
    } as ApiMethodDto;

    yield call(callApi, options.method, ApiPath.userCompareres_deleteUserComparere, options);

    const currentUserCompareres: UserComparereWithVotesDto[] = yield select((state: ComparerereStore) => state.userCompareresStore.userCompareres);
    yield put({
      type: UserCompareresTypes.DELETE_USER_COMPARERE_SUCCESS,
      payload: currentUserCompareres.filter((userComparere: UserComparereWithVotesDto) => userComparere.id !== comparereId.payload)
    });

  } catch (error) {
    if (error === 'TypeError: Failed to fetch') {
      ToastsStore.error("Sorry, service works are currently underway.")
    }
    console.error("Something went wrong.", error);
  }
}

function* getComparereDetailsAsync(actionResult: ActionResult<{ userComparereId: number, comparereDetailsTypeEnum: ComparereDetailsTypeEnum }>) {
  const token = yield select(state => state.user.token);
  try {
    const options = {
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
      method: "GET",
      expectedStatus: [200, 204],
      params: actionResult.payload.userComparereId !== undefined && actionResult.payload.comparereDetailsTypeEnum !== undefined ?
        [{ name: "userComparereId", value: actionResult.payload.userComparereId } as UrlParam, { name: "comparereDetailsTypeEnum", value: actionResult.payload.comparereDetailsTypeEnum }] : undefined
    } as ApiMethodDto;

    const res = yield call(callApi, options.method, ApiPath.userCompareres_getComparereDetails, options);
    yield put({
      type: UserCompareresTypes.GET_COMPARERE_DETAILS_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    if (error === 'TypeError: Failed to fetch') {
      ToastsStore.error("Sorry, service works are currently underway.")
    }
    console.error("Something went wrong.", error);
  }
}

function* getUserComparereByIdAsync(comparereId: SagaInputDto<number>) {
  const token = yield select(state => state.user.token);
  try {
    const options = {
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
      method: "GET",
      expectedStatus: [200, 204],
      params: comparereId.payload !== undefined ? { name: "userComparereId", value: comparereId.payload } as UrlParam : undefined
    } as ApiMethodDto;

    const res = yield call(callApi, options.method, ApiPath.userCompareres_getUserComparereById, options);
    yield put({
      type: UserCompareresTypes.GET_USER_COMPARERE_BY_ID_SUCCESS,
      payload: res.data
    });

  } catch (error) {
    if (error === 'TypeError: Failed to fetch') {
      ToastsStore.error("Sorry, service works are currently underway.")
    }
    console.error("Something went wrong.", error);
  }
}

function* updateComparereContextAsync(comaprereContext: SagaInputDto<UserComparereContextUpdateDto>) {
  const token = yield select(state => state.user.token);
  try {
    const options = {
      body: comaprereContext.payload,
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*", "Content-Type": "application/json" },
      method: "POST",
      expectedStatus: 200,
    } as ApiMethodDto;

    yield call(callApi, options.method, ApiPath.userCompareres_updateComparereContext, options);
    ToastsStore.success('Your changes have been saved!');

  } catch (error) {
    if (error === 'TypeError: Failed to fetch') {
      ToastsStore.error("Sorry, service works are currently underway.")
    }
    console.error("Something went wrong.", error);
  }
}

function* updateComparerePrivacyAsync(comparereIsPrivate: SagaInputDto<{ comparereId: number, isPrivate: boolean }>) {
  const token = yield select(state => state.user.token);
  try {
    const options = {
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*", "Content-Type": "application/json" },
      method: "GET",
      expectedStatus: 200,
      params: [
        { name: 'isPrivate', value: comparereIsPrivate.payload.isPrivate } as UrlParam,
        { name: 'comparereId', value: comparereIsPrivate.payload.comparereId } as UrlParam,
      ]
    } as ApiMethodDto;

    yield call(callApi, options.method, ApiPath.userCompareres_updateComparerePrivacy, options);
    ToastsStore.success('Comparere privacy has been changed!');

  } catch (error) {
    if (error === 'TypeError: Failed to fetch') {
      ToastsStore.error("Sorry, service works are currently underway.")
    }
    console.error("Something went wrong.", error);
  }
}


function* watchGetUserCompareres() {
  yield takeLatest(UserCompareresTypes.GET_USER_COMPARERES, getUserCompareresAsync);
}

function* watchDeleteUserComparere() {
  yield takeLatest(UserCompareresTypes.DELETE_USER_COMPARERE, deleteUserComparereAsync);
}

function* watchGetComparereDetails() {
  yield takeLatest(UserCompareresTypes.GET_COMPARERE_DETAILS, getComparereDetailsAsync);
}

function* watchGetComparereById() {
  yield takeLatest(UserCompareresTypes.GET_USER_COMPARERE_BY_ID, getUserComparereByIdAsync);
}

function* watchUpdateComparereContextAsync() {
  yield takeLatest(UserCompareresTypes.UPDATE_COMPARERE_CONTEXT, updateComparereContextAsync);
}

function* watchUpdateComparerePrivacyAsync() {
  yield takeLatest(UserCompareresTypes.UPDATE_COMPARERE_PRIVACY, updateComparerePrivacyAsync);
}

export default function* userCompareresSagas() {
  yield all([call(watchGetUserCompareres), call(watchDeleteUserComparere), call(watchGetComparereDetails), call(watchGetComparereById), call(watchUpdateComparereContextAsync), call(watchUpdateComparerePrivacyAsync)]);
}
