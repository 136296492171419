import HomePageTypes from "./home-page.types";
import { DesignerOfDayDto } from "../../dto/designer/designer-of-day";
import { INITIAL_STORE } from "../store.types";

INITIAL_STORE.homePageStore = {
  designerOfDay: {} as DesignerOfDayDto
}
const homePageReducer = (state = INITIAL_STORE.homePageStore, action: any): typeof INITIAL_STORE.homePageStore => {
  switch (action.type) {
    case HomePageTypes.GET_DESIGNER_OF_THE_DAY_SUCCESS:
      return {
        ...state,
        designerOfDay: action.payload
      };
    default: {
      return state;
    }
  }
};

export default homePageReducer;
