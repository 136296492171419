import "./realize-promotion-code-form.scss"
import React, { Component } from "react";
import { connect } from 'react-redux'
import { realizePromotionCode } from "../../redux/points/points.actions";
import { ToastsStore } from 'react-toasts';
import { Popup } from "../../components/popup/popup.component";
import Tile from "../../components/tile/tile.component";
import BtnCustomShadow from "../../components/btn-custom-shadow/btn-custom-shadow";
import FormInputOval from "../input-forms/form-input-oval/form-input-oval.component";

interface State {
  promotionCode: string;
  isOpen: boolean;
}

interface Props {
  realizePromotionCode: any;
}

class RealizePromotionCodeForm extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      promotionCode: '',
      isOpen: false
    };
  }

  openPopup = (e: any) => {
    e.preventDefault()
    const { promotionCode } = this.state;

    if (promotionCode) {
      this.setState({ isOpen: true })
    }
    else {
      ToastsStore.warning('Please, enter promotion code.');
      return;
    }
  };

  handleConfirm = () => {
    this.setState({ isOpen: false })
    this.handleSubmit();
  }

  handleCancel = () => {
    this.setState({ isOpen: false })
  }

  handleSubmit = () => {
    const { promotionCode } = this.state;
    this.props.realizePromotionCode(promotionCode);
    this.setState({ promotionCode: '' })
  };

  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState({ [name]: value } as State);
  };

  render() {
    const { promotionCode, isOpen } = this.state;

    return (
      <>
        <Popup isOpen={isOpen} handleConfirm={this.handleConfirm} handleCancel={this.handleCancel}>
          <div className="popup-title">It will realize your promotion code!</div>
          <div className="popup-body">Are you sure?</div>
        </Popup>
        <Tile>
          <h2 className="title">Redeem Promotion Code</h2>
          <form onSubmit={this.openPopup} className="promCode">
            <FormInputOval
              name="promotionCode"
              type="text"
              label="Promotion Code"
              value={promotionCode}
              onChange={this.handleChange}
              required
            />
            <BtnCustomShadow type="submit">Redeem</BtnCustomShadow>

          </form>
        </Tile>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  realizePromotionCode: (promotionCode: string) => dispatch(realizePromotionCode(promotionCode))
})

export default connect(null, mapDispatchToProps)(RealizePromotionCodeForm);
