import "./navbar.styles.scss";
import logoUrl from "./../../logo.svg"
import userIcon from "./../../navIcons/user-icon.svg"
import addIcon from "./../../navIcons/add-icon.svg"
import React from "react";
import { connect } from "react-redux";
import { RoutePath } from "../../route-paths";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import CollapsibleMenu from "../collapsible-menu/collapsible-menu.component";
import { signOut } from "../../redux/user/user.actions";
import { Link } from "react-router-dom";
import { UserDto } from "../../dto/user/user-dto";
import { UserRoleEnum } from "../../dto/user/user-role-enum";
import { AestheticsIndicator } from "../aesthetics-indicator/aesthetics-indicator";

interface Props {
  user: UserDto;
  signOut: any;
  areButtonsDisabled: boolean;
}

interface State {
  isMenuOpen: boolean;
  pointsAnimation: string;
  userPoints?: number;
  isTop: boolean;
}

export class Navbar extends React.PureComponent<Props, State> {

  constructor(props: any) {
    super(props);

    this.state = {
      isMenuOpen: false,
      userPoints: undefined,
      pointsAnimation: "",
      isTop: true
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const { user } = this.props;

    if (nextProps.user && nextProps.user.points && user.points && nextProps.user.points > user.points && user.points != null) {
      this.setState({ pointsAnimation: "addPointsAnim" })
    }
    else if (nextProps.user && nextProps.user.points && user.points && nextProps.user.points < user.points && user.points != null) {
      this.setState({ pointsAnimation: "substrPointsAnim" })
    }
    if (nextProps.user.points !== user.points) {
      setTimeout(() => {
        this.setState({ userPoints: nextProps.user.points })
      }, 350);
      setTimeout(() => {
        this.setState({ pointsAnimation: "" })
      }, 1000);
    }

    if (nextProps.user && nextProps.user.points && user.points == null) {
      this.setState({ userPoints: nextProps.user.points })
    }
  }

  componentDidMount() {
    const { user } = this.props;
    this.setState({ userPoints: user.points })

    document.addEventListener('scroll', () => {
      this.setState({ isTop: window.scrollY < 100 })
    });
  }
  getUserName = (user: UserDto) => {
    if (user.roles.filter((x: UserRoleEnum) => x === UserRoleEnum.TempUser).length > 0) {
      return user.email.substring(0, user.email.lastIndexOf("@"))
    } else {
      return user.email;
    }
  }

  openMenu = () => {
    this.setState({ isMenuOpen: true })
  }

  handleClose = () => {
    this.setState({ isMenuOpen: false })
  }

  render() {
    const { user, areButtonsDisabled } = this.props;
    const { userPoints, pointsAnimation, isTop } = this.state;

    return (
      <div className={isTop ? "navbar" : "navbar showShadow"}>
        <div className="container">
          <div className="col col-left">
            <Link to={RoutePath.HOME_PAGE}>
              <img src={logoUrl} alt="logo" />
            </Link>
          </div>
          <div className="col col-middle">
            {user && user.token ?
              <AestheticsIndicator user={user} />
              : null
            }
          </div>

          {user && user.token ?
            <div className="col col-right">
              {userPoints || userPoints === 0 ?
                <div className="iconWrapper iconWrapper_points">
                  <div className="points-count-wrapper">
                    <Link to={RoutePath.POINTS_PAGE}>
                      <svg width="100%" height="100%" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M44.7446 22.6792C44.7446 34.8985 34.8389 44.8042 22.6196 44.8042C10.4003 44.8042 0.494629 34.8985 0.494629 22.6792C0.494629 10.4599 10.4003 0.554199 22.6196 0.554199C34.8389 0.554199 44.7446 10.4599 44.7446 22.6792Z" stroke="black" strokeWidth="0.75" vectorEffect="non-scaling-stroke" />
                        <text className={"points-wrapper" + pointsAnimation ? (" " + pointsAnimation) : ""} x="50%" y="51%" dominantBaseline="middle" textAnchor="middle" fill="black" style={{ whiteSpace: "pre" }} fontFamily="Montserrat" fontSize="15" fontWeight="300" letterSpacing="0em">
                          <tspan className="points-count">{userPoints}</tspan>
                        </text>
                      </svg>
                    </Link>
                  </div>
                </div>
                : null
              }
              <div className="iconWrapper">
                <Link to={RoutePath.ADD_COMPERERE}>
                  <img src={addIcon} alt="add" />
                </Link>
              </div>
              <div className="iconWrapper">
                <img src={userIcon} alt="menu" onClick={this.openMenu} />
                <CollapsibleMenu isOpen={this.state.isMenuOpen} handleClose={this.handleClose}>
                  <div className="user-details">{this.getUserName(user)}</div>
                  <div className="link-wrapper">
                    <Link to={RoutePath.USER_COMPARERES}>Compareres</Link>
                  </div>
                  <div className="link-wrapper">
                    <Link to={RoutePath.POINTS_PAGE}>Points</Link>
                  </div>
                  <div className="link-wrapper">
                    <Link to={RoutePath.ACCOUNT_SETTINGS}>Settings</Link>
                  </div>
                  <div className="link-wrapper">
                    <span onClick={this.props.signOut}>Sign Out</span>
                  </div>
                </CollapsibleMenu>
              </div>
            </div> :
            !areButtonsDisabled ?
              <div className="col col-right unautorized">
                <div className="aWrapper">
                  <Link to={RoutePath.SIGN_IN}>Sign In</Link>
                </div>
                <div className="break">or</div>
                <div className="aWrapper">
                  <Link to={RoutePath.SIGN_UP}>Sign Up</Link>
                </div>
              </div>
              :
              <div className="col col-right unautorized">
                <div className="aWrapper disabled">
                  <Link to={''} className=''>Sign In</Link>
                </div>
                <div className="break">or</div>
                <div className="aWrapper disabled">
                  <Link to={''} className=''>Sign Up</Link>
                </div>
              </div>
          }
        </div>
      </div >
    )
  }
}

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  signOut: () => dispatch(signOut())
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);