import { takeLatest, put, all, call, select } from "redux-saga/effects";
import UserActionTypes from "./user.types";
import { SignUpDto } from "../../dto/sign-up-dto";
import { ApiPath } from "../../Api/ApiPath";
import { SignInDto } from "../../dto/sign-in-dto";
import { callApi } from "../../Api/api-call-service";
import { ApiMethodDto } from "../../Api/api-method-dto";
import { ToastsStore } from "react-toasts";
import { UserDto } from "../../dto/user/user-dto";
import UserCompareresTypes from "../user-compareres/user-compareres.types";
import { ActionResult } from "../store.types";

function* attemptAuthorize(credentials: any) {
  const options = {
    body: credentials.payload as SignUpDto,
    headers: { "Content-Type": "application/json" },
    method: "POST",
    expectedStatus: 201
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "post", ApiPath.auth_signUp, options);

    yield put({
      type: UserActionTypes.SIGN_IN_SUCCESS,
      payload: {
        email: credentials.payload.email,
        roles: res.data.roles.map((item: any) => { return item.role }),
        token: "Bearer " + res.data.token.toString(),
        lastRequestTime: Number(Date.UTC),
        expiresStart: res.data.expiresStart,
        expiresEnd: res.data.expiresEnd
      } as UserDto
    });

    ToastsStore.success("The gift has been sent to your email.");
    yield put({ type: UserActionTypes.GET_POINTS });

  } catch (error) {
    yield put({ type: UserActionTypes.AUTH_FAILURE });
    console.error('Something went wrong. ', error)
  }
}

function* signUpTemp(actionResult: ActionResult<{ email: string }>) {
  const options = {
    headers: { "Content-Type": "application/json" },
    method: "GET",
    expectedStatus: 201,
    params: actionResult.payload !== undefined ? { name: "email", value: actionResult.payload } : undefined
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.auth_signUpTemp, options);

    // yield window.location.href = RoutePath.FINALIZESIGNUP;
    yield put({
      type: UserActionTypes.SIGN_IN_SUCCESS,
      payload: {
        email: res.data.email,
        roles: res.data.roles.map((item: any) => { return item.role }),
        token: "Bearer " + res.data.token.toString(),
        lastRequestTime: Number(Date.UTC),
        expiresStart: res.data.expiresStart,
        expiresEnd: res.data.expiresEnd
      } as UserDto
    });

    ToastsStore.success('Your account has been created.');
    yield put({ type: UserActionTypes.GET_POINTS });

  } catch (error) {
    yield put({ type: UserActionTypes.AUTH_FAILURE });
    console.error('Something went wrong. ', error)
  }
}

function* attemptGetPoints() {
  const token = yield select(state => state.user.token);

  const options = {
    headers: { Authorization: token, "Content-Type": "application/json" },
    method: "GET",
    expectedStatus: 200
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.auth_getUserInfoGetPoints, options);

    yield put({
      type: UserActionTypes.GET_POINTS_SUCCESS,
      payload: res.data
    });

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* attemptSignIn(credentials: any) {
  const options = {
    body: credentials.payload as SignInDto,
    headers: { "Content-Type": "application/json" },
    method: "POST",
    expectedStatus: 200
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "post", ApiPath.auth_signIn, options);

    yield put({
      type: UserActionTypes.SIGN_IN_SUCCESS,
      payload: {
        email: credentials.payload.email,
        roles: res.data.roles.map((item: any) => { return item.role }),
        token: "Bearer " + res.data.token.toString(),
        lastRequestTime: Date.UTC,
        expiresStart: res.data.expiresStart,
        expiresEnd: res.data.expiresEnd
      }
    });

    ToastsStore.success('Sign In Success');
    yield put({ type: UserActionTypes.GET_POINTS });

  } catch (error) {
    yield put({ type: UserActionTypes.SIGN_IN_FAILURE });
    console.error('Something went wrong. ', error)
  }
}

function* refreshToken() {
  const token = yield select(state => state.user.token);

  try {
    const options = {
      body: '',
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
      method: "GET",
      expectedStatus: 200
    } as ApiMethodDto;

    const res = yield call(callApi, "get", ApiPath.auth_refreshToken, options);

    yield put({
      type: UserActionTypes.TOKEN_REFRESH_SUCCESS,
      payload: { token: res.data.token }
    });
    yield put({ type: UserActionTypes.GET_POINTS });

  } catch (error) {
    yield put({ type: UserActionTypes.TOKEN_REFRESH_FAILURE, message: 'Something went wrong.' });
    console.error('Something went wrong. ', error)
  }
}

function* signOutAsync() {
  const token = yield select(state => state.user.token);

  try {
    const options = {
      body: null,
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
      method: "GET",
      expectedStatus: 200
    } as ApiMethodDto

    yield call(callApi, "get", ApiPath.auth_signOut, options);
    yield put({ type: UserCompareresTypes.CLEAR_USER_COMPARERES })
    yield put({ type: UserActionTypes.SIGN_OUT_SUCCESS });
    ToastsStore.success('Sign Out Success');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* watchTokenRefresh() {
  yield takeLatest(UserActionTypes.REFRESH_TOKEN as any, refreshToken);
}

function* watchSignUp() {
  yield takeLatest(UserActionTypes.AUTHORIZE as any, attemptAuthorize);
}

function* watchSignUpTemp() {
  yield takeLatest(UserActionTypes.AUTH_SIGNUPTEMP as any, signUpTemp);
}

function* watchSignIn() {
  yield takeLatest(UserActionTypes.SIGN_IN as any, attemptSignIn);
}

function* watchSignOut() {
  yield takeLatest(UserActionTypes.SIGN_OUT as any, signOutAsync);
}

function* watchGetPoints() {
  yield takeLatest(UserActionTypes.GET_POINTS as any, attemptGetPoints);
}

export default function* userSagas() {
  yield all([call(watchSignUp), call(watchSignUpTemp), call(watchSignIn), call(watchTokenRefresh), call(watchSignOut), call(watchGetPoints)]);
}
