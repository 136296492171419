import React from "react";
import "./page-title-bar.scss";

const PageTitleBar: React.FC = props => {
  return (
    <div className="pageTitleBar">
      <div className="header"> {props.children} </div>
    </div>
  )
}

export default PageTitleBar;
