import { createSelector } from 'reselect';
import { ComparerereStore } from '../store.types';

const accountSettingsStore = (state: ComparerereStore) => state.accountSettingsStore

export const selectAccountSettings = createSelector(
  [accountSettingsStore],
  accountSettingsStore => {
    return accountSettingsStore.userProfile}
  );
