import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { ApiPath } from "../../Api/ApiPath";
import { callApi } from "../../Api/api-call-service";
import { ApiMethodDto, UrlParam } from "../../Api/api-method-dto";
import { SagaInputDto } from "../../Api/saga-input-dto";
import { ToastsStore } from "react-toasts";
import PointsTypes from "./points.types";
import UserActionTypes from "../user/user.types";

function* RealizePromotionCodeAsync(sagaInput_promotionCode: SagaInputDto<string>) {
  const token = yield select(state => state.user.token);
  try {
    const options = {
      headers: { Authorization: token, AccessControlAllowOrigin: "*" },
      method: "GET",
      expectedStatus: 200,
      params: {
        name: 'publicId',
        value: sagaInput_promotionCode.payload
      } as UrlParam
    } as ApiMethodDto;

    yield call(callApi, options.method, ApiPath.points_realizePromotionCode, options);

    ToastsStore.success('Your promotion code has been realized.');
    yield put({ type: UserActionTypes.GET_POINTS });
  } catch (error) {
    console.error("Something went wrong.", error);
  }
}

function* watchRealizePromotionCode() {
  yield takeLatest(PointsTypes.REALIZE_PROMOTION_CODE, RealizePromotionCodeAsync);
}

export default function* pointsSagas() {
  yield all([call(watchRealizePromotionCode)]);
}
