import React, { useState } from 'react';
import './tabs.styles.scss';
import Tab from "./../../assets/tab.svg";

interface Props {
  steps: Array<string>;
  details: Array<JSX.Element>;
}

const Tabs = (props: Props) => {
  const [activeTab, setActiveTabState] = useState(0);
  const [slideAnimation, setSlideAnimation] = useState(false);
  const [hideAnimation, setHideAnimation] = useState(false);

  const { details } = props;

  const tabStyle = { transform: `translateX(${-25 + (activeTab * 50)}%)  ${slideAnimation ? 'translateY(15%)' : 'translateY(0%)'}` }

  const setActiveTab = (index: number) => {
    if (activeTab === index) return;
    setHideAnimation(true);
    setSlideAnimation(true);

    setTimeout(() => {
      setActiveTabState(index);
      setHideAnimation(false);
    }, 100);

    setTimeout(() => {
      setSlideAnimation(false);
    }, 200);
  }

  const calculatedClassName = (index: number): string => {
    let leftClass = (activeTab - index === 1 ? ' active-left' : '')
    let activeClass = (activeTab === index ? ' active' : '');
    let rightClass = (activeTab - index === -1 ? ' active-right' : '')

    return 'tab-title' + leftClass + activeClass + rightClass;
  }
  return (
    <div className={'tabs-container-wrapper'}>
      <div className='tabs-container'>
        {props.steps.map((step: string, index: number) => {
          return (
            <div key={index} className={calculatedClassName(index)} onClick={() => setActiveTab(index)}>{step}</div>
          )
        })}
        <div className={'undertab' + (slideAnimation ? ' slideDown' : '')}>
          <div className="tabTabWrapper">
            <div className="tabTab" style={{ backgroundImage: `url('${Tab}')`, ...tabStyle }}>
            </div>
          </div>
        </div>
        <div className={hideAnimation ? 'tab-description-container hideAnimation' : 'tab-description-container'} key={activeTab}>
          {
            details[activeTab]

          }
        </div>
      </div>
    </div>
  )
}

export default Tabs