import { createSelector } from 'reselect';
import { ComparerereStore } from '../store.types';

const votesCompareresStore = (state: ComparerereStore) => state.votesCompareresStore

export const selectComparerePairForComparison = createSelector(
  [votesCompareresStore],
  votesCompareresStore => votesCompareresStore.comparerePairForComparison
);

export const selectHistoryOfUserChoicesWithVotesWithCompareres = createSelector(
  [votesCompareresStore],
  votesCompareresStore => votesCompareresStore.historyOfUserChoicesWithVotesWithCompareres
);

export const selectVoteCounter = createSelector(
  [votesCompareresStore],
  votesCompareresStore => votesCompareresStore.voteCounter
);