import UserCompareresTypes from "./user-compareres.types";
import { UserComparereWithVotesDto } from "../../dto/user-compareres/user-comparere-with-votes-dto";
import { ComparereDetailsTypeEnum } from "../../dto/user-compareres/comparere-details-type-enum";
import { UserComparereContextUpdateDto } from "../../dto/user-compareres/user-comparere-context-update-dto";

export const getUserCompareres = (page: number) => ({
  type: UserCompareresTypes.GET_USER_COMPARERES,
  payload: { page }
});

export const getUserCompareresSuccess = (userCompareres: Array<UserComparereWithVotesDto>) => ({
  type: UserCompareresTypes.GET_USER_COMPARERES_SUCCESS,
  payload: userCompareres
});

export const deleteUserComparere = (userComparereId: number) => ({
  type: UserCompareresTypes.DELETE_USER_COMPARERE,
  payload: userComparereId
})

export const deleteUserComparereSuccess = (userComparereId: number) => ({
  type: UserCompareresTypes.DELETE_USER_COMPARERE_SUCCESS,
  payload: userComparereId
})

export const getComparereDetails = (userComparereId: number, comparereDetailsTypeEnum: ComparereDetailsTypeEnum) => ({
  type: UserCompareresTypes.GET_COMPARERE_DETAILS,
  payload: { userComparereId, comparereDetailsTypeEnum }
})

export const getUserComparereById = (userComparereId: number) => ({
  type: UserCompareresTypes.GET_USER_COMPARERE_BY_ID,
  payload: userComparereId 
})

export const clearUserComparereDetails = () => ({
  type: UserCompareresTypes.CLEAR_USER_COMPARERE_DETAILS,
})

export const clearUserComparerers = () => ({
  type: UserCompareresTypes.CLEAR_USER_COMPARERES,
})

export const updateComparereContext = (comparereContext: UserComparereContextUpdateDto) => {
  return (
    {
      type: UserCompareresTypes.UPDATE_COMPARERE_CONTEXT,
      payload: comparereContext
    }
  )
}

export const updateComparerePrivacy = (comparereId: number, isPrivate: boolean) => {
  return (
    {
      type: UserCompareresTypes.UPDATE_COMPARERE_PRIVACY,
      payload: {comparereId: comparereId, isPrivate: isPrivate}
    }
  )
}
