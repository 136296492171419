import React from 'react';
import PageTitleBar from '../../components/text-components/page-title-bar/page-title-bar';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../route-paths';

export const UserAgreementPage = () => {
  return (
    <div className='cookies-policy-page'>
      <PageTitleBar>Comparere User Agreement</PageTitleBar>
      <div className="container">
        <h5>Last update: 1 October, 2019</h5>
        <br />
        <br />
        <p>
          Hello, visitors! This Comparere User Agreement ("Terms") applies to your access to and use of the comparere.com website and other services and products it ("Comparere," "we," or "us") provides.
        </p>
        <p>
          By accessing or/and using our services you agree to abide by them (Terms) and also our Policys: <Link to={RoutePath.PRIVACY_POLICY}>Privacy Policy</Link>, <Link to={RoutePath.CONTENT_POLICY}>Content Policy</Link> and <Link to={RoutePath.COOKIES_POLICY}>Cookies Policy</Link>. If you do not agree to these Terms or Policies you should not access or use our services.
        </p>
        <p>
          Please take a look at Comparere's <Link to={RoutePath.PRIVACY_POLICY}>Privacy Policy</Link> too—it explains how we collect, use, and share information about you when you access or use our Services.
        </p>
        <h3>1. Access to the services</h3>
        <p>Account creation and services usage is not allowed for children under the age of 13. Additionally, if you are in the European Economic Area, you must be over the age required by the laws of your country to create an account or otherwise use the Services, or we need to have received verifiable consent from your parent or legal guardian.
          In addition, certain of our Services or portions of our Services require you to be older than 13 years of age, so please read all notices and any additional terms carefully when you access the Services.
          If you are accepting these Terms on behalf of another legal entity, including a business or a government, you represent that you have full legal authority to bind such entity to these terms.
        </p>
        <h3>2. Use of the Services</h3>
        <p>
          Comparere grants you a personal, non-transferable, non-exclusive, revocable, limited license to use and access the Services solely as permitted by these Terms. We reserve all rights not expressly granted to you by these Terms.
          Except as permitted through the Services or as otherwise permitted by us in writing, your license does not include the right to:
        </p>
        <ul>
          <li>license, sell, transfer, assign, distribute, host, or otherwise commercially exploit the Services or Content</li>
          <li>modify, prepare derivative works of, disassemble, decompile, or reverse engineer any part of the Services or Content</li>
          <li>access the Services or Content in order to build a similar or competitive website, product, or service.</li>
        </ul>
        <p>
          We reserve the right to modify, suspend, or discontinue the Services (in whole or in part) at any time, with or without notice to you.
          Any future release, update, or other addition to functionality of the Services will be subject to these Terms, which may be updated from time to time.
          You agree that we will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Services or any part thereof.
        </p>
        <h3>3. Comparere Account and Account Security</h3>
        <p>To use certain features of our Services, you may be required to create a Comparere account (an "Account") and provide us with a username, password, and certain other information about yourself as set forth in the <Link to={RoutePath.PRIVACY_POLICY}>Privacy Policy</Link>.</p>
        <p>You are solely responsible for the information associated with Your Account and anything that happens related to Your Account. You must maintain the security of your Account and promptly notify Comparere if you discover or suspect that someone has accessed your Account without your permission. We recommend that you use a strong password that is used only with the Services.</p>
        <p>You will not license, sell, or transfer your Account without our prior written approval.</p>
        <h3>4. Your Content</h3>
        <p>
          There may be following content in Services: information, text, links, photos, graphics, or other materials, including materials created with or submitted to the Services by you or through your Account, which is your content. We do not take responsibility for and we do not expressly or implicitly endorse any of Your Content. You alone are responsible for Your Content, so if you share or post Content to which you have not all necessary rights, you may expose yourself to liability. By submitting Your Content to the Services, you warrant that you have all rights and authority necessary to grant the rights to Your Content contained within these Terms.
        </p>
        <p>You retain any ownership rights you have in Your Content, but you grant Comparere the following license to use that Content:</p>
        <p>
          When Your Content is created with or submitted to the Services, you grant us a worldwide, royalty-free, perpetual, irrevocable, non-exclusive, transferable, and sublicensable license to use, copy, modify, adapt, prepare derivative works from, distribute, perform, and display Your Content and any name, username, voice, or likeness provided in connection with Your Content in all media formats and channels now known or later developed. This license includes the right for us to make Your Content available for syndication, broadcast, distribution, or publication by other companies, organizations, or individuals who partner with Comparere. You also agree that we may remove metadata associated with Your Content, and you irrevocably waive any claims and assertions of moral rights or attribution with respect to Your Content.
        </p>
        <p>
          Any ideas, suggestions, and feedback about Comparere or our Services that you provide to us are entirely voluntary, and you agree that Comparere may use such ideas, suggestions, and feedback without compensation or obligation to you.
        </p>
        <p>
          Although we have no obligation to screen, edit, or monitor Your Content, we may, in our sole discretion, delete or remove Your Content at any time and for any reason, including for a violation of these Terms, a violation of our Content Policy, or if you otherwise create liability for us.
        </p>
        <p>For more informations, please visit our <Link to={RoutePath.CONTENT_POLICY}>Content Policy</Link>.</p>

        <h3>5. Termination</h3>
        <p>
          We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
        </p>
        {/* <h3>5. Virtual Goods and Payment Information</h3>
        <p>There are no fees for use of many aspects of the Services. However, Virtual Goods which include Extra Points, may be available for purchase.</p>
        <p>Comparere may change the fees or benefits associated with the Virtual Goods from time to time with reasonable advance notice; provided, however, that no advance notice will be required for temporary promotions, including temporary reductions in the fees associated with the Virtual Goods.</p>
        <p>You may submit your debit card, credit card, or other payment information ("Payment Information") via our Services to purchase premium features or other paid products or services. We use third-party service providers to process your Payment Information. If you submit your Payment Information, you agree to pay all costs that you incur, and you give us permission to charge you when payment is due for an amount that includes these costs and any applicable taxes and fees.</p>
        <h3>Termination</h3>
        <p>You may terminate these Terms at any time and for any reason by deleting your Account and discontinuing your use of all Services. If you stop using the Services without deactivating your Accounts, your Accounts may be deactivated due to prolonged inactivity.</p>
        <p>We may suspend or terminate your Accounts, status as a moderator, or ability to access or use the Services at any time for any or no reason, including for a violation of these Terms or our <Link to={RoutePath.CONTENT_POLICY}>Content Policy</Link>.</p> */}
      </div>
    </div>
  )
}