import React from "react";
import Fab from "../fab/fab";
import { Link } from "react-router-dom";
import { RoutePath } from "../../route-paths";

const InfoFabWrapper: React.FC = props => {
  return (
    <Fab fabType="info">
      <div className="link-wrapper">
        <Link to={RoutePath.CONTACT}>Contact</Link>
      </div>
      <div className="link-wrapper">
        <Link to={RoutePath.CONTENT_POLICY}>Content policy</Link>
      </div>
      <div className="link-wrapper">
        <Link to={RoutePath.COOKIES_POLICY}>Cookies policy</Link>
      </div>
      <div className="link-wrapper">
        <Link to={RoutePath.PRIVACY_POLICY}>Privacy policy</Link>
      </div>
      <div className="link-wrapper">
        <Link to={RoutePath.USER_AGREEMENT}>User Agreement</Link>
      </div>
      <div className="link-wrapper">
        <Link to={RoutePath.FAQ}>FAQ</Link>
      </div>
      {/* <div className="link-wrapper">
        <Link to={RoutePath.USER_AGREEMENT}>User agreement</Link>
      </div> */}
    </Fab>
  )
}

export default InfoFabWrapper;
