import { all, call } from 'redux-saga/effects';

import userSagas from './user/user.sagas';
import accountSettingsSagas from './account-settings/account-settings.sagas';
import userCompareresSagas from './user-compareres/user-compareres.sagas';
import votesCompareresSagas from './votes-compareres/votes-compareres.sagas';
import pointsSagas from './points/points.sagas';
import surveySagas from './survey/survey.sagas';
import homePageSagas from './home-page/home-page.sagas';


export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(userCompareresSagas),
    call(accountSettingsSagas),
    call(votesCompareresSagas),
    call(pointsSagas),
    call(surveySagas),
    call(homePageSagas)
  ]);
}