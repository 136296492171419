import UserCompareresTypes from './user-compareres.types';
import { UserComparereWithVotesDto } from '../../dto/user-compareres/user-comparere-with-votes-dto';
import { UserComparereDetailsDto } from '../../dto/user-compareres/user-comparere-details-dto';

const INITIAL_STATE = {
  userComparere: {} as UserComparereWithVotesDto,
  userCompareres: [] as Array<UserComparereWithVotesDto> | any,
  newComparere: [] as Array<File>,
  userComparereDetails: {} as UserComparereDetailsDto,
  spaghettiCodeCallback: {
    isNewComparereUploadedSuccessfully: false
  }
}

const userCompareresReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case UserCompareresTypes.GET_USER_COMPARERES_SUCCESS:
      return {
        ...state,
        userCompareres: action.payload
      }
    case UserCompareresTypes.DELETE_USER_COMPARERE_SUCCESS:
      return { 
        ...state,
        userCompareres: action.payload
      }
    case UserCompareresTypes.GET_COMPARERE_DETAILS_SUCCESS:
      return {
        ...state,
        userComparereDetails: action.payload
      }
    case UserCompareresTypes.GET_USER_COMPARERE_BY_ID_SUCCESS:
      return {
        ...state,
        userComparere: action.payload
      }
    case UserCompareresTypes.CLEAR_USER_COMPARERE_DETAILS:
      return {
        ...state,
        userComparere: {}
      }  
    case UserCompareresTypes.CLEAR_USER_COMPARERES:
      return {
        ...state,
        userCompareres: {}
      }

    default: {
      return state;
    }
  }
}

export default userCompareresReducer;