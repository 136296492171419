const AccountSettingsTypes = {
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  FINALIZE_SIGNUP: 'FINALIZE_SIGNUP',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  RESET_PASSWORD: 'RESET_PASSWORD',
  SEND_CONTACT_EMAIL: 'SEND_CONTACT_EMAIL',
  CHANGE_NOTIFICATIONS: 'CHANGE_NOTIFICATIONS',
  CHANGE_USERDETAILS: 'CHANGE_USERDETAILS',
  ADD_SOCIAL_MEDIA_LINK: 'ADD_SOCIAL_MEDIA_LINK',
  ADD_SOCIAL_MEDIA_LINK_SUCCESS: 'ADD_SOCIAL_MEDIA_LINK_SUCCESS',
  GET_USER_PROFILE: 'GET_USER_PROFILE',
  DELETE_SOCIAL_MEDIA_LINK: 'DELETE_SOCIAL_MEDIA_LINK',
  UPDATE_USER_LOGIN: 'UPDATE_USER_LOGIN'
};

export default AccountSettingsTypes;