import React, { Component } from "react";
import { connect } from 'react-redux'
import Tile from "../../components/tile/tile.component";
import { NewsletterSettingsDto } from "../../dto/account-settings/newsletter-settings-dto";
import { changeNotifications } from "../../redux/account-settings/account-settings.actions";
import { AccountSettingsController } from "../../Api/requests/account-settings";
import CheckboxShadow from "../../components/checkbox-shadow/checkbox-shadow";
import BtnCustomShadow from "../../components/btn-custom-shadow/btn-custom-shadow";

interface State {
  sendInformationsAboutComparereUpdates: boolean,
  sendInformationsAboutNewCompareres: boolean,
  sendInformationsAboutCodeeboTeamOtherProjects: boolean
}

interface Props {
  changeNotifications?: any;
}

class ChangeNotificationsForm extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      sendInformationsAboutComparereUpdates: false,
      sendInformationsAboutNewCompareres: false,
      sendInformationsAboutCodeeboTeamOtherProjects: false
    };
  }

  componentDidMount() {
    AccountSettingsController.GetNotificationsSettings().then((res) => {
      this.setState({
        sendInformationsAboutComparereUpdates: res.sendInformationsAboutComparereUpdates,
        sendInformationsAboutNewCompareres: res.sendInformationsAboutNewCompareres,
        sendInformationsAboutCodeeboTeamOtherProjects: res.sendInformationsAboutCodeeboTeamOtherProjects
      });
    })
  }

  handleSubmit = async (e: any) => {
    e.preventDefault();
    const { sendInformationsAboutComparereUpdates,
      sendInformationsAboutNewCompareres,
      sendInformationsAboutCodeeboTeamOtherProjects } = this.state;

    const newsletterSettings = {
      sendInformationsAboutComparereUpdates: sendInformationsAboutComparereUpdates,
      sendInformationsAboutNewCompareres: sendInformationsAboutNewCompareres,
      pushBrowserNotifications: false,
      sendInformationsAboutCodeeboTeamOtherProjects: sendInformationsAboutCodeeboTeamOtherProjects
    } as NewsletterSettingsDto;

    this.props.changeNotifications(newsletterSettings);
  };

  handleChange = (e: any) => {
    const { name } = e.target;
    this.setState({ [name]: e.target.checked } as State);
  };

  render() {
    return (
      <Tile>
        <h2 className="title">Notifications Settings</h2>
        <form onSubmit={this.handleSubmit}>
          <CheckboxShadow
            id={'sendInformationsAboutNewCompareres'}
            name={'sendInformationsAboutNewCompareres'}
            label='Emails about new compareres'
            onChange={this.handleChange}
            defaultValue={this.state.sendInformationsAboutNewCompareres}
            required={false}
          />
          {/* <Checkbox
            id={'newsletter'}
            name={'newsletter'}
            label='Browser notifications'
            onChange={this.handleChange}
            required={false}
          /> */}
          <CheckboxShadow
            id={'sendInformationsAboutComparereUpdates'}
            name={'sendInformationsAboutComparereUpdates'}
            label='Informations about updates'
            onChange={this.handleChange}
            defaultValue={this.state.sendInformationsAboutComparereUpdates}
            required={false}
          />
          <CheckboxShadow
            id={'sendInformationsAboutCodeeboTeamOtherProjects'}
            name={'sendInformationsAboutCodeeboTeamOtherProjects'}
            defaultValue={this.state.sendInformationsAboutCodeeboTeamOtherProjects}
            label='Other things developed by Comparere Team.'
            onChange={this.handleChange}
            required={false}
          />
          <BtnCustomShadow type="submit">Send</BtnCustomShadow>
        </form>
      </Tile>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  changeNotifications: (newsletterSettings: NewsletterSettingsDto) => dispatch(changeNotifications(newsletterSettings))
})

export default connect(null, mapDispatchToProps)(ChangeNotificationsForm);