import React, { Component } from "react";
import { connect } from 'react-redux'
import { Popup } from "../../components/popup/popup.component";
import { DeleteAccountDto } from "../../dto/account-settings/delete-account-dto";
import { deleteAccount } from "../../redux/account-settings/account-settings.actions";
import Tile from "../../components/tile/tile.component";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { UserDto } from "../../dto/user/user-dto";
import FormInputOval from "../input-forms/form-input-oval/form-input-oval.component";
import BtnCustomShadow from "../../components/btn-custom-shadow/btn-custom-shadow";
import CheckboxShadow from "../../components/checkbox-shadow/checkbox-shadow";

interface State {
  confirmDelete: boolean;
  password: string;
  isOpen: boolean;
}

interface Props {
  deleteAccount: any;
  user: UserDto;
}

class DeleteAccountForm extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      confirmDelete: false,
      password: '',
      isOpen: false
    };
  }

  openPopup = (e: any) => {
    e.preventDefault()
    const { confirmDelete, password } = this.state;

    if (!(confirmDelete && password)) {
      return;
    }

    if (!confirmDelete) {
      return;
    }

    this.setState({ isOpen: true })
  };

  handleConfirm = () => {
    this.setState({ isOpen: false })
    this.handleSubmit();
  }

  handleCancel = () => {
    this.setState({ isOpen: false })
  }

  handleSubmit = async () => {
    const { password } = this.state;
    const deleteAccountDto: DeleteAccountDto = { password: password }
    this.props.deleteAccount(deleteAccountDto);
  };

  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState({ [name]: value } as State);
  };

  render() {
    const { password, isOpen } = this.state;
    return (
      <>
        <Popup isOpen={isOpen} handleConfirm={this.handleConfirm} handleCancel={this.handleCancel}>
          <div className="popup-title">It will delete your account forever!</div>
          <div className="popup-body">Are you sure?</div>
        </Popup>
        <Tile>
          <h2 className="title">Delete account</h2>
          <form onSubmit={this.openPopup}>
            <CheckboxShadow
              onChange={this.handleChange}
              label='Yes i want to delete my account'
              name='confirmDelete'
              id='confirmDelete'
              required={true}
            />
            <FormInputOval
              name="password"
              type="password"
              label="Password"
              value={password}
              onChange={this.handleChange}
              required={true}
            />
            <BtnCustomShadow type="submit">Confirm</BtnCustomShadow>
          </form>
        </Tile>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  deleteAccount: (deleteAccountDto: DeleteAccountDto) => dispatch(deleteAccount(deleteAccountDto))
})

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountForm);