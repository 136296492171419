import React, { useEffect, useState } from 'react'
import './../sign-up.scss';

import signUpDataImg from "../../../assets/signup/signup_data.svg"
import dataInfo from "../../../assets/signup/data_info.svg"

import CompleteYourProfileForm from "../../../forms/sign-up-forms/sign-up-completeProfile-form/sign-up-completeProfile-form"
import { AccountSettingsController } from '../../../Api/requests/account-settings';
import { SexTypeEnum } from '../../../dto/account-settings/sex-type-enum';
import { Redirect } from 'react-router';
import { RoutePath } from '../../../route-paths';
import { Popup } from '../../../components/popup/popup.component';

const CompleteYourProfilePage = () => {

  const [userDetails, setUserDetails] = useState({ yearOfBirth: 0, sexTypeEnum: undefined } as { yearOfBirth: number, sexTypeEnum?: string });
  const [isPopupOpen, setIsPopupOpen] = useState(false);


  useEffect(() => {
    AccountSettingsController.GetUserDetails().then((res) => {
      setUserDetails({
        yearOfBirth: res.yearOfBirth,
        sexTypeEnum: SexTypeEnum[res.sexTypeEnum]
      });
    })
  }, [])

  if (userDetails.yearOfBirth !== 0 || userDetails.sexTypeEnum !== undefined) return <Redirect to={RoutePath.HOME_PAGE} />
  return (
    <div className='sign-up-page container'>
      <Popup isOpen={isPopupOpen} handleCancel={() => setIsPopupOpen(false)}>

        <div className="row">
          <div className="col50">
            <div className="popup-title">Why we collect the informations about sex and age?</div>
            <div className="popup-body">
              We use them to create graphs showing votes distribution. Pozwala to designerom, badać swoje prace, pod kątem konkretnego zastosowania np. dla danej grupy społecznej. Te dane nie są wymagane, to pola opcjonalne
          </div>
          </div>
          <div className="col50">
            <img src={dataInfo} alt="data info" />
          </div>
        </div>
      </Popup>
      <div className='col50 left'>
        <div className="description">
          <h3>Complete your profile</h3>
          <p className="short-info">
            We’ll use this informations, to present votes distribution by sex and age. <br /> It will make your votes more valuable (<strong onClick={() => setIsPopupOpen(true)}>more</strong>).<br />
            Each field gives you +10 points. <br />
            <span>
              Or click “skip” to do it later
            </span>
          </p>
        </div>
        <CompleteYourProfileForm />
      </div>
      <div className="col50 right">
        <img src={signUpDataImg} alt="Sign Up" />
      </div>
    </div>
  )
}

export default CompleteYourProfilePage;