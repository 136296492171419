import React, { ReactChild } from 'react';
import './accordion-item.scss';
import Arrow from './../../../assets/arrow.svg';

interface Props {
  title: string;
  children: ReactChild;
  expand: boolean;
  onClick: any; 
}

const AccordionItem = (props: Props | any) => {
  const { title, expand, children, onClick } = props;
  return (
    <div className='accordion-item'>
      <div className='wrapper' onClick={onClick}>
        <div className={expand ? 'title is-expanded' : 'title'} >
          {title}
        </div>
        <div className={'arrow-wrapper'}>
          <img className={expand ? 'arrow is-expanded' : 'arrow' } src={Arrow} alt="arrow"/>
        </div>
      </div>
      <div
        className={expand ? "content is-expanded" : "content"}
      >
        <div>
        {children}
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;
