import { store } from "../../redux/store";
import { ToastsStore } from "react-toasts";
import { callApi } from "../api-call-service";
import { ApiPath } from "../ApiPath";
import { ApiMethodDto } from "../api-method-dto";
import { AddReportDto } from "../../dto/report/add-report-dto";

export class reportController {
  static async addNewReportAsync(addReport: AddReportDto): Promise<void> {
    return new Promise(async (resolve) => {
      const token = store.getState().user.token
      const options = {
        body: addReport,
        datatype: "Json",
        headers: { Authorization: token, "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
        method: "POST",
        expectedStatus: 200
      } as ApiMethodDto;

      await callApi(options.method, ApiPath.report_addNewReport, options)
        .then((res) => {
          if (res.details.status === options.expectedStatus) {
            ToastsStore.success('Your report has been send.');
            resolve();
          }
        })
    });
  }
}