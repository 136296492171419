import './vote-comparere.styles.scss'
import React, { Component } from 'react';
import { RandomComparereDto } from '../../dto/votes/random-comparere.dto';
import { getComparerePairForComparison, addVote, resetVoteCounter } from '../../redux/votes-compareres/votes-compareres.actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ComparerereStore } from '../../redux/store.types';
import { selectComparerePairForComparison, selectVoteCounter } from '../../redux/votes-compareres/votes-compareres.selectors';
import { AddVoteDto } from '../../dto/votes/add-vote.dto';
import Lenny from "../../assets/lenny.png"
import VoteScaler from '../comparere-vote/vote-scaler/vote-scaler';
import { RoutePath } from '../../route-paths';
import Popup from '../popup/popup.component';
import ReportForm from '../../forms/report-form/report-form.component';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import ReportIcon from "../../assets/report-icon.svg";
import BtnCustom from '../btn-custom/btn-custom.component';
import { History } from 'history';
import { Link } from 'react-router-dom';
import ComparereAbout from '../comparere-about/comparere-about.component';

interface Props {
  getComparerePairForComparison: any;
  resetVoteCounter: any;
  addVote: any;
  uc?: string;
  history?: History;
}

interface ConnectedProps {
  voteCounter: number;
  comparerePairForComparison: RandomComparereDto;
  user?: any;
}

interface State {
  isReportPopupOpen: boolean;
  isVoteComparereLimitPopupOpen: boolean;
  isVoted: boolean;
  comparereAboutIsOpen: boolean
}

class VoteForComparere extends Component<Props & ConnectedProps, State> {
  state = {
    isReportPopupOpen: false,
    isVoteComparereLimitPopupOpen: false,
    isVoted: false,
    comparereAboutIsOpen: false
  };

  componentDidMount() {
    this.props.getComparerePairForComparison(this.props.uc);
  }

  reDownload = () => {
    this.props.getComparerePairForComparison();
  }

  vote = (voteDto: AddVoteDto) => {
    const { uc, user } = this.props;
    this.props.addVote(voteDto, uc);
    if (user && user.token) {
      this.setState({ isVoted: true })
    }

    if (uc) {
      this.props.history!.push(RoutePath.HOME_PAGE);
    }
  }

  handleCloseReportPupupAndGetNewComparere = () => {
    this.props.getComparerePairForComparison();
    this.setState({ isReportPopupOpen: false })
  }

  handleCloseReportPupup = () => {
    this.setState({ isReportPopupOpen: false })
  }

  handleCancelVoteComparereLimitPopup = () => {
    this.setState({ isVoteComparereLimitPopupOpen: false })
  }

  handleNavigateToSignIn = () => {
    this.props.resetVoteCounter();
    this.props.history!.push(RoutePath.SIGN_UP);
  }

  handleComparereAboutOpen = () => {
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    if (nextProps.voteCounter === 3) {
      this.setState({ isVoteComparereLimitPopupOpen: true })
    }
  }

  componentWillUnmount() {
    this.props.resetVoteCounter();
  }

  render() {
    const { comparerePairForComparison, user } = this.props;
    const { isReportPopupOpen, isVoteComparereLimitPopupOpen } = this.state;

    return (
      <>
        {comparerePairForComparison.id ?
          <div className='vote-page-wrapper'>
            {comparerePairForComparison.id === -1 ?
              <div className="no-content">
                <img width="100px" src={Lenny} alt="Lenny" />
                <br />
                <br />
                There are currently no new Compareres to compare. Please try later or add something yourself and become an active member of the community!
              </div>
              : (comparerePairForComparison && comparerePairForComparison.id === -2) ?
                <div className="no-content">
                  <img width="100px" src={Lenny} alt="Lenny" />
                  <br />
                  <br />
                  Voting for your own comparere does not make sense. Please, return to the <Link to={RoutePath.HOME_PAGE}><strong onClick={this.reDownload}>home page</strong></Link>.
              </div> :
                (user && user.points < 1) && user.points !== null ? <div className="no-content">
                  <img width="100px" src={Lenny} alt="Lenny" />
                  <br />
                  <br />
                  You have not enough points, to vote. <br />
                  Please wait for our daily air drop of points for users with balance below 10. If you can't wait, please go to <Link to={RoutePath.POINTS_PAGE}><strong>points</strong></Link> section and buy extra package.
              </div> :
                  <>
                    <Popup isOpen={isReportPopupOpen} handleCancel={this.handleCloseReportPupup} hideFooter>
                      <div className="popup-title">Report Comparere</div>
                      <div className="popup-body">
                        <div >The Comparere team makes every effort to search for and block materials that do not comply with the regulations or that have bad associations. However, we cannot trace everything that users share, if we have missed something, please let us know.</div>
                        <ReportForm comparereId={comparerePairForComparison.id} handleClosePopup={this.handleCloseReportPupupAndGetNewComparere} />
                      </div>
                    </Popup>
                    <Popup isOpen={isVoteComparereLimitPopupOpen} handleCancel={this.handleCancelVoteComparereLimitPopup} hideFooter={true}>
                      <div className="popup-title">Please Sign Up</div>
                      <div className="popup-body please-sing-up">
                        <p>You'll have access to:</p>
                        <ul>
                          <li>Community voting results</li>
                          <li>Adding graphics</li>
                          <li>Votes history</li>
                          <li>Sharing Compareres</li>
                          <li>Points collecting system</li>
                        </ul>
                        <p>
                          There is <strong>1 Click Sign Up</strong> on our Sign Up page! <br />
                        </p>
                        <br />
                        <br />
                        <BtnCustom onClick={(this.handleNavigateToSignIn)}>Sign Up Page</BtnCustom>
                      </div>
                    </Popup>
                    {user && user.token ? <img className="reportIcon" src={ReportIcon} alt="report" onClick={() => { this.setState({ isReportPopupOpen: true }) }} /> : null}
                    <ComparereAbout about={comparerePairForComparison.contextData} comparereAboutOpen={this.handleComparereAboutOpen} />
                    <VoteScaler
                      comparerePairForComparison={this.props.comparerePairForComparison}
                      vote={this.vote}
                    />
                    {/* {user && user.token && isVoted ? <WelcomePopupLosePoints /> : null} */}
                  </>
            }
          </div>
          : <div className='vote-page-wrapper'><div className="serviceUnavailable"> Connecting to the server... Please wait... </div></div>}
      </>
    )
  }
}

const mapStateToProps = createStructuredSelector<ComparerereStore, ConnectedProps>({
  user: selectCurrentUser,
  comparerePairForComparison: selectComparerePairForComparison,
  voteCounter: selectVoteCounter
})

const mapDispatchToProps = (dispatch: any) => ({
  getComparerePairForComparison: (ucParam?: string) => dispatch(getComparerePairForComparison(ucParam)),
  addVote: (addVoteDto: AddVoteDto, ucParam?: string) => dispatch(addVote(addVoteDto, ucParam)),
  resetVoteCounter: () => dispatch(resetVoteCounter())
})

export default connect(mapStateToProps, mapDispatchToProps)(VoteForComparere);
