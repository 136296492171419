import "./App.scss";
import React from "react";
import Navbar from "./components/navbar/navbar.component";
import PrivateRoute from "./components/private-route/private-route.component";
import UserCompareres from "./pages/user-compareres/user-compareres.component";
import AccountSettings from "./pages/account-settings/account-settings.component";
import AddNewComperere from "./pages/add-new-comparere/add-new-comperere.component";
import { connect } from "react-redux";
import { RoutePath } from "./route-paths";
import { selectCurrentUser } from "./redux/user/user.selectors";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";
import SignInPage from "./pages/sign-in-page/sign-in-page.component";
import SignUpPage from "./pages/sign-up/sign-up-page/sign-up-page.component";
import AuthorizedHomePage from "./pages/authorized-home-page/authorized-home-page.component";
import HomePage from "./pages/home-page/home-page.component";
import { History } from 'history';

import PointsPage from "./pages/points-page/points-page.component";
import PaymentStatusPage from "./pages/payment-status/payment-status.component";
import InfoFabWrapper from "./components/info-fab-wrapper/info-fab-wrapper.component";
import AlertFabWrapper from "./components/alert-fab-wrapper/alert-fab-wrapper";

import ResetPasswordPage from "./pages/reset-password-page/reset-password.page";
import { compose } from "redux";
import { ContactPage } from "./pages/contact-page/contact-page.component";
import { CookiesPolicyPage } from "./pages/cookies-policy-page/cookies-policy-page";
import { PrivacyPolicyPage } from "./pages/privacy-policy-page/privacy-policy-page";
import { RulesPage } from "./pages/rules-page/rules-page.component";
import { StaticHelperValues } from "./static-helper-values/static-helper-values";
import SurveyPage from "./pages/survey-page/survey-page.component";
import NotFound from "./components/not-found/not-found.component";
import ScrollToTop from "./components/scroll-to-top/scroll-to-top";
import { UserDto } from "./dto/user/user-dto";
import { UserRoleEnum } from "./dto/user/user-role-enum";
import UserComparereDetails from "./components/user-comparere-details/user-comparere-details.component";
import FAQ from "./pages/faq/faq.component";
import FinalizeSignUpPasswordPage from "./pages/sign-up/finalize-sign-up-password-page/finalize-sign-up-password-page";
import CompleteYourProfilePage from "./pages/sign-up/complete-your-profile-page/complete-your-profile-page";
import { UserAgreementPage } from "./pages/user-agreement-page/user-agreement.component";

interface Props {
  user: UserDto;
  match: any;
  history?: History;
}

const App: React.FC<Props> = (props: Props) => {
  const { token, roles } = props.user;
  const isTempUser = token && roles ? roles.indexOf(UserRoleEnum.TempUser) > -1 : undefined;
  const countDownDate = StaticHelperValues.COUNTDOWN_FINISH_DATE;
  const disableNormalView = () => {
    return countDownDate >= Date.now()
  }
  return (
    <>
      <Navbar areButtonsDisabled={disableNormalView()} />
      {/* <WelcomePopup /> */}
      <ScrollToTop>
        <div id="app-pages" className="app-pages">
          <>
            {disableNormalView() ?
              <Switch>
                <Route exact path={RoutePath.CONTACT} component={ContactPage} />
                <Route exact path={RoutePath.COOKIES_POLICY} component={CookiesPolicyPage} />
                <Route exact path={RoutePath.PRIVACY_POLICY} component={PrivacyPolicyPage} />
                <Route exact path={RoutePath.CONTENT_POLICY} component={RulesPage} />
                <Route exact path={RoutePath.FAQ} component={FAQ} />
                <Route exact path={RoutePath.USER_AGREEMENT} component={UserAgreementPage} />
                <Route exact path={RoutePath.SURVEY} component={SurveyPage} />
                <Route exact path={RoutePath.HOME_PAGE} component={HomePage} />
                <Route component={NotFound} />
              </Switch>
              :
              <Switch>
                <PrivateRoute path={RoutePath.USER_COMPARERES_DETAILS} component={UserComparereDetails} roles={roles} />
                <PrivateRoute path={RoutePath.USER_COMPARERES} component={UserCompareres} roles={roles} />
                <PrivateRoute path={RoutePath.ACCOUNT_SETTINGS} component={AccountSettings} roles={roles} />
                <PrivateRoute path={RoutePath.ADD_COMPERERE} component={AddNewComperere} roles={roles} />
                <PrivateRoute path={RoutePath.POINTS_PAGE} component={PointsPage} roles={roles} />
                <PrivateRoute path={RoutePath.PAYMENT_STATUS_PAGE} component={PaymentStatusPage} roles={roles} />

                <Route exact path={RoutePath.CONTACT} component={ContactPage} />
                <Route exact path={RoutePath.COOKIES_POLICY} component={CookiesPolicyPage} />
                <Route exact path={RoutePath.PRIVACY_POLICY} component={PrivacyPolicyPage} />
                <Route exact path={RoutePath.CONTENT_POLICY} component={RulesPage} />
                <Route exact path={RoutePath.FAQ} component={FAQ} />
                <Route exact path={RoutePath.USER_AGREEMENT} component={UserAgreementPage} />
                <Route exact path={RoutePath.SURVEY} component={SurveyPage} />
                <Route exact path={RoutePath.COMPLETEYOURPROFILE} render={() => (token || disableNormalView()) ? <CompleteYourProfilePage /> : <Redirect to={RoutePath.HOME_PAGE} />} />
                <Route exact path={RoutePath.FINALIZESIGNUP} render={() => (token && !isTempUser) ? <Redirect to={RoutePath.COMPLETEYOURPROFILE} /> :
                  (isTempUser ? <FinalizeSignUpPasswordPage /> : <Redirect to={RoutePath.HOME_PAGE} />)} />
                <Route exact path={RoutePath.SIGN_UP} render={
                  () => (token || disableNormalView()) ?
                    (isTempUser ? <Redirect to={RoutePath.FINALIZESIGNUP} /> : <Redirect to={RoutePath.HOME_PAGE} />) :
                    <SignUpPage />
                } />
                <Route exact path={RoutePath.SIGN_IN} render={() => (token || disableNormalView()) ? <Redirect to={RoutePath.HOME_PAGE} /> : <SignInPage />} />
                <Route exact path={RoutePath.HOME_PAGE} component={token ? AuthorizedHomePage : HomePage} />
                <Route exact path={RoutePath.HOME_PAGE_WITHPARAM} component={token ? AuthorizedHomePage : HomePage} />
                <Route exact path={RoutePath.RESET_PASSWORD} component={token ? AuthorizedHomePage : ResetPasswordPage} />
                <Route component={NotFound} />
              </Switch>
            }
          </>
        </div>
        <footer>
          Powered by <a href="https://www.codeebo.com" target="_blank" rel="noopener noreferrer">Codeebo</a> {(new Date(Date.now()).getFullYear())}
        </footer>
        {(roles && roles.indexOf(UserRoleEnum.TempUser) > -1) ? < AlertFabWrapper /> : null}
        <InfoFabWrapper />
        <div>{process.env.REACT_APP_NAME}</div>
        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} />
      </ScrollToTop>

    </>
  );
}

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser
});

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(App);