import React, { Component } from "react";
import { connect } from 'react-redux'
import Tile from "../../components/tile/tile.component";
import { RoutePath } from "../../route-paths";
import { resetPassword } from "../../redux/account-settings/account-settings.actions";
import { Link } from "react-router-dom";
import FormInputOval from "../input-forms/form-input-oval/form-input-oval.component";
import BtnCustomShadow from "../../components/btn-custom-shadow/btn-custom-shadow";

interface State {
  email: string;
  isButtonDisabled: boolean
}

interface Props {
  resetPassword: any;
}

class ResetPasswordForm extends Component<Props, State> {
  timeoutContainer: any;

  constructor(props: any) {
    super(props);

    this.state = {
      email: '',
      isButtonDisabled: false
    };
  }

  handleSubmit = async (e: any) => {
    e.preventDefault();
    const { email } = this.state;

    this.props.resetPassword(email);
    this.setState({ isButtonDisabled: true })
    this.timeoutContainer = window.setTimeout(() => {
      this.setState({ isButtonDisabled: false })
    }, 1500);
  };

  componentWillUnmount() {
    window.clearInterval(this.timeoutContainer);
  }

  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState({ [name]: value } as State);
  };

  render() {
    const { email } = this.state;
    return (
      <Tile>
        <h2 className="title">Reset password</h2>
        <form onSubmit={this.handleSubmit}>
          <FormInputOval
            name="email"
            type="email"
            label="Email"
            value={email}
            onChange={this.handleChange}
            required
          />
          <BtnCustomShadow type="submit" disabled={this.state.isButtonDisabled}>Send</BtnCustomShadow>
        </form>
        <div className="sign-in-form_footer">
          <p>Remember password? Sign in <span className="link"><Link to={RoutePath.SIGN_IN}>here!</Link></span></p>
          <p>Don't have account? Sign up <span className="link"><Link to={RoutePath.SIGN_UP}>here!</Link></span></p>
        </div>
      </Tile>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  resetPassword: (email: string) => dispatch(resetPassword(email))
})

export default connect(null, mapDispatchToProps)(ResetPasswordForm);
